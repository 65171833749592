body {/*disable text selection*/
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
}
html {
     height: 100%;
     position: fixed;
     width: 100%;
}
* {
    font-family: "KelsonSans","PT Sans", "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    touch-action: manipulation;
}

.has-modal .app,
.has-modal-overlay .app,
.has-modal .sidebar,
.has-modal-overlay .sidebar {
    pointer-events: auto !important;
}

.modal {
  text-align: center;
  padding: 0!important;
  position: fixed;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px; /* Adjusts for spacing */
}

.modal-dialog {
  display: inline-block;
  vertical-align: middle;
}

.modal-content {
    background-color: white;
    color:black;
}

.modal-content input {
    color:black;
}

.trash-can {
    margin-top: 9px;
}

.card-container.scenario i.avatar-user{
    font-size: 4.4em;
    color:#aeaeae;
    position: relative;
    top: 0.5em;
}

.message-config.error.user-panel {
    font-size: 1.5rem;
}

@media (max-width: 767px) {

    /*.section > .scenario-name{
        padding-top: 60px;
    }*/


    .modal-dialog {
        position: fixed;
        top: 15%;
        left: 18.5%;
        z-index: 1050;
        width: 80%;
        height: 80%;
        margin-left: -10%;
    }

    .device-upper-active,
    .device-upper{
        height: 105px !important;
    }

    .dash-header-today-spacing {
        margin-left:0% !important;
        margin-right:0% !important;
    }

    .icon-close-sidebar {
        line-height:52px;
        display: inline-block !important;
        color: lightgrey;
        font-size: 16px;
        cursor:pointer;
    }

    .desktop-only{
        display: none;
    }
    .btn-responsive {
        font-size:100%;
        line-height: 1;
        border-radius:3px;
    }
    .device-down{
        height: 38px !important
    }

    .disable-app-active-side-bar {
        pointer-events: none;
        cursor: default;
        transform:none !important;
    }

    .active-side-bar-zind {
        z-index:99;
    }

    .active-side-bar {
        pointer-events: none;
        position:fixed !important;
        width:100%;
        height:100%;
        z-index:1000;
        opacity:0.6;
        filter: alpha(opacity = 60);
        background-color: #060000;
        top:0;
        left:0;
    }
    .hidden-devices {
        display: none;
    }
}

@media (min-width: 993px) and (max-width: 1199px) {
    .btn-responsive {
        padding:11px 15px 6px;
        font-size:100%;
        line-height: 1.2;
    }
}
@media (min-width: 768px) and (max-width: 992px) {

    /*.section > .scenario-name{
        padding-top: 60px;
    }*/

    .icon-close-sidebar {
        line-height:52px;
        display: inline-block !important;
        color: lightgrey;
        font-size: 16px;
        cursor:pointer;
    }

    .desktop-only{
        display: none;
    }

    .btn-responsive {
        padding:10px 15px 6px;
        font-size:100%;
        line-height: 1.2;
    }

    .disable-app-active-side-bar {
        pointer-events: none;
        cursor: default;
        transform:none !important;
    }

    .active-side-bar-zind {
        z-index:99;
    }

    .active-side-bar {
        pointer-events: none;
        position:fixed !important;
        width:100%;
        height:100%;
        z-index:1000;
        opacity:0.6;
        filter: alpha(opacity = 60);
        background-color: #060000;
        top:0;
        left:0;
    }
    .visible-devices {
        display: none;
    }
}

@media all and (max-width:360px) {

    .step-4-form-item {
        max-width: 85%!important;
        min-width: 85%!important;
    }

    .dash-header-today-spacing {
        margin-left:0% !important;
        margin-right:0% !important;
    }

    .icon-close-sidebar {
        line-height:52px;
        display: inline-block !important;
        color: lightgrey;
        font-size: 16px;
        cursor:pointer;
    }

    .disable-app-active-side-bar {
        pointer-events: none;
        cursor: default;
        transform:none !important;
    }

    .active-side-bar-zind {
        z-index:99;
    }

    .active-side-bar {
        pointer-events: none;
        position:fixed !important;
        width:100%;
        height:100%;
        z-index:1000;
        opacity:0.6;
        filter: alpha(opacity = 60);
        background-color: #060000;
        top:0;
        left:0;
    }

    .domotics-arrow-xs {
        width:7.4% !important;
    }


    .dash-header-today-cost {
        font-size: small !important;
        color: white;
        margin-top:0px;
        padding-right:2.5rem;
        display:inline-block;
    }

    .dash-header-monthly-cost {
        font-size: small !important;
        color: white;
        margin-top:0px;
        padding-right:1.5rem !important;
        display:inline-block;
    }

    .dash-header-devices-counter {
        font-size: small !important;
        color: white;
        margin-top:0px;
        padding-left:4rem;
    }

    .dash-header-content-container-parent {
        width:auto !important;
    }

    .dash-header-content-container {
        min-height:5rem;
        padding-top:0px;
    }

    .dash-header-content-greeting {
        display:none;
    }

    .dash-header-content-monthly-usage-icon {
        padding-left:0px;
        opacity: 0.7;
    }

    .dash-header-content-monthly-text {
        font-size: x-small !important;
        margin-top: -1.5rem;
        padding-left: 0px;
    }

    .dash-header-content-daily-usage-icon {
        padding-left:0px;
        opacity: 0.7;
    }

    .dash-header-content-daily-usage-text {
        font-size: x-small !important;
        margin-top: -1.5rem;
        padding-left: 0px;
    }
}

@media all and (min-width : 320px) and (max-width: 767px)  {

    .step-4-form-item {
        max-width: 85%!important;
        min-width: 85%!important;
    }

    .rates-btn {
        margin-left: 1.5rem !important;
    }

    .modal-dialog{
        padding-bottom: 25%;
    }

    .card-back-usage-info-container {
            text-align: center;
            width: 100%;
            padding-top: 27%;
    }

    .icon-close-sidebar {
        line-height:52px;
        display: inline-block !important;
        color: lightgrey;
        font-size: 16px;
        cursor:pointer;
    }

    .disable-app-active-side-bar {
        pointer-events: none;
        cursor: default;
        transform:none !important;
    }

    .active-side-bar-zind {
        z-index:99;
    }

    .active-side-bar {
        pointer-events: none;
        position:fixed !important;
        width:100%;
        height:100%;
        z-index:1000;
        opacity:0.6;
        filter: alpha(opacity = 60);
        background-color: #060000;
        top:0;
        left:0;
    }

    .spec-mob {margin-top:0 !important;padding-left:0;}

    .graphSliceButtons {
        display:none !important;
    }

    .mob-graphSlice {
        display:inherit !important;
    }

    .mob-graphSlice.mob-graphSlice-select {
        padding-right: 0;
        margin-right: 0.2rem;
        width: 50%;
        font-size: smaller;
        height: 30px;
    }

    .domotics-graph-btn{
        display:none;
    }

    .spec {
        right:2rem;
    }

    .domotics-device-config-quad-container{
        padding: 2em !important;
        height: 100%;
        text-align: center;
    }

    html.touch *:hover {
        pointer-events: none !important;
    }

    div.domotics-general-list-icon-card-back{
        position:relative;
        overflow:hidden;
    }

    div.domotics-general-list-icon-card-back:before {
        font-size: 3.143em;
        padding-left: 38%;
        padding-top: 10%;
        position: absolute;
    }

    div.domotics-general-list-icon{
        position:relative;
        overflow:hidden;
    }

    div.domotics-general-list-icon:before {
        font-size: 2.143em;
        padding-left: 38%;
        padding-top: 30%;
        position: absolute;
    }


    div.domotics-device-list-icon,div.domotics-device-list-icon-multiple {
        position:relative;
        overflow:hidden;
    }

    div.domotics-device-list-icon:before {
        font-size: 4em;
        padding-left: 33%;
        padding-top:21%;
        position: absolute;
    }

    div.domotics-device-list-icon-multiple:before {
        font-size: 2.143em;
        padding-left: 19%;
        padding-top: 45%;
        position: absolute;
    }

    div.domotics-device-icon-off {
        color: #aeaeae;

        /*Not supported by IE, and was doing nothing that I can see for other browsers. Padding was already been added to div on other class*/
        /*padding-top: calc(50% - 11rem);*/
    }

    div.domotics-device-icon-on {
        color: white;
    }

    div.domotics-device-icon-toggle {
        color: #65b2e9;
    }

    .device-card-name {
        font-weight: bold;
        font-size: small;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
        font-family: sans-serif;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden !important;
    }

    .device-card-division {
        font-size: small;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
        font-family: sans-serif;
        color: #aeaeae;
        display:block;
    }

    .device-card-settings-cog{
        margin-right: 2.2rem;
        font-size: 1.386rem;
        margin-top: 1.1rem;
        color: #aeaeae;
    }

    .card-power-icon-off {
        padding-top: 1.3rem;
        padding-right: 2.1rem;
        font-size: 1.229rem;
        color: #aeaeae;
    }

    .card-power-icon-on {
        padding-top: 1.3rem;
        padding-right: 2.1rem;
        font-size: 1.229rem;
        color: white;
    }

    .card-power-icon-toggle {
        padding-top: 1.3rem;
        padding-right: 2.1rem;
        font-size: 1.229rem;
        color: #65b2e9;
    }

    .cards-container {
        text-align: center;
        margin-bottom:5rem;
    }

    .domotics-device, device-button, device-button-scenario{
/*        width: 13.46rem !important;
        height: 10.9rem !important;*/
        position: relative;
        float:left;
        border-radius: 0.2143rem;
    }

    .domotics-device {
        margin:0.7rem;
        text-align: left;
    }

    .card-container{
/*        max-width:100% !important;
        max-height: 100% !important;*/
        border:none !important;
        outline: none !important;
        border-radius: 0.2143rem;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -webkit-touch-callout : none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
    }

    .device-upper {
        background-color: white;
        max-width:100%;
        height: 8rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius:  0.2143rem  0.2143rem  0px  0px;
        border-radius:  0.2143rem 0.2143rem 0px 0px;
    }

    .device-upper-whole {
        background-color: white;
        max-width:100%;
        height: 148px;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius:  0.2143rem  0.2143rem  0px  0px;
        border-radius:  0.2143rem 0.2143rem 0px 0px;
        cursor:pointer;
    }

    .device-upper-plus {
        min-width:50%;
        height: 70%;
        margin: 0px;
        position: relative;
    }

    /*NEW CLICKABLE AREA*/
    .top-div-device-clickable {
        min-height:2rem;
        position:relative;
    }

    .top-div-device-clickable-element {
        min-width:20%;
        min-height: 2rem;
        z-index: 2;
        position: relative;
    }

    /*NEW CLICKABLE AREA*/

    .device-upper-minus {
        min-width:50%;
        height: 70%;
        margin: 0px;
        position: relative;
    }

    .device-upper-active {
        background-color: #65b2e9;
        max-width:100%;
        height: 8rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0.2143rem 0.2143rem 0px 0px;
        border-radius: 0.2143rem 0.2143rem 0px 0px;
    }

    .device-down {
        background-color: white;
        max-width:100%;
        max-height: 40%;
        height: 38px;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
        padding-top:5px;
    }

    .device-down-simple {
        background-color: white;
        max-width:100%;
        max-height: 40%;
        height: 38px;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
        text-align: center;
    }

    .device-down-upper-active {
        background-color: #65b2e9;
        color: white !important;
        cursor:pointer;
        max-width:100%;
        max-height: 40%;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
    }

    .device-down-upper-active *{
        color: white !important;
    }

    .device-down-upper-active i{
        color: white !important;
    }


    .device-upper:hover, .device-upper-active:hover{
        cursor:pointer;
    }

    .device-down:hover{
        cursor:pointer;
    }

    .device-down:hover span:not(.device-card-division){
        color: #65b2e9;
    }

    .device-down:hover i{
        color: #65b2e9;
    }

    .device-toggle-percentage {
        padding-right: 1.1rem;
        padding-top: 3.9rem;
        font-size: 1.229rem;
    }

    .device-percentage-status-white {
        background-color:white;
    }

    .device-percentage-status-gray {
        background-color:gray;
    }

    .device-percentage-status-gray-end {
        background-color:gray;
        border-top:1px solid white;
        border-bottom:1px solid white;
        border-right:1px solid white;
    }

    .device-percentage-status-gray-start {
        background-color:gray;
        border-top:1px solid white;
        border-bottom:1px solid white;
        border-left:1px solid white;
    }

    .device-card-back-metering-span {
        text-align: center;
    }

    .device-card-back-metering-span > span {
        font-size:4rem;
        display:block;
        padding-top:15%;
    }

    .device-control-bar {
        width:100%;
        height:1.2rem;
        margin-top:20%;
    }

    .device-percentage-status {
        margin-left: 1.3rem;
        margin-top: 0.8rem;
        font-size: 1.0rem;
    }

    .dash-header-today-cost {
        font-size: medium;
        color: white;
        margin-top:0px;
        padding-right:4rem;
        display:inline-block;
    }

    .dash-header-monthly-cost {
        font-size: medium;
        color: white;
        margin-top:0px;
        padding-right:2rem;
        display:inline-block;
    }

    .dash-header-devices-counter {
        font-size: medium;
        color: white;
        margin-top:0px;
        padding-left:4rem;
    }

    .dash-header-content-container-parent {
        width:auto !important;
    }

    .dash-header-content-container {
        min-height:5rem;
        padding-top:0px;
        margin-bottom: 15px;
    }

    .dash-header-content-container > div {
        margin-bottom:4% !important;
    }

    .dash-header-content-greeting {
        display:none;
    }

    .dash-header-content-monthly-usage-icon {
        padding-left:0px;
        opacity: 0.7;
    }

    .dash-header-content-monthly-text {
        font-size: x-small;
        margin-top: -1.5rem;
        padding-left: 0px;
    }

    .dash-header-content-daily-usage-icon {
        padding-left:0px;
        opacity: 0.7;
    }

    .dash-header-content-daily-usage-text {
        font-size: x-small;
        margin-top: -1.5rem;
        padding-left: 0px;
    }

    /***** card back ****/
    .card-back-device-down {
        background-color: white;
        max-width:100%;
        max-height: 40%;
        height: 38px;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
        overflow:hidden;
    }

    .domotics-card-back-cog-area {
        min-width:50%;
        min-height:4rem;
        cursor: pointer;
    }

    .domotics-card-back-refresh-area {
        min-width:50%;
        min-height:4rem;
        cursor:pointer;
    }

    .domotics-card-back-cog-area:hover *{
        color: #65b2e9;
    }

    .domotics-card-back-refresh-area:hover *{
        color:#65b2e9;
    }

    .device-card-back-settings-cog{
        margin-left: 2.2143rem;
        font-size: 1.586rem;
        margin-top: 0.8571rem;
        color: #aeaeae;
    }

    /******************header and body styling per view******************/
    /**** home *****/
    .domotics-body-home{
        padding-top: 54px !important;
    }

    .domotics-header-home {
        min-height: 5rem !important;
    }
    /**** devices ***/

    .domotics-body-devices{
        padding-top: 80px !important;
    }

    .domotics-header-devices {
        min-height: 5rem !important;
    }
    /*************************************/

    /**** LOGIN *****/
    .domotics-login {
        height:100%;
        width:100%;
        text-align: center;
        background-image: url('../../../images/src/images/back.jpg') !important;
        background-size: 100% 100%;
        font-family: "PT Sans", "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .domotics-login-form {
        min-height: 35%;
        max-width: 80%;
        // background-color: white;
        padding:2rem;
        border-radius: 0.2rem;
        margin-left: auto ;
        margin-right: auto ;
    }

    .domotics-login-header {
        color: #001ec8;
        font-size:4rem;
        font-style: italic;
        font-weight: bold;
        margin-left:8rem;
    }

    .domotics-login-header2 {
        color:white;
        font-size:3rem;
        /*text-shadow: 1px 1px #00193a;*/
        margin-top:12rem;
    }

    .domotics-login-form-forgot-pw {
        color:white;
        text-decoration: underline;
    }

    .domotics-login-form-forgot-pw:hover {
        color:white;
    }

    .domotics-login-form > input {
        display:block;
        margin : 0 auto;
        margin-top:5%;
        min-height:5rem;
        width: 90%;
        font-size:large;
        border-radius: 0.2rem;
        padding: 0 14px 0 14px;
    }

    .domotics-login-btn {
        border : solid 0px #ffffff;
        -webkit-box-shadow : 0px 1px 1px rgb(75, 107, 148);
        -moz-box-shadow : 0px 1px 1px rgb(75, 107, 148);
        box-shadow : 0px 1px 1px rgb(75, 107, 148);
        color : #ffffff;
        margin-top:8% !important;
        background-color : #4b9dff;
        font-size:small !important;
        opacity: 0.8;
    }

    .domotics-login-btn:hover {
        background-color : #001ec8;
        border-radius: 5px;
    }

    .domotics-body-login {
        padding-top: 0px !important;
    }

    /*******************************/


    /**** DEVICE CONFIG *****/

    /**** LEFT SIDE ****/
    .domotics-device-config-top-container {
        min-width:80%;
        background-color: white;
    }

    .domotics-device-config-general-settings {
        position:relative;
        text-align: center;
        width:100%;
        padding-left:2rem;
    }

    .domotics-device-config-general-settings > div:first-child {
        margin-top:2rem;
        max-width:50%;
    }

    .domotics-device-config-general-settings > div:first-child > span {
        font-size: large !important;
        display:block;
        word-wrap: break-word;
    }

    .domotics-device-config-general-settings-span {
        margin-top:1rem;
        font-size: medium;
    }

    .domotics-device-config-icon {
        width:10rem;
        height:10rem;
        border-style: dashed;
        cursor: pointer;
    }

    .domotics-device-config-icon:before {
        font-size:8rem;
        position:relative;
        line-height: 92px;
    }

    .domotics-device-config-general-settings > div:nth-child(2) {
        padding-right: 2rem;
        padding-top: 8rem;
        font-size:medium;
        display:inline-block;
        text-align: left;
    }

    .domotics-device-config-general-settings > div:nth-child(2) > input, .domotics-device-config-general-settings > div:nth-child(2) > select {
        display:block;
        width:12rem;
        padding:0.2rem;
        border: solid 2px #dcdcdc;
        transition: box-shadow 0.3s, border 0.3s;
    }

    .domotics-device-config-general-settings > div:nth-child(2) > input:focus, .domotics-device-config-general-settings > div:nth-child(2) > input.focus {
        border: solid 1px #707070;
        box-shadow: 0 0 5px 1px #969696;
    }

    .domotics-device-config-general-settings > div:nth-child(2) > span {
        margin-top:3rem;
        display: block;
    }

    .domotics-device-config-button-container {
        margin-top:2rem;
    }

    .domotics-device-config-button-container > input {
        width:11rem;
    }

    .domotics-device-config-button-container > input:nth-child(2) {
        margin-right:1rem;
    }

    /**** RIGHT SIDE ****/
    .domotics-device-config-usage-container {
        width:100%;
        position:relative;
        /*padding-left:0px;*/
        margin-top:2rem;
    }

    .domotics-device-config-usage-container > div:first-child > span {
        font-size:large;
        padding-left:1rem;
    }

    .domotics-device-config-usage-container > div:first-child > i {
        /*padding-right:15%;*/
    }

    .domotics-device-config-usage-graph-container {
        width:100%;
        height:80%;
        padding-left:1rem;
        margin-top:1rem;
    }

    .config-usage-graph {
        width:100%;
        height:25rem;
        overflow:hidden;
        border:1px solid black;
    }

    .config-usage-graph-metering-info {
        padding-left:0.1rem;
        text-align: center;
    }

    .graph-info-title {
        color:#7cb5ec;
        font-size:medium;
        display:block;
    }

    .domotics-device-config-turnoff-device {
        width:100%;
        padding-left:1rem;
    }

    .domotics-device-config-turnoff-device > i {
        -moz-transition: none;
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }

    .domotics-device-config-turnoff-device > input[type="checkbox"] {
        vertical-align: text-bottom;
    }

    .domotics-device-config-turnoff-device > input[type="number"] {
        width:5rem;
        margin-top:1rem;
    }
    /************************/
    /**** NOTIFICATIONS ***/
    div[id="toast-container"] {
        margin-top:11.5rem;
    }

    .device-progressbar-current {
        width:90%;
        position: absolute;
        height:0.6rem;
        bottom:0;
        margin-bottom:0.1rem;
        right:0;
        left:0;
        margin-left:auto;
        margin-right:auto;
        border-radius:0px;
    }

    .device-progressbar-current > .progress-bar {
        background-image: none;
        background-color: #AEAEAE;
        border-radius:0px;
    }

    .device-progressbar-requested {
        width:90%;
        position: absolute;
        height:0.6rem;
        top:0;
        margin-top:6%;
        right:0;
        left:0;
        margin-left:auto;
        margin-right:auto;
        border-radius:0px;
        background-color: transparent;
        background-image: none;
        border: 1px solid white;
    }

    .device-progressbar-requested > .progress-bar {
        background-image: none;
        border-radius:0px;
        box-shadow: none;
    }

    .device-control-bar-requested-container {
        position: absolute;
        width: 90%;
        height: 0.6rem;
        margin-left: auto;
        margin-right: auto;
        top: 0;
        left:0;
        right:0;
    }

    .device-control-bar-request {
        width: 0.5rem;
        height: 2.0rem;
        position: relative;
        top: 0;
        margin-top: 2%;
        border: 1px solid #777;
        z-index: 2;
    }


    .card-back-usage-icon {
        margin-right:1.5rem;
        margin-top:1.0rem;
        cursor:pointer;
        font-size:2rem;
    }

    .card-back-usage-info {
        line-height: 6rem;
        font-size:2.8rem !important;
    }

    /***************CONFIGURATION WIZARD**************/

    /******STEP1********/

    .conf-wizard-step1 > h1 {
        padding-top:30rem;
    }

    .conf-wizard-step1 > span {
        display:block;
        font-size: x-large;
        color:white
    }

    .conf-wizard-step1 > input {
        color:black;
        background-color: white;
        font-size: large
    }


    /*****************************/

    /*************** STEP 2 *********/

    .domotics-device-config-wizard-langselect {
        width:90%;
        background-color: white;
        margin-left: auto;
        margin-right: auto;
        padding-top:5rem;
        margin-top:5rem;
        border:solid 2px black;
        font-size:1.3rem;
    }

    .domotics-device-config-wizard-management,
    .domotics-device-config-wizard-network,
    .wizard-config-button-container{
        width: 90% !important;
    }

    .domotics-device-config-wizard-button-container {
        margin-top:2rem;
    }

    .domotics-lang-element {
        width:90%;
        height:5rem;
        margin-bottom:2rem;
        margin-left:auto;
        margin-right:auto;
        line-height:5rem;
        cursor:default;
    }

    .domotics-lang-not-selected-element {
        background-color: lightgray;
    }

    .domotics-selected-lang-element {
        background-color: #002f6d;
        color:white;
    }

    .wizard-config-button-container {
        width:50%;
        margin-left:auto;
        margin-right:auto;
    }

    .wizard-config-button-container {
        width:50%;
        margin-left:auto;
        margin-right:auto;
    }

    /********************************/

    /******** STEP3 *********/
    .domotics-config-wizard-create-user {
        width:90%;
        min-height:40rem;
        background-color: white;
        margin-left: auto;
        margin-right: auto;
        padding-top:5rem;
        margin-top:5rem;
        margin-bottom:5rem;
        border:solid 2px black;
        font-size:2rem;
    }
    /***********************/

    /******** STEP4 *********/
    .stage-wrapper{
        margin-top: 1%;
    }
    /***********************/

    /*** DEVICES HEADER **/

    .devices-header-content-container {
        text-align: center;
        padding-top:1.8rem;
    }

    .devices-header-content-title {
        position: relative;
        top:0;
        font-size:medium;
        color:white;
    }
    .devices-header-content-title-device {
        position: relative;
        top:0;
        font-size:medium;
        color:white;
        margin-right: 10%;
    }
    .devices-header-content-container > .breadcrumb-device-navigation{
        margin-right: 10px;
        padding-right: 4rem;
    }

    .devices-header-content-container > .breadcrumb-device-navigation > i{
        font-size: 14px;
        line-height: 21px;
    }

    .devices-header-content-container > .breadcrumb-device-navigation > span,
    .devices-header-content-container > .breadcrumb-device-navigation > a > span{
        font-size: 14px;
        line-height: 21px;
        font-family: 'KelsonSans';
    }

    .header-parent-container .tablet-category span{
        display: block;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 10px;
        font-size: 14px;
        line-height: 21px;
        font-family: 'KelsonSansBold';
        color: #FFFFFF;
        padding-right: 4rem;
    }

    .devices-header-content-filtering-container {
        position:relative;
        bottom:0;
    }

    .devices-header-content-filter{
        left:0;
        right:0;
        margin-left:auto;
        margin-right:auto;
        position:relative;
        bottom:-10px;
        width:45%;
        display:table;
    }

    .devices-header-content-filter > div {
        display: inline-block;
        background-color:transparent;
        color:lightgray;
        font-size:medium;
        margin-right:7px;
        margin-left:7px;
    }
    /***********************/

    /****** STEP 5 CONFIG ******/
    .wizard-step5-btn-container {
        position: relative;
        width: 25rem;
        margin-left: auto;
        margin-right: 3%;
    }

    .wizard-step5-editarea-title {
        display:block;
        margin-top:1rem;
        font-size:large;
        font-weight: bold;
    }

    .wizard-step5-editarea-title-input {
        margin-top:1rem;
        border: solid 1px #707070;
        box-shadow: 0 0 5px 1px #969696;
        font-size:14px;
        padding: 5px 0px 0px;
    }

    .wizard-step5-divisions-title {
        display:block;
        margin-top:3rem;
        font-size:large;
        font-weight: bold;
    }

    .wizard-step5-modal-input{
        margin-top:1rem;
        border: solid 1px #707070;
        box-shadow: 0 0 5px 1px #969696;
        font-size:1.5rem;
    }
    /***************************/

    /**** USER HEADER DETAILS ***/

    .user-details-header {
        position: absolute;
        right:0;
        top:0;
        margin-top:1.5rem;
        width:20rem;
    }

    .user-header {
        display:none;
    }

    .user-header-sidebar {
        width:70%;
        margin-left:1.5rem;
        margin-top:2rem;
        margin-bottom:0.5rem;
        position:relative;
        color:gray;
    }

    .user-header,.user-header-sidebar * {
        cursor:pointer;
    }

    .user-header-name-sidebar {
        display:inline-block;
        position:relative;
        padding-right:0.5rem;
        margin-left: 2px;
    }

    .user-header-caret-sidebar {
        display:inline-block;
        position:relative;
        top:-1rem;
        padding-left:0.5rem;
    }

    .user-header-name {
        display:inline-block;
        position:relative;
        top:-1rem;
        padding-right:0.5rem;
    }

    .user-header-caret {
        display:inline-block;
        position:relative;
        top:-1rem;
        padding-left:0.5rem;
    }

    .notifications-header {
        width:30%;
        position:relative;
        color:white;
    }

    .notifications-header * {
        cursor:pointer;
    }

    .header-notifications-val {
        position:absolute;
        top:-0.2rem;
        right:2.5rem;
        padding:1px 4px;
        background: red; color: white;
        border-radius:3px;
        font-size:1rem;
    }

    .dom-not-ic {
        font-size:2.5rem;
        margin-left:0.5rem;
        margin-top:0.2rem;
    }

    .circletag {
        width: 30px;
        height: 30px;
        background: #E6E7ED;
        -moz-border-radius: 20px;
        -webkit-border-radius: 20px;
        background-color: white;
        background-position:50% 50%;
        background-repeat:no-repeat;
        color:#3CA2E0;
        font-size:2.5rem;
    }

    /*.circletag > i {
        position:absolute;
        top:0;
        margin-left:0.5rem;
        margin-top:0.2rem;
    }*/

    /****************************/

    /*** graph arrows ***/

    /* .highcharts-container{
    //     width: calc(100% + 2rem) !important;
    // } */

    .domotics-timetravel-forward {
        font-size: 3rem;
        position: relative;
        cursor:pointer;
        margin-right: calc(50% - 12rem);
        float: right;
    }

    .domotics-timetravel-back {
        font-size: 3rem;
        padding: 0 !important;
        padding-left: 1rem !important;
        position: relative;
        cursor:pointer;
        margin-left: calc(50% - 12rem);
    }

    /********************/

    /*.wizard-label {
        display:none;
    }*/

    /***********************/

    .display-usage-container {
        margin-right:2.5rem !important;
        cursor:pointer;
    }

    .domotics-displaying-usage {
        position:absolute;
        top:0.3rem !important;
        right:0.5rem !important;
        color:white;
        font-size:3rem !important;
        font-weight:900;
    }

    .domotics-usage-icon {
        position:absolute;
        top:1.2rem !important;
        color:darkgray;
        font-size:1.7rem !important;
        right:0.5rem !important;
    }


    .domotics-wizard-step7-periods-container-input-container{
        padding-right: 0 !important;
    }

    .top-button {
        top: 3px;
    }

    .login-container {
        width: 280px !important;
    }
    .login-container input[type="password"],
    .login-container input[type="text"],
    .login-container input[type="submit"],
    .login-container .login-msg {
        width: 228px !important;
    }
}

@media all and (min-width: 768px) and (max-width: 991px) {

    .step-4-form-item {
        max-width: 45%!important;
        min-width: 45%!important;
    }

    .rates-btn {
        margin-left: 2rem !important;
    }

    .modal-dialog{
        padding-bottom: 45%;
    }

    .card-back-usage-info-container {
            text-align: center;
            width: 100%;
            padding-top: 30%;
    }

    .dash-header-today-spacing {
        margin-left:12.5% !important;
        margin-right:12.5% !important;
    }

    .icon-close-sidebar {
        line-height:52px;
        display: inline-block !important;
        color: lightgrey;
        font-size: 16px;
        cursor:pointer;
    }

    div.domotics-general-list-icon-card-back{
        position:relative;
        overflow:hidden;
    }

    div.domotics-general-list-icon-card-back:before {
        font-size: 6.143em;
        padding-left: 35%;
        padding-top: 10%;
        position: absolute;
    }

    div.domotics-general-list-icon{
        position:relative;
        overflow:hidden;
    }

    div.domotics-general-list-icon:before {
        font-size: 4.143em;
        padding-left: 38%;
        padding-top: 25%;
        position: absolute;
    }

    div.domotics-device-list-icon,div.domotics-device-list-icon-multiple {
        position:relative;
        overflow:hidden;
    }

    div.domotics-device-list-icon:before {
        font-size: 4em;
        padding-left: 35%;
        /*padding-right: 65px;*/
        padding-top: 24%;
        padding-bottom: 55px;
        position: absolute;
    }

    div.domotics-device-list-icon-multiple:before {
        font-size: 4.143em;
        padding-left: 19%;
        padding-top: 45%;
        position: absolute;
    }


    div.domotics-device-icon-off {
        color: #aeaeae;
    }

    div.domotics-device-icon-on {
        color: white;
    }

    div.domotics-device-icon-toggle {
        color: #65b2e9;
    }

    .device-card-name {
        font-weight: bold;
        font-size: larger;
        padding-left: 1.429rem;
        font-family: sans-serif;
        display: block;
        padding-right: 1.429rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden !important;
    }

    .device-card-division {
        font-size: small;
        padding-left: 1.429rem;
        padding-right: 1.429rem;
        font-family: sans-serif;
        color: #aeaeae;
        display:block;
    }

    .device-card-settings-cog{
        margin-right: 2.2143rem;
        font-size: 1.686rem;
        margin-top: 0.8571rem;
        color: #aeaeae;
    }

    .card-power-icon-off {
        padding-top: 2rem;
        padding-right: 2rem;
        font-size: 1.5rem;
        color: #aeaeae;
    }

    .card-power-icon-on {
        padding-top: 2rem;
        padding-right: 2rem;
        font-size: 1.5rem;
        color: white;
    }

    .card-power-icon-toggle {
        padding-top: 2rem;
        padding-right: 2rem;
        font-size: 1.5rem;
        color: #65b2e9;
    }

    .cards-container {
        text-align: center;
        margin-bottom:5rem;
    }

    .domotics-device, device-button, device-button-scenario{
/*        width: 24.46rem !important;
        height: 18.86rem !important;*/
        position: relative;
        float:left;
        border-radius: 0.2143rem;
    }

    .domotics-device {
        margin:0.7rem;
        text-align: left;
        border-radius: 0.2143rem;
    }

    .card-container{
/*        max-width:100% !important;
        max-height: 100% !important;*/
        border:none !important;
        outline: none !important;
        border-radius: 0.2143rem;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -webkit-touch-callout : none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
    }

    .device-upper {
        background-color: white;
        max-width:100%;
        height: 121px;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius:  0.2143rem  0.2143rem  0px  0px;
        border-radius:  0.2143rem 0.2143rem 0px 0px;
    }

    .device-upper-whole {
        cursor:pointer;
        background-color: white;
        max-width:100%;
        height: 171px;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius:  0.2143rem  0.2143rem  0px  0px;
        border-radius:  0.2143rem 0.2143rem 0px 0px;
    }

    .device-upper-plus {
        min-width:50%;
        height: 70%;
        margin: 0px;
        position: relative;
    }

    .device-upper-minus {
        min-width:50%;
        height: 70%;
        margin: 0px;
        position: relative;
    }

    .device-upper-active {
        background-color: #65b2e9;
        max-width:100%;
        height: 121px;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0.2143rem 0.2143rem 0px 0px;
        border-radius: 0.2143rem 0.2143rem 0px 0px;
    }

    .device-down {
        background-color: white;
        max-width:100%;
        height: 50px;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
        padding-top:8px;
    }

    .device-down > div:nth-child(2) {
        width:75% !important;
    }

    .device-down-simple {
        background-color: white;
        max-width:100%;
        height: 50px;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
        text-align: center;
    }

    .device-down-upper-active {
        background-color: #65b2e9;
        color: white !important;
        cursor:pointer;
        max-width:100%;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
    }

    .device-down-upper-active *{
        color: white !important;
    }

    .device-down-upper-active i{
        color: white !important;
    }


    .device-upper:hover, .device-upper-active:hover{
        cursor:pointer;
    }

    .device-down:hover{
        cursor:pointer;
    }

    .device-down:hover span:not(.device-card-division){
        color: #65b2e9;
    }

    .device-down:hover i{
        color: #65b2e9;
    }

    .device-toggle-percentage {
        padding-right: 1.071rem;
        padding-top: 5.143rem;
        font-size: 1.629rem;
    }

    .device-percentage-status-white {
        background-color:white;
    }

    .device-percentage-status-gray {
        background-color:gray;
    }

    .device-percentage-status-gray-end {
        background-color:transparent;
        border-top:1px solid white;
        border-bottom:1px solid white;
        border-right:1px solid white;
    }

    .device-percentage-status-gray-start {
        background-color:transparent;
        border-top:1px solid white;
        border-bottom:1px solid white;
        border-left:1px solid white;
    }

    .device-card-back-metering-span {
        text-align: center;
    }

    .device-card-back-metering-span > span {
        font-size:24px;
        display:block;
        padding-top:25%;
        padding-left:10px;
    }

    .device-control-bar {
        width:100%;
        height:1.5rem;
        margin-top:20%;
    }

    .device-percentage-status {
        margin-left: 1.3rem;
        margin-top: 0.8rem;
        font-size: 1.2rem;
    }

    .dash-header-today-cost {
        font-size: large;
        display:inline-block;
        color: white;
        padding-left:3rem;
    }

    .dash-header-monthly-cost {
        font-size: large;
        display:inline-block;
        color: white;
        padding-left:3rem;
    }

    .dash-header-devices-counter {
        font-size: large;
        display:inline-block;
        color: white;
        padding-left:3rem;
    }

    .dash-header-content-container {
        padding-top:0px;
    }

    .dash-header-content-container > div {
        margin-bottom:4% !important;
    }

    .dash-header-content-greeting {
        color:white;
        padding-left:0px;
        font-size:x-large;
        float:left;
        padding-top:2rem;
        padding-left:2rem;
    }

    .dash-header-content-monthly-usage-icon {
        padding-left:0px;
        opacity: 0.7;
    }

    .dash-header-content-monthly-text {
        font-size: x-small;
        margin-top: -1.5rem;
        padding-left: 0px;
    }

    .dash-header-content-daily-usage-icon {
        padding-left:0px;
        opacity: 0.7;
    }

    .dash-header-content-daily-usage-text {
        font-size: x-small;
        margin-top: -1.5rem;
        padding-left: 0px;
    }

    /***** card back ****/
    .card-back-device-down {
        background-color: white;
        max-width:100%;
        max-height: 40%;
        height: 5rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
        overflow:hidden;
    }

    .domotics-card-back-cog-area {
        min-width:50%;
        min-height:5rem;
        cursor: pointer;
    }

    .domotics-card-back-refresh-area {
        min-width:50%;
        min-height:5rem;
        cursor:pointer;
    }

    .domotics-card-back-cog-area:hover *{
        color: #65b2e9;
    }

    .domotics-card-back-refresh-area:hover *{
        color:#65b2e9;
    }

    .device-card-back-settings-cog{
        margin-left: 2.2143rem;
        font-size: 1.686rem;
        margin-top: 0.8571rem;
        color: #aeaeae;
    }

    /*NEW CLICKABLE AREA*/
    .top-div-device-clickable {
        min-height:2rem;
        position:relative;
    }

    .top-div-device-clickable-element {
        min-width:20%;
        min-height: 2rem;
        z-index: 2;
        position: relative;
    }

    /*NEW CLICKABLE AREA*/

    /******************header and body styling per view******************/
    /**** home *****/
    .domotics-body-home{
        padding-top: 64px !important;
    }

    .domotics-header-home {
        min-height: 8rem !important;
    }
    /**** devices ***/

    .domotics-body-devices{
        padding-top: 75px !important;
    }

    .domotics-header-devices {
        min-height: 56px !important;
    }
    /*************************************/


    /**** LOGIN *****/
    .domotics-login {
        height:100%;
        width:100%;
        text-align: center;
        background-image: url('../../../images/src/images/back.jpg');
        background-size: 100% 100%;
        font-family: "PT Sans", "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .domotics-login-form {
        min-height: 35%;
        max-width: 80%;
        // background-color: white;
        padding:2rem;
        border-radius: 0.2rem;
        margin-left: auto ;
        margin-right: auto ;
    }

    .domotics-login-header {
        color: #001ec8;
        font-size:4rem;
        font-style: italic;
        font-weight: bold;
        margin-left:7rem;
    }

    .domotics-login-header2 {
        color:white;
        font-size:3rem;
        /*text-shadow: 1px 1px #00193a;*/
        margin-top:8rem;
    }

    .domotics-login-form-forgot-pw {
        color:white;
        text-decoration: underline;
    }

    .domotics-login-form-forgot-pw:hover {
        color:white;
    }

    .domotics-login-form > input {
        display:block;
        margin : 0 auto;
        margin-top:3%;
        min-height:5rem;
        width: 90%;
        font-size:large;
        border-radius: 0.2rem;
        padding: 0 14px 0 14px;
    }

    .domotics-login-btn {
        border : solid 0px #ffffff;
        -webkit-box-shadow : 0px 1px 1px rgb(75, 107, 148);
        -moz-box-shadow : 0px 1px 1px rgb(75, 107, 148);
        box-shadow : 0px 1px 1px rgb(75, 107, 148);
        color : #ffffff;
        margin-top:8% !important;
        background-color : #4b9dff;
        font-size:small !important;
        opacity: 0.8;
    }

    .domotics-login-btn:hover {
        background-color : #001ec8;
        border-radius: 5px;
    }

    .domotics-body-login {
        padding-top: 0px !important;
    }

    /*******************************/

    /**** DEVICE CONFIG *****/

    /**** LEFT SIDE ****/
    .domotics-device-config-top-container {
        min-width:80%;
        background-color: white;
    }

    .domotics-device-config-general-settings {
        position:relative;
        text-align: center;
        width:100%;
        padding-left:5rem;
    }

    .domotics-device-config-general-settings > div:first-child {
        margin-top:2rem;
        max-width:50%;
    }

    .domotics-device-config-general-settings > div:first-child > span {
        font-size: large !important;
        display:block;
        word-wrap: break-word;
    }

    .domotics-device-config-general-settings-span {
        margin-top:1rem;
        font-size: large;
    }

    .domotics-device-config-icon {
        width:20rem;
        height:20rem;
        border-style: dashed;
        cursor:pointer;
    }

    .domotics-device-config-icon:before {
        font-size:16rem;
        position:relative;
        line-height: 18rem;
    }

    .domotics-device-config-general-settings > div:nth-child(2) {
        padding-right: 15rem;
        padding-top: 8rem;
        font-size:larger;
        display:inline-block;
        text-align: left;
    }

    .domotics-device-config-general-settings > div:nth-child(2) > input, .domotics-device-config-general-settings > div:nth-child(2) > select {
        display:block;
        width:20rem;
        padding:0.2rem;
        border: solid 2px #dcdcdc;
        transition: box-shadow 0.3s, border 0.3s;
    }

    .domotics-device-config-general-settings > div:nth-child(2) > input:focus, .domotics-device-config-general-settings > div:nth-child(2) > input.focus {
        border: solid 1px #707070;
        box-shadow: 0 0 5px 1px #969696;
    }

    .domotics-device-config-general-settings > div:nth-child(2) > span {
        margin-top:3rem;
        display: block;
    }

    .domotics-device-config-button-container {
        margin-top:2rem;
    }

    .domotics-device-config-button-container > input {
        width:12rem;
    }

    .domotics-device-config-button-container > input:nth-child(2) {
        margin-right:1rem;
    }

    /**** RIGHT SIDE ****/
    .domotics-device-config-usage-container {
        width:100%;
        position:relative;
        padding-left:3rem;
        margin-top:2rem;
        margin-right:0%;
    }

    .domotics-device-config-usage-container > div:first-child > span {
        font-size:large;
        padding-left:3rem;
    }

    .domotics-device-config-usage-container > div:first-child > i {
        /*padding-right:30%;*/
    }

    .domotics-device-config-usage-graph-container {
        width:100%;
        height:80%;
        padding-left:1rem;
        margin-top:1rem;
    }

    .config-usage-graph {
        width:100%;
        height:25rem;
        overflow:hidden;
        border:1px solid black;
    }

    .config-usage-graph-metering-info {
        padding-left:5rem;
        text-align: center;
    }

    .graph-info-title {
        color:#7cb5ec;
        font-size:medium;
        display:block;
    }


    .domotics-device-config-turnoff-device {
        width:100%;
        padding-left:1rem;
    }

    .domotics-device-config-turnoff-device > i {
        -moz-transition: none;
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }

    .domotics-device-config-turnoff-device > input[type="checkbox"] {
        vertical-align: text-bottom;
    }

    .domotics-device-config-turnoff-device > input[type="number"] {
        width:7rem;
    }
    /************************/
    /**** NOTIFICATIONS ***/
    div[id="toast-container"] {
        margin-top:14rem;
    }

    .device-progressbar-current {
        width:90%;
        position: absolute;
        height:1.4rem;
        bottom:0;
        margin-bottom:0.1rem;
        right:0;
        left:0;
        margin-left:auto;
        margin-right:auto;
        border-radius:0px;
    }

    .device-progressbar-current > .progress-bar {
        background-image: none;
        background-color: #AEAEAE;
        border-radius:0px;
    }


    .device-progressbar-requested {
        width:90%;
        position: absolute;
        height:1.4rem;
        top:0;
        margin-top:5%;
        right:0;
        left:0;
        margin-left:auto;
        margin-right:auto;
        border-radius:0px;
        background-color: transparent;
        background-image: none;
        border: 1px solid white;
    }

    .device-progressbar-requested > .progress-bar {
        background-image: none;
        border-radius:0px;
        box-shadow: none;
    }

    .device-control-bar-requested-container {
        position: absolute;
        width: 90%;
        height: 1rem;
        margin-left: auto;
        margin-right: auto;
        top: 0;
        left:0;
        right:0;
    }

    .device-control-bar-request {
        width: 0.6rem;
        height: 2.5rem;
        position: relative;
        background: white;
        top: 0;
        margin-top: 3%;
        border: 1px solid #777;
        z-index: 2;
    }

    .card-back-usage-icon {
        margin-right:1.5rem;
        margin-top:1.0rem;
        cursor:pointer;
        font-size:2rem;
        color:gray;
    }

    .card-back-usage-info {
        font-size:3rem;
        line-height: 5rem;
    }


    /***************CONFIGURATION WIZARD**************/

    /******STEP1********/

    .conf-wizard-step1 > h1 {
        padding-top:30rem;
    }

    .conf-wizard-step1 > span {
        display:block;
        font-size: x-large;
        color:white
    }

    .conf-wizard-step1 > input {
        color:black;
        background-color: white;
        font-size: large
    }


    /*****************************/

    /*************** STEP 2 *********/

    .domotics-device-config-wizard-langselect {
        width:70%;
        background-color: white;
        margin-left: auto;
        margin-right: auto;
        padding-top:5rem;
        margin-top:5rem;
        border:solid 2px black;
        font-size:2rem;
    }

    .domotics-device-config-wizard-management,
    .domotics-device-config-wizard-network,
    .wizard-config-button-container{
        width: 70% !important;
    }

    .domotics-device-config-wizard-button-container {
        margin-top:2rem;
    }

    .domotics-lang-element {
        width:90%;
        height:5rem;
        margin-bottom:2rem;
        margin-left:auto;
        margin-right:auto;
        line-height:5rem;
        cursor:default
    }

    .domotics-lang-not-selected-element {
        background-color: lightgray;
    }

    .domotics-selected-lang-element {
        background-color: #002f6d;
        color:white;
    }

    .wizard-config-button-container {
        width:50%;
        margin-left:auto;
        margin-right:auto;
    }

    /********************************/

    /******** STEP3 *********/
    .domotics-config-wizard-create-user {
        width:70%;
        min-height:40rem;
        background-color: white;
        margin-left: auto;
        margin-right: auto;
        padding-top:5rem;
        margin-top:5rem;
        border:solid 2px black;
        font-size:2rem;
    }

    /***********************/

    /******** STEP4 *********/
    .stage-wrapper{
        margin-top: 5%;
    }
    /***********************/

    /*** DEVICES HEADER **/

    .domotics-usage-icon {
        position:absolute;
        top:1.2rem !important;
        color:white;
        font-size:2rem;
    }

    .domotics-displaying-usage {
        position:absolute;
        right:2.1rem !important;
        color:white;
        font-size:4rem;
        font-weight:900;
    }

    .devices-header-content-container {
        text-align: center;
        // padding-top:1rem;
    }

    .devices-header-content-title {
        position: relative;
        top:0;
        font-size:x-large;
        color:white;
    }

    .devices-header-content-title-device {
        position: relative;
        top:0;
        font-size:medium;
        color:white;
    }

    .devices-header-content-container > .breadcrumb-device-navigation{
        margin-right: 10px;
        padding-right: 4rem;
    }

    .devices-header-content-container > .breadcrumb-device-navigation > i{
        font-size: 14px;
        line-height: 21px;
    }

    .devices-header-content-container > .breadcrumb-device-navigation > span,
    .devices-header-content-container > .breadcrumb-device-navigation > a > span{
        font-size: 14px;
        line-height: 21px;
        font-family: 'KelsonSans';
    }

    .header-parent-container .tablet-category span{
        display: block;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 10px;
        font-size: 14px;
        line-height: 21px;
        font-family: 'KelsonSansBold';
        color: #FFFFFF;
        padding-right: 4rem;
    }


    .devices-header-content-filtering-container {
        position:relative;
        bottom:0;
    }

    .devices-header-content-filter{
        left:0;
        right:0;
        margin-left:auto;
        margin-right:auto;
        position:relative;
        bottom:-30px;
        width:45%;
        display:table;
    }

    .devices-header-content-filter > div {
        display: inline-block;
        background-color:transparent;
        color:lightgray;
        font-size:larger;
        margin-right:7px;
        margin-left:7px;
    }
    /***********************/

    /****** STEP 5 CONFIG ******/
    .wizard-step5-btn-container {
        position: relative;
        width:35rem;
        margin-left:45%;
    }

    .wizard-step5-editarea-title {
        display:block;
        margin-top:1rem;
        font-size:large;
        font-weight: bold;
    }

    .wizard-step5-editarea-title-input {
        margin-top:1rem;
        border: solid 1px #707070;
        box-shadow: 0 0 5px 1px #969696;
        font-size:1.5rem;
        padding: 5px 0px 0px;
    }

    .wizard-step5-divisions-title {
        display:block;
        margin-top:3rem;
        font-size:large;
        font-weight: bold;
    }

    .wizard-step5-modal-input{
        margin-top:1rem;
        border: solid 1px #707070;
        box-shadow: 0 0 5px 1px #969696;
        font-size:1.5rem;
    }
    /***************************/

    /**** USER HEADER DETAILS ***/

    .user-details-header {
        position: absolute;
        right:0;
        top:0;
        margin-top:1.5rem;
        width:20rem;
    }

    .user-header {
        display:none;
    }

    .user-header-sidebar {
        width:70%;
        margin-left:1.5rem;
        margin-top:2rem;
        margin-bottom:0.5rem;
        position:relative;
        color:gray;
    }

    .user-header,.user-header-sidebar * {
        cursor:pointer;
    }

    .user-header-name-sidebar {
        display:inline-block;
        position:relative;
        padding-right:0.5rem;
        margin-left: 2px;
    }

    .user-header-caret-sidebar {
        display:inline-block;
        position:relative;
        top:-1rem;
        padding-left:0.5rem;
    }

    .user-header-name {
        display:inline-block;
        position:relative;
        top:-1rem;
        padding-right:0.5rem;
    }

    .user-header-caret {
        display:inline-block;
        position:relative;
        top:-1rem;
        padding-left:0.5rem;
    }

    .notifications-header {
        width:30%;
        position:relative;
        color:white;
    }

    .notifications-header * {
        cursor:pointer;
    }

    .header-notifications-val {
        position:absolute;
        top:-0.2rem;
        right:2.5rem;
        padding:1px 4px;
        background: red; color: white;
        border-radius:3px;
        font-size:1.1rem;
    }

    .dom-not-ic {
        font-size:2.5rem;
        margin-left:0.5rem;
    }

    .circletag {
        width: 30px;
        height: 30px;
        background: #E6E7ED;
        -moz-border-radius: 20px;
        -webkit-border-radius: 20px;
        background-color: white;
        background-position:50% 50%;
        background-repeat:no-repeat;
        color:#3CA2E0;
        font-size:2.5rem;
    }

    .circletag > i {
        position:absolute;
        top:0;
        margin-left:0.5rem;
        margin-top:0.2rem;
    }

    /****************************/

    /*** graph arrows ***/

    /********************/

    .disable-app-active-side-bar {
        pointer-events: none;
        cursor: default;
        transform:none !important;
    }

    .active-side-bar-zind {
        z-index:99;
    }

    .active-side-bar {
        pointer-events: none;
        position:fixed !important;
        width:100%;
        height:100%;
        z-index:1000;
        opacity:0.6;
        filter: alpha(opacity = 60);
        background-color: #060000;
        top:0;
        left:0;
    }
}

.icon-close-sidebar {
    line-height:52px;
    display:none;
}


@media all and (min-width: 768px) and (max-width: 991px) {

    .step-4-form-item {
        max-width: 45%!important;
        min-width: 45%!important;
    }

    .domotics-timetravel-forward {
        font-size: 3rem;
        position: relative;
        margin-top: 20rem;
        cursor:pointer;
    }

    .domotics-timetravel-back {
        font-size: 3rem;
        position: relative;
        margin-top: 20rem;
        cursor:pointer;
        padding: 0 !important;
        padding-left: 2rem !important;
    }
}

/*@media all and (min-width: 767px) and (max-width: 768px) {
    .col-sm-10 {
        width: 100% !important;
    }
}*/

@media all and (min-width : 992px) and (max-width: 1199px) {

    .step-4-form-item {
        max-width: 45%!important;
        min-width: 45%!important;
    }

    .rates-btn {
        margin-left: 3rem !important;
    }

    .card-back-usage-info-container {
            text-align: center;
            width: 100%;
            padding-top: 35%;
    }

    .mobile-only{
        display: none;
    }

    .sidebar-left {
        width:214px !important;
    }

    .has-sidebar-left .app, .sidebar-left-in .app {
        margin-left:214px;
    }

    div.domotics-general-list-icon-card-back{
        position:relative;
        overflow:hidden;
    }

    div.domotics-general-list-icon-card-back:before {
        font-size: 6.143em;
        padding-left: 35%;
        padding-top: 10%;
        position: absolute;
    }

    div.domotics-general-list-icon{
        position:relative;
        overflow:hidden;
    }

    div.domotics-general-list-icon:before {
        font-size: 4.143em;
        padding-left: 38%;
        padding-top: 25%;
        position: absolute;
    }


    div.domotics-device-list-icon, div.domotics-device-list-icon-multiple {
        position:relative;
        overflow:hidden;
    }


    div.domotics-device-list-icon:before {
        position: absolute;
        font-size: 4em;
        padding-left: 36%;
        padding-top: 23%;
    }

    div.domotics-device-list-icon-multiple:before {
        font-size: 4.143em;
        padding-left: 19%;
        padding-top: 45%;
        position: absolute;
    }

    div.domotics-device-icon-off {
        color: #aeaeae;
    }

    div.domotics-device-icon-on {
        color: white;
    }

    div.domotics-device-icon-toggle {
        color: #65b2e9;
    }

    .device-card-name {
        font-weight: bold;
        font-size: larger;
        padding-left: 1.4rem;
        font-family: sans-serif;
        display: block;
        padding-right: 1.4rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden !important;
    }

    .device-card-division {
        font-size: small;
        padding-left: 1.429rem;
        padding-right: 1.429rem;
        font-family: sans-serif;
        color: #aeaeae;
        display:block;
    }

    .device-card-settings-cog{
        margin-right: 2.2143rem;
        font-size: 1.686rem;
        color: #aeaeae;
        margin-top: 12px;
        margin-bottom: 21px;
    }

    .card-power-icon-off {
        padding-top: 14px;
        padding-right: 14px;
        font-size: 16px;
        color: #aeaeae;
    }

    .card-power-icon-on {
        padding-top: 14px;
        padding-right: 14px;
        font-size: 16px;
        color: white;
    }

    .card-power-icon-toggle {
        padding-top: 14px;
        padding-right: 14px;
        font-size: 16px;
        color: #65b2e9;
    }

    .cards-container {
        text-align: center;
        margin-bottom:5rem;
    }

    .domotics-device, device-button, device-button-scenario{
/*        width: 24.46rem !important;
        height: 18.86rem !important;*/
        position: relative;
        float:left;
        border-radius: 0.2143rem;
    }

    .domotics-device {
        text-align: left;
        margin:0.5rem;
        border-radius: 0.2143rem;
    }

    .card-container{
/*        max-width:100% !important;
        max-height: 100% !important;*/
        border:none !important;
        outline: none !important;
        border-radius: 0.2143rem;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -webkit-touch-callout : none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
    }

    .device-upper {
        background-color: white;
        max-width:100%;
        height: 12.2rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0.2143rem 0.2143rem 0px 0px;
        border-radius: 0.2143rem 0.2143rem 0px 0px;
    }

    .device-upper-whole {
        cursor:pointer;
        background-color: white;
        max-width:100%;
        height: 181px;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius:  0.2143rem  0.2143rem  0px  0px;
        border-radius:  0.2143rem 0.2143rem 0px 0px;
    }

    .device-upper-plus {
        min-width:50%;
        height: 70%;
        margin: 0px;
        position: relative;
    }

    .device-upper-minus {
        min-width:50%;
        height: 70%;
        margin: 0px;
        position: relative;
    }

    .device-upper-active {
        background-color: #65b2e9;
        max-width:100%;
        height: 12.2rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0.2143rem 0.2143rem 0px 0px;
        border-radius: 0.2143rem 0.2143rem 0px 0px;
    }

    .device-down {
        background-color: white;
        max-width:100%;
        height: 6rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
        padding-top:10px;
    }

/*    .device-down > div:nth-child(2) {
        width:75% !important;
    }*/

    .device-down-simple {
        background-color: white;
        max-width:100%;
        height: 5.8rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
        padding-top:10px;
        text-align: center;
    }

    .device-down-upper-active {
        background-color: #65b2e9;
        color: white !important;
        cursor:pointer;
        max-width:100%;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
    }

    .device-down-upper-active *{
        color: white !important;
    }

    .device-down-upper-active i{
        color: white !important;
    }


    .device-upper:hover, .device-upper-active:hover{
        cursor:pointer;
    }

    .device-down:hover{
        cursor:pointer;
    }

    .device-down:hover span:not(.device-card-division){
        color: #65b2e9;
    }

    .device-down:hover i{
        color: #65b2e9;
    }

    .device-toggle-percentage {
        padding-right: 1.071rem;
        padding-top: 2.143rem;
        font-size: 1.629rem;
    }
    .device-percentage-status-white {
        background-color:white;
    }

    .device-percentage-status-gray {
        background-color:gray;
    }

    .device-percentage-status-gray-end {
        background-color:transparent;
        border-top:1px solid white;
        border-bottom:1px solid white;
        border-right:1px solid white;
    }

    .device-percentage-status-gray-start {
        background-color:transparent;
        border-top:1px solid white;
        border-bottom:1px solid white;
        border-left:1px solid white;
    }

    .device-card-back-metering-span {
        text-align: center;
    }

    .device-card-back-metering-span > span {
        font-size: 24px;
        display: block;
        padding-top: 48px;
        padding-bottom: 48px;
    }

    .device-control-bar {
        width:100%;
        height:1.5rem;
        margin-top:20%;
    }

    .device-percentage-status {
        margin-left: 1.3rem;
        margin-top: 0.8rem;
        font-size: 1.3rem;
    }

    .dash-header-today-cost {
        font-size: x-large;
        display:inline-block;
        color: white;
        padding-left:3rem;
    }

    .dash-header-monthly-cost {
        font-size: x-large;
        display: inline-block;
        color: white;
        padding-left:3rem;
    }

    .dash-header-devices-counter {
        font-size: x-large;
        display: inline-block;
        color: white;
        padding-left:3rem;
    }

    .dash-header-content-container {
        min-height:10rem;
        // padding-top:5rem;
    }

    .dash-header-content-greeting {
        color:white;
        padding-left:0px;
        font-size:x-large;
        float:left;
        padding-top:3rem;
        padding-left:2rem;
    }

    .dash-header-content-monthly-usage-icon {
        padding-left:0px;
        opacity: 0.7;
    }

    .dash-header-content-monthly-text {
        font-size: x-small;
        margin-top: -1.5rem;
        padding-left: 0px;
    }

    .dash-header-content-daily-usage-icon {
        padding-left:0px;
        opacity: 0.7;
    }

    .dash-header-content-daily-usage-text {
        font-size: x-small;
        margin-top: -1.5rem;
        padding-left: 0px;
    }

    /***** card back ****/
    .card-back-device-down {
        background-color: white;
        max-width:100%;
        height: 5.8rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
        overflow:hidden;
    }

    .domotics-card-back-cog-area {
        min-width:50%;
        min-height:5.8rem;
        cursor: pointer;
    }

    .domotics-card-back-refresh-area {
        min-width:50%;
        min-height:5.8rem;
        cursor:pointer;
    }

    .domotics-card-back-refresh-area > i {
        margin-right:40px;
        margin-top: 20px;
    }

    .domotics-card-back-cog-area:hover *{
        color: #65b2e9;
    }

    .domotics-card-back-refresh-area:hover *{
        color:#65b2e9;
    }

    .device-card-back-settings-cog{
        font-size: 16px;
        margin-top: 21px;
        margin-left: 40px;
        color: #aeaeae;
    }

    /*NEW CLICKABLE AREA*/
    .top-div-device-clickable {
        min-height:5rem;
        position:relative;
    }

    .top-div-device-clickable-element {
        min-width:20%;
        min-height: 5rem;
        z-index: 2;
        position: relative;
    }

    /*NEW CLICKABLE AREA*/

    /******************header and body styling per view******************/
    /**** home *****/
    .domotics-body-home{
        padding-top: 64px !important;
    }

    .domotics-header-home {
        height: 56px !important;
    }
    /**** devices ***/

    .domotics-body-devices{
        padding-top: 64px !important;
    }

    .domotics-header-devices {
        height: 56px !important;
    }
    /*************************************/

    /**** LOGIN *****/
    .domotics-login {
        height:100%;
        width:100%;
        text-align: center;
        background-image: url('../../../images/src/images/back.jpg');
        background-size: 100% 100%;
        font-family: "PT Sans", "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .domotics-login-form {
        min-height: 35%;
        max-width: 60%;
        // background-color: white;
        padding:2rem;
        border-radius: 0.2rem;
        margin-left:auto;
        margin-right:auto;
    }

    .domotics-login-header {
        color: #001ec8;
        font-size:6rem;
        font-style: italic;
        font-weight: bold;
        margin-left:8rem;
        margin-top:2rem;
    }

    .domotics-login-header2 {
        color:white;
        font-size:4rem;
        /*text-shadow: 1px 1px #00193a;*/
        margin-top:9rem;
    }

    .domotics-login-form-forgot-pw {
        color:white;
        text-decoration: underline;
    }

    .domotics-login-form-forgot-pw:hover {
        color:white;
    }

    .domotics-login-form > input {
        display:block;
        margin : 0 auto;
        margin-top:4%;
        min-height:5rem;
        width: 90%;
        font-size:x-large;
        border-radius: 0.2rem;
        padding: 0 14px 0 14px;
    }

    .domotics-login-btn {
        border : solid 0px #ffffff;
        -webkit-box-shadow : 0px 1px 1px rgb(75, 107, 148);
        -moz-box-shadow : 0px 1px 1px rgb(75, 107, 148);
        box-shadow : 0px 1px 1px rgb(75, 107, 148);
        color : #ffffff;
        margin-top:7% !important;
        background-color : #4b9dff;
        font-size:small !important;
        opacity: 0.8;
    }

    .domotics-login-btn:hover {
        background-color : #001ec8;
        border-radius: 5px;
    }

    .domotics-body-login {
        padding-top: 0px !important;
    }

    /*******************************/

    /**** DEVICE CONFIG *****/

    /**** LEFT SIDE ****/
    .domotics-device-config-top-container {
        min-width:80%;
        background-color: white;
    }

    .domotics-device-config-general-settings {
        position:relative;
        float:left;
        width:50%;
        padding-left:5rem;
        text-align: center;
    }

    .domotics-device-config-general-settings > div:first-child {
        margin-top:2rem;
        max-width: 50%;
    }

    .domotics-device-config-general-settings > div:first-child > span {
        font-size: large !important;
        display:block;
        word-wrap: break-word;
    }

    .domotics-device-config-general-settings-span {
        margin-top:1rem;
        font-size: large;
    }

    .domotics-device-config-icon {
        width:10rem;
        height:10rem;
        border-style: dashed;
        cursor: pointer;
    }

    .domotics-device-config-icon:before {
        font-size:8rem;
        position:relative;
        line-height: 9rem;
    }

    .domotics-device-config-general-settings > div:nth-child(2) {
        padding-right: 2rem;
        padding-top: 8rem;
        font-size:larger;
        display: inline-block;
        text-align: left;
    }

    .domotics-device-config-general-settings > div:nth-child(2) > input, .domotics-device-config-general-settings > div:nth-child(2) > select {
        display:block;
        width:12rem;
        padding:0.2rem;
        border: solid 2px #dcdcdc;
        transition: box-shadow 0.3s, border 0.3s;
    }

    .domotics-device-config-general-settings > div:nth-child(2) > input:focus, .domotics-device-config-general-settings > div:nth-child(2) > input.focus {
        border: solid 1px #707070;
        box-shadow: 0 0 5px 1px #969696;
    }

    .domotics-device-config-general-settings > div:nth-child(2) > span {
        margin-top:3rem;
        display: block;
    }

    .domotics-device-config-button-container {
        margin-top:2rem;
    }

    .domotics-device-config-button-container > input {
        width:12rem;
    }

    .domotics-device-config-button-container > input:nth-child(2) {
        margin-right:1rem;
    }

    /**** RIGHT SIDE ****/
    .domotics-device-config-usage-container {
        width:50%;
        position:relative;
        float:right;
        margin-top:8rem !important;
        margin-right:0%;
    }

    .domotics-device-config-usage-container > div:first-child > span {
        font-size:large;
        padding-left:3rem;
    }

    .domotics-device-config-usage-container > div:first-child > i {
        /*padding-right:30%;*/
    }

    .domotics-device-config-usage-graph-container {
        width:100%;
        height:80%;
        padding-left:1rem;
        margin-top:1rem;
    }

    .config-usage-graph {
        width:100%;
        height:25rem;
        overflow:hidden;
        border:1px solid black;
    }

    .config-usage-graph-metering-info {
        padding-left:2rem;
        text-align: center;
    }

    .graph-info-title {
        color:#7cb5ec;
        font-size:x-large;
        display:block;
    }

    .domotics-device-config-turnoff-device {
        width:100%;
        padding-left:0px;
    }

    .domotics-device-config-turnoff-device > i {
        -moz-transition: none;
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }

    .domotics-device-config-turnoff-device > input[type="checkbox"] {
        vertical-align: text-bottom;
    }

    .domotics-device-config-turnoff-device > input[type="number"] {
        width:7rem;
    }
    /************************/
    /**** NOTIFICATIONS ***/
    div[id="toast-container"] {
        margin-top:14rem;
    }

    .device-progressbar-current {
        width:90%;
        position: absolute;
        height:1.4rem;
        bottom:0;
        margin-bottom:0.1rem;
        right:0;
        left:0;
        margin-left:auto;
        margin-right:auto;
        border-radius:0px;
    }

    .device-progressbar-current > .progress-bar {
        background-image: none;
        background-color: #AEAEAE;
        border-radius:0px;
    }


    .device-progressbar-requested {
        width:90%;
        position: absolute;
        height:1.4rem;
        top:0;
        margin-top:5%;
        right:0;
        left:0;
        margin-left:auto;
        margin-right:auto;
        border-radius:0px;
        background-color: transparent;
        background-image: none;
        border: 1px solid white;
    }

    .device-progressbar-requested > .progress-bar {
        background-image: none;
        border-radius:0px;
        box-shadow: none;
    }

    .device-control-bar-requested-container {
        position: absolute;
        width: 90%;
        height: 1rem;
        margin-left: auto;
        margin-right: auto;
        top: 0;
        left:0;
        right:0;
    }

    .device-control-bar-request {
        width: 0.6rem;
        height: 2.5rem;
        position: relative;
        background: white;
        top: 0;
        margin-top: 3%;
        border: 1px solid #777;
        z-index: 2;
    }

    .card-back-usage-icon {
        margin-right: 14px;
        margin-top: 14px;
        cursor: pointer;
        font-size: 16px;
        color: gray;
    }

    .card-back-usage-info {
        font-size:3rem;
    }

    /***************CONFIGURATION WIZARD**************/

    /******STEP1********/

    .conf-wizard-step1 > h1 {
        padding-top:30rem;
    }

    .conf-wizard-step1 > span {
        display:block;
        font-size: x-large;
        color:white
    }

    .conf-wizard-step1 > input {
        color:black;
        background-color: white;
        font-size: large
    }


    /*****************************/

    /*************** STEP 2 *********/

    .domotics-device-config-wizard-langselect {
        width:70%;
        background-color: white;
        margin-left: auto;
        margin-right: auto;
        padding-top:5rem;
        margin-top:5rem;
        border:solid 2px black;
        font-size:2rem;
    }

    .domotics-device-config-wizard-management,
    .domotics-device-config-wizard-network,
    .wizard-config-button-container{
        width: 75% !important;
    }

    .domotics-device-config-wizard-button-container {
        margin-top:2rem;
    }

    .domotics-lang-element {
        width:90%;
        height:5rem;
        margin-bottom:2rem;
        margin-left:auto;
        margin-right:auto;
        line-height:5rem;
        cursor:default
    }

    .domotics-lang-not-selected-element {
        background-color: lightgray;
    }

    .domotics-selected-lang-element {
        background-color: #002f6d;
        color:white;
    }

    .wizard-config-button-container {
        width:50%;
        margin-left:auto;
        margin-right:auto;
    }

    /********************************/

    /******** STEP3 *********/
    .domotics-config-wizard-create-user {
        width:70%;
        min-height:40rem;
        background-color: white;
        margin-left: auto;
        margin-right: auto;
        padding-top:5rem;
        margin-top:5rem;
        border:solid 2px black;
        font-size:2rem;
    }

    /***********************/

    /*** DEVICES HEADER **/

    .devices-header-content-container {
        text-align: center;
        padding-top:1rem;
    }
    .devices-header-content-container{
        padding-top: 14px;
        padding-bottom: 21px;
        text-align: left;
        padding-left: 21px;
        min-height: 56px !important;
        height: 56px;
    }

    .devices-header-content-container > .breadcrumb-device-navigation > i{
        font-size: 14px;
        line-height: 21px;
    }

    .devices-header-content-container > .breadcrumb-device-navigation > span,
    .devices-header-content-container > .breadcrumb-device-navigation > a > span{
        font-size: 14px;
        line-height: 21px;
        font-family: 'KelsonSans';
    }

    .devices-header-content-title {
        font-size:xx-large;
        color:white;
    }
    .devices-header-content-title-device {
        font-size:xx-large;
        color:white;
    }

    .devices-header-content-filtering-container {
        position:relative;
        bottom:0;
    }

    .devices-header-content-filter{
        left:0;
        right:0;
        margin-left:auto;
        margin-right:auto;
        position:relative;
        bottom:-15px;
        width:45%;
        display:table;
    }

    .devices-header-content-filter > div {
        display: inline-block;
        background-color:transparent;
        color:lightgray;
        font-size:larger;
        margin-right:7px;
        margin-left:7px;
    }
    /***********************/

    /****** STEP 5 CONFIG ******/
    .wizard-step5-btn-container {
        position: relative;
        width:35rem;
        margin-left:45.5%;
    }

    .wizard-step5-editarea-title {
        display:block;
        margin-top:1rem;
        font-size:large;
        font-weight: bold;
    }

    .wizard-step5-editarea-title-input {
        margin-top:1rem;
        border: solid 1px #707070;
        box-shadow: 0 0 5px 1px #969696;
        font-size:1.5rem;
        padding: 5px 0px 0px;
    }

    .wizard-step5-divisions-title {
        display:block;
        margin-top:3rem;
        font-size:large;
        font-weight: bold;
    }

    .wizard-step5-modal-input{
        margin-top:1rem;
        border: solid 1px #707070;
        box-shadow: 0 0 5px 1px #969696;
        font-size:1.5rem;
    }
    /***************************/

    /**** USER HEADER DETAILS ***/

    .user-details-header {
        position: absolute;
        right:0;
        top:0;
        margin-top:1.5rem;
        width:20rem;
    }

    .user-header {
        display:none;
    }

    .user-header-sidebar {
        width:70%;
        margin-left:1.5rem;
        margin-top:2rem;
        margin-bottom:0.5rem;
        position:relative;
        color:gray;
    }

    .user-header,.user-header-sidebar * {
        cursor:pointer;
    }

    .user-header-name-sidebar {
        display:inline-block;
        position:relative;
        padding-right:0.5rem;
        margin-left: 2px;
    }

    .user-header-caret-sidebar {
        display:inline-block;
        position:relative;
        top:-1rem;
        padding-left:0.5rem;
    }

    .user-header-name {
        display:inline-block;
        position:relative;
        top:-1rem;
        padding-right:0.5rem;
    }

    .user-header-caret {
        display:inline-block;
        position:relative;
        top:-1rem;
        padding-left:0.5rem;
    }

    .notifications-header {
        width:30%;
        position:relative;
        color:white;
    }

    .notifications-header * {
        cursor:pointer;
    }

    .header-notifications-val {
        position:absolute;
        top:-0.2rem;
        right:2.5rem;
        padding:1px 4px;
        background: red; color: white;
        border-radius:3px;
        font-size:1.1rem;
    }

    .dom-not-ic {
        font-size:2.5rem;
        margin-left:0.5rem;
    }

    .circletag {
        width: 30px;
        height: 30px;
        background: #E6E7ED;
        -moz-border-radius: 20px;
        -webkit-border-radius: 20px;
        background-color: white;
        background-position:50% 50%;
        background-repeat:no-repeat;
        color:#3CA2E0;
        font-size:2.5rem;
    }

    .circletag > i {
        position:absolute;
        top:0;
        margin-left:0.5rem;
        margin-top:0.2rem;
    }

    /****************************/


    /*** graph arrows ***/

    .domotics-timetravel-forward {
        font-size: 4rem;
        position: relative;
        margin-top: 20rem;
        cursor:pointer;
    }

    .domotics-timetravel-back {
        font-size: 4rem;
        position: relative;
        margin-top: 20rem;
        cursor:pointer;
        padding: 0 !important;
        padding-left: 2.5rem !important;
    }

    /********************/


    .domotics-displaying-usage {
        position:absolute;
        top:0px;
        right:2.6rem !important;
        color:white;
        font-size:4rem;
        font-weight:900;
    }

    .dash-header-today-spacing {
        margin-left:12.5% !important;
        margin-right:12.5% !important;
    }
    .visible-devices {
        display: none;
    }
}

@media all and (min-width : 1200px) and (max-width: 1599px) {

    .step-4-form-item {
        max-width: 45%!important;
        min-width: 45%!important;
    }

    .rates-btn {
        margin-left: 3rem !important;
    }

    .card-back-usage-info-container {
            text-align: center;
            width: 100%;
            padding-top: 20%;
    }

    .card-container.scenario i.avatar-user{
        font-size: 4.6em;
        color:#aeaeae;
        position: relative;
        top: 0.6em;
    }

    .mobile-only{
        display: none;
    }

    .trash-can {
        margin-top: 20px !important;;
    }

    .sidebar-left {
        width:214px !important;
    }

    .has-sidebar-left .app, .sidebar-left-in .app {
        margin-left:214px;
    }

    div.domotics-general-list-icon-card-back{
        position:relative;
        overflow:hidden;
    }

    div.domotics-general-list-icon-card-back:before {
        font-size: 6.143em;
        padding-left: 36%;
        padding-top: 10%;
        position: absolute;
    }

    div.domotics-general-list-icon{
        position:relative;
        overflow:hidden;
    }

    div.domotics-general-list-icon:before {
        font-size: 4.143em;
        padding-left: 38%;
        padding-top: 25%;
        position: absolute;
    }


    div.domotics-device-list-icon,div.domotics-device-list-icon-multiple {
        position:relative;
        overflow:hidden;
    }

    div.domotics-device-list-icon:before {
        position: absolute;
        font-size: 4.2em;
        padding-left: 37%;
        padding-top: 38px;
    }


    div.domotics-device-list-icon-multiple:before {
        font-size: 4.143em;
        padding-left: 19%;
        padding-top: 45%;
        position: absolute;
    }

    div.domotics-device-icon-off {
        color: #aeaeae;
    }

    div.domotics-device-icon-on {
        color: white;
    }

    div.domotics-device-icon-toggle {
        color: #65b2e9;
    }

    .device-card-name {
        font-weight: bold;
        font-size: larger;
        padding-left: 1.429rem;
        font-family: sans-serif;
        display: block;
        padding-right: 1.429rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden !important;
    }

    .device-card-division {
        font-size: small;
        padding-left: 1.429rem;
        padding-right: 1.429rem;
        font-family: sans-serif;
        color: #aeaeae;
        display:block;
    }

    .device-card-settings-cog{
        color: #aeaeae;
        margin-right: 2.2143rem;
        font-size: 1.686rem;
        margin-top: 0.8571rem;
    }

    .card-power-icon-off {
        color: #aeaeae;
        padding-top: 2rem;
        padding-right: 2rem;
        font-size: 1.5rem;
    }

    .card-power-icon-on {
        padding-top: 2rem;
        padding-right: 2rem;
        font-size: 1.5rem;
        color: white;
    }

    .card-power-icon-toggle {
        padding-top: 2rem;
        padding-right: 2rem;
        font-size: 1.5rem;
        color: #65b2e9;
    }

    .cards-container {
        text-align: center;
        margin-bottom:5rem;
    }

    .domotics-device, device-button, device-button-scenario{
/*        width: 24.46rem !important;
        height: 18.86rem !important;*/
        position: relative;
        text-align: left;
        float:left;
        border-radius: 0.2143rem;
    }

    .domotics-device {
        margin:0.5rem;
        border-radius: 0.2143rem;
    }

    .card-container{
/*        max-width:100% !important;
        max-height: 100% !important;*/
        border:none !important;
        outline: none !important;
        border-radius: 0.2143rem;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -webkit-touch-callout : none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
    }


    .device-upper {
        background-color: white;
        max-width:100%;
        height: 12.2rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0.2143rem 0.2143rem 0px 0px;
        border-radius: 0.2143rem 0.2143rem 0px 0px;
    }

    .device-upper-whole {
        cursor:pointer;
        background-color: white;
        max-width:100%;
        height: 180px;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius:  0.2143rem  0.2143rem  0px  0px;
        border-radius:  0.2143rem 0.2143rem 0px 0px;
    }

    .device-upper-plus {
        min-width:50%;
        height: 70%;
        margin: 0px;
        position: relative;
    }

    .device-upper-minus {
        min-width:50%;
        height: 70%;
        margin: 0px;
        position: relative;
    }

    .device-upper-active {
        background-color: #65b2e9;
        max-width:100%;
        height: 12.2rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0.2143rem 0.2143rem 0px 0px;
        border-radius: 0.2143rem 0.2143rem 0px 0px;
    }

    .device-down {
        background-color: white;
        max-width:100%;
        max-height: 40%;
        height: 6rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
        padding-top:10px;
    }

    .device-down-simple {
        background-color: white;
        max-width:100%;
        max-height: 40%;
        height: 5.8rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
        text-align: center;
    }

    .device-down-upper-active {
        background-color: #65b2e9;
        color: white !important;
        cursor:pointer;
        max-width:100%;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
    }

    .device-down-upper-active *{
        color: white !important;
    }

    .device-down-upper-active i{
        color: white !important;
    }


    .device-upper:hover, .device-upper-active:hover{
        cursor:pointer;
    }

    .device-down:hover{
        cursor:pointer;
    }

    .device-down:hover span:not(.device-card-division){
        color: #65b2e9;
    }

    .device-down:hover i{
        color: #65b2e9;
    }



    .device-toggle-percentage {
        padding-right: 1.071rem;
        padding-top: 0px;
        font-size: 1.629rem;
    }

    .device-percentage-status-white {
        background-color:white;
    }

    .device-percentage-status-gray {
        background-color:gray;
    }

    .device-percentage-status-gray-end {
        background-color:transparent;
        border-top:1px solid white;
        border-bottom:1px solid white;
        border-right:1px solid white;
    }

    .device-percentage-status-gray-start {
        background-color:transparent;
        border-top:1px solid white;
        border-bottom:1px solid white;
        border-left:1px solid white;
    }

    .device-card-back-metering-span {
        text-align: center;
    }



    .device-card-back-metering-span > span {
        font-size:24px;
        display:block;
        padding-top:15%;
    }

    .device-control-bar {
        width:100%;
        height:1.5rem;
        margin-top:20%;
    }

    .device-percentage-status {
        margin-left: 1.3rem;
        margin-top: 0.8rem;
        font-size: 1.4rem;
    }

    .dash-header-today-cost {
        font-size: x-large;
        display:inline-block;
        color: white;
        padding-top:5rem;
        padding-left:2.5rem;
        padding-right:2.5rem;
    }

    .dash-header-devices-counter {
        font-size: x-large;
        display:inline-block;
        color: white;
        padding-top:5rem;
        padding-left:2.5rem;
        padding-right:2.5rem;
    }

    .dash-header-monthly-cost {
        font-size: x-large;
        display:inline-block;
        color: white;
        padding-left:2.5rem;
        padding-right:2.5rem;
    }

    .dash-header-content-container {
        min-height:10rem;
        padding-top:0px;
    }

    .dash-header-content-greeting {
        color:white;
        padding-left:4rem;
        padding-top:8rem;
        font-size:x-large;
        float:left;
    }

    .dash-header-content-monthly-usage-icon {
        padding-left:0px;
        opacity: 0.7;
    }

    .dash-header-content-monthly-text {
        font-size: x-small;
        margin-top: -1.5rem;
        padding-left: 0px;
    }

    .dash-header-content-daily-usage-icon {
        padding-left:0px;
        opacity: 0.7;
    }

    .dash-header-content-daily-usage-text {
        font-size: x-small;
        margin-top: -1.5rem;
        padding-left: 0px;
    }

    /***** card back ****/
    .card-back-device-down {
        background-color: white;
        max-width:100%;
        height: 5.8rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
        overflow:hidden;
    }

    .domotics-card-back-cog-area {
        min-width:50%;
        min-height:5.8rem;
        cursor: pointer;
    }

    .domotics-card-back-refresh-area {
        min-width:50%;
        min-height:5.8rem;
        cursor:pointer;
    }

    .domotics-card-back-refresh-area > i {
        margin-top: 22px;
        padding-right: 21px;
    }

    .domotics-card-back-cog-area:hover *{
        color: #65b2e9;
    }

    .domotics-card-back-refresh-area:hover *{
        color:#65b2e9;
    }



    .device-card-back-settings-cog{
        margin-top: 22px;
        padding-left: 45px;
        font-size: 1.686rem;
        color: #aeaeae;
    }

    .top-div-device-clickable {
        min-height:5rem;
        position:relative;
    }

    .top-div-device-clickable-element {
        min-width:20%;
        min-height: 5rem;
        z-index: 2;
        position: relative;
    }

    /******************header and body styling per view******************/

    /**** home *****/
    .domotics-body-home{
        padding-top: 86px !important;
    }

    .domotics-header-home {
        min-height: 56px !important;
    }
    /**** devices ***/

    .domotics-body-devices{
        padding-top: 64px !important;
    }

    .domotics-header-devices {
        min-height: 56px !important;
        max-height: 56px !important;
    }
    /*************************************/

    /**** LOGIN *****/
    .domotics-login {
        height:100%;
        width:100%;
        text-align: center;
        background-image: url('../../../images/src/images/back.jpg');
        background-size: 100% 100%;
        font-family: "PT Sans", "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .domotics-login-form {
        min-height: 35%;
        max-width: 40%;
        // background-color: white;
        padding:2rem;
        border-radius: 0.2rem;
        margin-left: auto;
        margin-right:auto;
    }

    .domotics-login-header {
        color: #001ec8;
        font-size:7rem;
        font-style: italic;
        font-weight: bold;
        margin-left:8rem;
        margin-top:10rem;
    }

    .domotics-login-header2 {
        color:white;
        font-size:5rem;
        /*text-shadow: 1px 1px #00193a;*/
        margin-top:8rem;
    }

    .domotics-login-form-forgot-pw {
        color:white;
        text-decoration: underline;
    }

    .domotics-login-form-forgot-pw:hover {
        color:white;
    }

    .domotics-login-bt{
        width: 100px;
    }

    .domotics-login-form > input {
        display:block;
        margin : 0 auto;
        margin-top:7%;
        min-height:5rem;
        width: 90%;
        font-size:x-large;
        border-radius: 0.2rem;
        padding: 0 14px 0 14px;
    }

    .domotics-login-btn {
        border : solid 0px #ffffff;
        -webkit-box-shadow : 0px 1px 1px rgb(75, 107, 148);
        -moz-box-shadow : 0px 1px 1px rgb(75, 107, 148);
        box-shadow : 0px 1px 1px rgb(75, 107, 148);
        color : #ffffff;
        margin-top:10% !important;
        background-color : #4b9dff;
        font-size:small !important;
        opacity: 0.8;
    }

    .domotics-login-btn:hover {
        background-color : #001ec8;
        border-radius: 5px;
    }

    .domotics-body-login {
        padding-top: 0px !important;
    }

    /*******************************/

    /**** DEVICE CONFIG *****/

    /**** LEFT SIDE ****/
    .domotics-device-config-top-container {
        min-width:80%;
        background-color: white;
    }

    .domotics-device-config-general-settings {
        position:relative;
        float:left;
        width:50%;
        padding-left:5rem;
        text-align: center;
    }

    .domotics-device-config-general-settings > div:first-child {
        margin-top:2rem;
        max-width:50%;
    }

    .domotics-device-config-general-settings > div:first-child > span {
        font-size: large !important;
        display:block;
        word-wrap: break-word;
    }

    .domotics-device-config-general-settings-span {
        margin-top:1rem;
        font-size: large;
    }

    .domotics-device-config-icon {
        width:12rem;
        height:12rem;
        border-style: dashed;
        cursor:pointer;
    }

    .domotics-device-config-icon:before {
        font-size:9rem;
        position:relative;
        line-height: 11rem;
    }

    .domotics-device-config-general-settings > div:nth-child(2) {
        padding-right: 6rem;
        padding-top: 6rem;
        font-size:larger;
        display:inline-block;
        text-align: left;
    }

    .domotics-device-config-general-settings > div:nth-child(2) > input, .domotics-device-config-general-settings > div:nth-child(2) > select {
        display:block;
        width:15rem;
        padding:0.2rem;
        border: solid 2px #dcdcdc;
        transition: box-shadow 0.3s, border 0.3s;
    }

    .domotics-device-config-general-settings > div:nth-child(2) > input:focus, .domotics-device-config-general-settings > div:nth-child(2) > input.focus {
        border: solid 1px #707070;
        box-shadow: 0 0 5px 1px #969696;
    }

    .domotics-device-config-general-settings > div:nth-child(2) > span {
        margin-top:2rem;
        display: block;
    }

    .domotics-device-config-button-container {
        margin-top:2rem;
    }

    .domotics-device-config-button-container > input {
        width:12rem;
    }

    .domotics-device-config-button-container > input:nth-child(2) {
        margin-right:1rem;
    }

    /**** RIGHT SIDE ****/
    .domotics-device-config-usage-container {
        width:50%;
        position:relative;
        float:right;
        margin-top:8rem !important;
        margin-right:0%;
    }

    .domotics-device-config-usage-container > div:first-child > span {
        font-size:large;
        padding-left:3rem;
    }

    .domotics-device-config-usage-container > div:first-child > i {
        /*padding-right:30%;*/
    }

    .domotics-device-config-usage-graph-container {
        width:100%;
        height:80%;
        padding-left:1rem;
        margin-top:1rem;
    }

    .config-usage-graph {
        width:100%;
        height:30rem;
        overflow:hidden;
        border:1px solid black;
    }

    .config-usage-graph-metering-info {
        padding-left:2rem;
        text-align: center;
    }

    .graph-info-title {
        color:#7cb5ec;
        font-size:x-large;
        display:block;
    }

    .domotics-device-config-turnoff-device {
        width:100%;
        padding-left:1rem;
    }

    .domotics-device-config-turnoff-device > i {
        -moz-transition: none;
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }

    .domotics-device-config-turnoff-device > input[type="checkbox"] {
        vertical-align: text-bottom;
    }

    .domotics-device-config-turnoff-device > input[type="number"] {
        width:7rem;
    }
    /************************/
    /**** NOTIFICATIONS ***/
    div[id="toast-container"] {
        margin-top:14rem;
    }


    .device-progressbar-current {
        width:90%;
        position: absolute;
        height:1.2rem;
        bottom:0;
        margin-bottom:0.1rem;
        right:0;
        left:0;
        margin-left:auto;
        margin-right:auto;
        border-radius:0px;
    }

    .device-progressbar-current > .progress-bar {
        background-image: none;
        background-color: #AEAEAE;
        border-radius:0px;
    }

    .device-progressbar-requested {
        width:90%;
        position: absolute;
        height:1.2rem;
        top:0;
        margin-top:3%;
        right:0;
        left:0;
        margin-left:auto;
        margin-right:auto;
        border-radius:0px;
        background-color: transparent;
        background-image: none;
        border: 1px solid white;
    }

    .device-progressbar-requested > .progress-bar {
        background-image: none;
        border-radius:0px;
        box-shadow: none;
    }

    .device-control-bar-requested-container {
        position: absolute;
        width: 90%;
        height: 1rem;
        margin-left: auto;
        margin-right: auto;
        top: 0;
        left:0;
        right:0;
    }

    .device-control-bar-request {
        width: 0.6rem;
        height: 2.5rem;
        position: relative;
        background: white;
        top: 0;
        margin-top: 0.5%;
        border: 1px solid #777;
        z-index: 2;
    }

    .card-back-usage-icon {
        margin-right:1.5rem;
        margin-top:1.0rem;
        cursor:pointer;
        font-size:2rem;
        color:gray;
    }

    .card-back-usage-info {
        font-size:3rem;
        line-height: 8rem;
    }

    /***************CONFIGURATION WIZARD**************/

    /******STEP1********/

    .conf-wizard-step1 > h1 {
        padding-top:30rem;
    }

    .conf-wizard-step1 > span {
        display:block;
        font-size: x-large;
        color:white
    }

    .conf-wizard-step1 > input {
        color:black;
        background-color: white;
        font-size: large
    }


    /*****************************/

    /*************** STEP 2 *********/

    .domotics-device-config-wizard-langselect {
        width:50%;
        background-color: white;
        margin-left: auto;
        margin-right: auto;
        padding-top:5rem;
        margin-top:5rem;
        border:solid 2px black;
        font-size:2rem;
    }

    .domotics-device-config-wizard-management,
    .domotics-device-config-wizard-network,
    .wizard-config-button-container{
        width: 75% !important;
    }

    .domotics-device-config-wizard-button-container {
        margin-top:2rem;
    }

    .domotics-lang-element {
        width:90%;
        height:5rem;
        margin-bottom:2rem;
        margin-left:auto;
        margin-right:auto;
        line-height:5rem;
        cursor:default
    }

    .domotics-lang-not-selected-element {
        background-color: lightgray;
    }

    .domotics-selected-lang-element {
        background-color: #002f6d;
        color:white;
    }

    .wizard-config-button-container {
        width:50%;
        margin-left:auto;
        margin-right:auto;
    }

    /********************************/

    /******** STEP3 *********/
    .domotics-config-wizard-create-user {
        width:50%;
        min-height:40rem;
        background-color: white;
        margin-left: auto;
        margin-right: auto;
        padding-top:5rem;
        margin-top:5rem;
        border:solid 2px black;
        font-size:2rem;
    }

    /***********************/


    /*** DEVICES HEADER **/

    .devices-header-content-container {
        text-align: center;
        padding-top:1rem;
    }

    .devices-header-content-container{
        padding-top: 14px;
        padding-bottom: 21px;
        text-align: left;
        padding-left: 21px;
        min-height: 56px !important;
        height: 56px;
    }

    .devices-header-content-container > .breadcrumb-device-navigation > i{
        font-size: 14px;
        line-height: 21px;
    }

    .devices-header-content-container > .breadcrumb-device-navigation > span,
    .devices-header-content-container > .breadcrumb-device-navigation > a > span{
        font-size: 14px;
        line-height: 21px;
        font-family: 'KelsonSans';
    }

    .devices-header-content-title {
        font-size:xx-large;
        color:white;
    }

    .devices-header-content-title-device {
        font-size:xx-large;
        color:white;
    }

    .devices-header-content-filtering-container {
        position:relative;
        bottom:0;
    }

    .devices-header-content-filter{
        left:0;
        right:0;
        margin-left:auto;
        margin-right:auto;
        position:relative;
        bottom:-15px;
        width:45%;
        display:table;
    }

    .devices-header-content-filter > div {
        display: inline-block;
        background-color:transparent;
        color:lightgray;
        font-size:larger;
        margin-right:7px;
        margin-left:7px;
    }
    /***********************/


    /****** STEP 5 CONFIG ******/
    .wizard-step5-btn-container {
        position: relative;
        width:35rem;
        margin-left:61%;
    }

    .wizard-step5-editarea-title {
        display:block;
        margin-top:1rem;
        font-size:large;
        font-weight: bold;
    }

    .wizard-step5-editarea-title-input {
        margin-top:1rem;
        border: solid 1px #707070;
        box-shadow: 0 0 5px 1px #969696;
        font-size:1.5rem;
        padding: 5px 0px 0px;
    }

    .wizard-step5-divisions-title {
        display:block;
        margin-top:3rem;
        font-size:large;
        font-weight: bold;
    }

    .wizard-step5-modal-input{
        margin-top:1rem;
        border: solid 1px #707070;
        box-shadow: 0 0 5px 1px #969696;
        font-size:1.5rem;
    }
    /***************************/

    /**** USER HEADER DETAILS ***/

    .user-details-header {
        position: absolute;
        right:0;
        top:0;
        margin-top: 1.5rem;
        width:20rem;
    }

    .user-header {
        width:70%;
        margin-right:-3rem;
        position:relative;
        color:white;
    }

    .user-header-sidebar {
        display:none;
    }

    .user-header,.user-header-sidebar * {
        cursor:pointer;
    }

    .user-header-name-sidebar {
        display:inline-block;
        position:relative;
        padding-right:0.5rem;
        margin-left: 2px;
    }

    .user-header-caret-sidebar {
        display:inline-block;
        position:relative;
        top:-1rem;
        padding-left:0.5rem;
    }

    .user-header-name {
        display:inline-block;
        position:relative;
        top:-1rem;
        padding-right:0.5rem;
    }

    .user-header-caret {
        display:inline-block;
        position:relative;
        top:-1rem;
        padding-left:0.5rem;
    }

    .notifications-header {
        width:30%;
        position:relative;
        color:white;
    }

    .notifications-header * {
        cursor:pointer;
    }

    .header-notifications-val {
        position:absolute;
        top:-0.2rem;
        right:2.5rem;
        padding:1px 4px;
        background: red; color: white;
        border-radius:3px;
        font-size:1.1rem;
    }

    .dom-not-ic {
        font-size:2.5rem;
        margin-left:0.5rem;
    }

    .circletag {
        width: 30px;
        height: 30px;
        background: #E6E7ED;
        -moz-border-radius: 20px;
        -webkit-border-radius: 20px;
        background-color: white;
        background-position:50% 50%;
        background-repeat:no-repeat;
        color:#3CA2E0;
        font-size:2.5rem;
    }

    .circletag > i {
        position:absolute;
        top:0;
        margin-left:0.5rem;
        margin-top:0.2rem;
    }

    /****************************/


    /*** graph arrows ***/

    .domotics-timetravel-forward {
        font-size: 4rem;
        position: relative;
        margin-top: 20rem;
        cursor:pointer;
    }

    .domotics-timetravel-back {
        font-size: 4rem;
        position: relative;
        margin-top: 20rem;
        cursor:pointer;
        padding: 0 !important;
        padding-left: 2.5rem !important;
    }

    /********************/

    .dash-header-today-spacing {
        margin-left:12.5% !important;
        margin-right:12.5% !important;
    }
    .visible-devices {
        display: none;
    }
}

@media (min-width : 1440px) and (max-width: 1559px){

    .step-4-form-item {
        max-width: 45%!important;
        min-width: 45%!important;
    }

    .dash-header-today-spacing {
        margin-left:12.5% !important;
        margin-right:12.5% !important;
    }

    .mobile-only{
        display: none;
    }

    .device-upper {
        background-color: white;
        max-width:100%;
        height: 12.2rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius:  0.2143rem  0.2143rem  0px  0px;
        border-radius:  0.2143rem 0.2143rem 0px 0px;
    }

    .device-upper-whole {
        cursor:pointer;
        background-color: white;
        max-width:100%;
        height: 182px;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius:  0.2143rem  0.2143rem  0px  0px;
        border-radius:  0.2143rem 0.2143rem 0px 0px;
    }

    .device-upper-active {
        background-color: #65b2e9;
        max-width:100%;
        height: 12.2rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0.2143rem 0.2143rem 0px 0px;
        border-radius: 0.2143rem 0.2143rem 0px 0px;
    }
/*    .device-down > div:nth-child(2) {
        padding-top:10px;
    }*/

    div.domotics-device-list-icon:before {
        position: absolute;
        font-size: 4.8em;
        padding-left: 36%;
        padding-top: 14%;
        padding-bottom: 28px;
    }

    .device-toggle-percentage {
        padding-right: 1.071rem;
        padding-top: 2.143rem;
        font-size: 1.629rem;
    }

    .device-card-back-metering-span > span {
        font-size: 32px;
        display: block;
        padding-top: 48px;
        padding-bottom: 48px;
    }

    .device-card-back-settings-cog{
        font-size: 16px;
        margin-top: 21px;
        color: #aeaeae;
    }

    .domotics-card-back-refresh-area > i {
        margin-right:52px !important;
        margin-top: 21px; 
    }
    .visible-devices {
        display: none;
    }
}

@media all and (min-width : 1600px) {

    .step-4-form-item {
        max-width: 45%!important;
        min-width: 45%!important;
    }

    .rates-btn {
        margin-left: 3rem !important;
    }


    .card-back-usage-info-container {
            text-align: center;
            width: 100%;
            padding-top: 25%;
    }

    .card-container.scenario i.avatar-user{
        font-size: 4.6em;
        color:#aeaeae;
        position: relative;
        top: 0.6em;
    }

    .mobile-only{
        display: none;
    }

    .trash-can {
        margin-top: 15px !important;;
    }

    .sidebar-left {
        width:214px !important;
    }

    .has-sidebar-left .app, .sidebar-left-in .app {
        margin-left:214px;
    }

    div.domotics-general-list-icon-card-back{
        position:relative;
        overflow:hidden;
    }

    div.domotics-general-list-icon-card-back:before {
        font-size: 6.143em;
        padding-left: 35%;
        padding-top: 10%;
        position: absolute;
    }

    div.domotics-general-list-icon{
        position:relative;
        overflow:hidden;
    }

    div.domotics-general-list-icon:before {
        font-size: 4.143em;
        padding-left: 38%;
        padding-top: 25%;
        position: absolute;
    }

    div.domotics-device-list-icon:before {
        position: absolute;
        font-size: 64px;
        padding-left: 88px;
        padding-right: 88px;
        padding-top: 28px;
        padding-bottom: 28px;
    }


    div.domotics-device-list-icon, div.domotics-device-list-icon-multiple {
        position:relative;
        overflow:hidden;
    }

    div.domotics-device-list-icon-multiple:before {
        font-size: 4.143em;
        padding-left: 19%;
        padding-top: 45%;
        position: absolute;
    }

    div.domotics-device-icon-off {
        color: #aeaeae;
    }

    div.domotics-device-icon-on {
        color: white;
    }

    div.domotics-device-icon-toggle {
        color: #65b2e9;
    }

    .device-card-name {
        font-weight: bold;
        font-size: larger;
        padding-left: 1.4rem;
        font-family: sans-serif;
        display: block;
        padding-right: 1.4rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden !important;
    }

    .device-card-division {
        font-size: small;
        padding-left: 1.429rem;
        padding-right: 1.429rem;
        font-family: sans-serif;
        color: #aeaeae;
        display:block;
    }

    .device-card-settings-cog{
        margin-right: 2.2143rem;
        font-size: 16px;
        color: #aeaeae;
        margin-top: 15px;
        margin-bottom: 21px;
    }

    .card-power-icon-off {
        padding-top: 14px;
        padding-right: 14px;
        font-size: 16px;
        color: #aeaeae;
    }

    .card-power-icon-on {
        color: white;
        padding-top: 14px;
        padding-right: 14px;
        font-size: 16px;
    }

    .card-power-icon-toggle {
        padding-top: 14px;
        padding-right: 14px;
        font-size: 16px;
        color: #65b2e9;
    }

    .cards-container {
        text-align: center;
        margin-bottom:6rem;
    }

    .domotics-device, device-button, device-button-scenario{
/*        width: 24.46rem !important;
        height: 18.86rem !important;*/
        position: relative;
        float:left;
        border-radius: 0.2143rem;
    }

    .domotics-device {
        text-align: left;
        margin:0.5rem;
        border-radius: 0.2143rem;
    }

    .card-container{
/*        max-width:100% !important;
        max-height: 100% !important;*/
        border:none !important;
        outline: none !important;
        border-radius: 0.2143rem;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -webkit-touch-callout : none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
    }

    .device-upper {
        background-color: white;
        max-width:100%;
        height: 12.2rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius:  0.2143rem  0.2143rem  0px  0px;
        border-radius:  0.2143rem 0.2143rem 0px 0px;
    }

    .device-upper-whole {
        cursor:pointer;
        background-color: white;
        max-width:100%;
        height: 182px;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius:  0.2143rem  0.2143rem  0px  0px;
        border-radius:  0.2143rem 0.2143rem 0px 0px;
    }

    .device-upper-plus {
        min-width:50%;
        height: 70%;
        margin: 0px;
        position: relative;
    }

    .device-upper-minus {
        min-width:50%;
        height: 70%;
        margin: 0px;
        position: relative;
    }

    .device-upper-active {
        background-color: #65b2e9;
        max-width:100%;
        height: 12.2rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0.2143rem 0.2143rem 0px 0px;
        border-radius: 0.2143rem 0.2143rem 0px 0px;
    }

    .device-down {
        background-color: white;
        max-width:100%;
        max-height: 40%;
        height: 6rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
        padding-top:10px;
    }

    .device-down-simple {
        background-color: white;
        max-width:100%;
        max-height: 40%;
        height: 5.8rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
        text-align: center;
    }

    .device-down-upper-active {
        background-color: #65b2e9;
        color: white !important;
        cursor:pointer;
        max-width:100%;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
    }

    .device-down-upper-active *{
        color: white !important;
    }

    .device-down-upper-active i{
        color: white !important;
    }


    .device-upper:hover, .device-upper-active:hover{
        cursor:pointer;
    }

    .device-down:hover{
        cursor:pointer;
    }

    .device-down:hover span:not(.device-card-division){
        color: #65b2e9;
    }

    .device-down:hover i{
        color: #65b2e9;
    }

    .device-toggle-percentage {
        padding-right: 1.071rem;
        padding-top: 2.143rem;
        font-size: 1.629rem;
    }

    .device-percentage-status-white {
        background-color:white;
    }

    .device-percentage-status-gray {
        background-color:gray;
    }

    .device-percentage-status-gray-end {
        background-color:transparent;
        border-top:1px solid white;
        border-bottom:1px solid white;
        border-right:1px solid white;
    }

    .device-percentage-status-gray-start {
        background-color:transparent;
        border-top:1px solid white;
        border-bottom:1px solid white;
        border-left:1px solid white;
    }

    .device-card-back-metering-span {
        text-align: center;
    }

    .device-card-back-metering-span > span {
        font-size: 32px;
        display: block;
        padding-top: 48px;
        padding-bottom: 48px;
    }

    .device-control-bar {
        width:100%;
        height:1.5rem;
        margin-top:20%;
    }

    .device-percentage-status {
        margin-left: 1.3rem;
        margin-top: 0.8rem;
        font-size: 1.5rem;
    }

    .dash-header-today-cost {
        font-size: x-large;
        display:inline-block;
        padding-top:6rem;
        padding-left:6rem;
        padding-right:6rem;
        color: white;
    }

    .dash-header-monthly-cost {
        font-size: x-large;
        display:inline-block;
        padding-top:6rem;
        padding-left:6rem;
        padding-right:6rem;
        color: white;
    }

    .dash-header-devices-counter {
        font-size: x-large;
        display:inline-block;
        padding-top:6rem;
        padding-left:6rem;
        padding-right:6rem;
        color: white;
    }

    .dash-header-content-container {
        min-height:15rem;
        padding-top:0px;
    }

    .dash-header-content-greeting {
        color:white;
        float:left;
        padding-top:9rem;
        padding-left:4rem;
        font-size:xx-large;
    }

    .dash-header-content-monthly-usage-icon {
        padding-left:0px;
        opacity: 0.7;
    }

    .dash-header-content-monthly-text {
        font-size: x-small;
        margin-top: -1.5rem;
        padding-left: 0px;
    }

    .dash-header-content-daily-usage-icon {
        padding-left:0px;
        opacity: 0.7;
    }

    .dash-header-content-daily-usage-text {
        font-size: x-small;
        margin-top: -1.5rem;
        padding-left: 0px;
    }

    /***** card back ****/
    .card-back-device-down {
        background-color: white;
        max-width:100%;
        height: 5.8rem;
        margin: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px 0px 0.2143rem 0.2143rem;
        border-radius: 0px 0px 0.2143rem 0.2143rem;
        overflow:hidden;
    }

    .domotics-card-back-cog-area {
        min-width:50%;
        min-height:5.8rem;
        cursor: pointer;
    }

    .domotics-card-back-refresh-area {
        min-width:50%;
        min-height:5.8rem;
        cursor:pointer;
    }

    .domotics-card-back-refresh-area > i {
        margin-right:52px !important;
        margin-top: 21px;
    }

    .domotics-card-back-cog-area:hover *{
        color: #65b2e9;
    }

    .domotics-card-back-refresh-area:hover *{
        color:#65b2e9;
    }

    .device-card-back-settings-cog{
        font-size: 16px;
        margin-top: 21px;
        margin-left: 52px;
        color: #aeaeae;
    }

    .top-div-device-clickable {
        min-height:5rem;
        position:relative;
    }

    .top-div-device-clickable-element {
        min-width:20%;
        min-height: 5rem;
        z-index: 2;
        position: relative;
    }

    /******************header and body styling per view******************/
    /**** home *****/
    .domotics-body-home{
        padding-top: 75px !important;
    }

    .domotics-header-home {
        min-height: 15rem !important;
    }
    /**** devices ***/

    .domotics-body-devices{
        padding-top: 64px !important;
    }

    .domotics-header-devices {
        min-height: 56px !important;
        max-height: 56px !important;
    }

    /*************************************/
    /**** LOGIN *****/
    .domotics-login {
        height:100%;
        width:100%;
        text-align: center;
        background-image: url('../../../images/src/images/back.jpg');
        background-size:cover;
        font-family: "PT Sans", "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .domotics-login-form {
        min-height: 35%;
        max-width: 30%;
        // background-color: white;
        padding:2rem;
        border-radius: 0.2rem;
        margin-left:auto;
        margin-right:auto;
    }

    .domotics-login-header {
        color: #001ec8;
        font-size:7rem;
        font-style: italic;
        font-weight: bold;
        margin-left:8rem;
        margin-top:10rem;
    }

    .domotics-login-header2 {
        color:white;
        font-size:5rem;
        /*text-shadow: 1px 1px #00193a;*/
        margin-top:15rem;
    }

    .domotics-login-form-forgot-pw {
        color:white;
        text-decoration: underline;
    }

    .domotics-login-form-forgot-pw:hover {
        color:white;
    }

    .domotics-login-form > input {
        display:block;
        margin : 0 auto;
        margin-top:7%;
        min-height:5rem;
        width: 90%;
        font-size:x-large;
        border-radius: 0.2rem;
        padding: 0 14px 0 14px;
    }

    .domotics-login-btn {
        border : solid 0px #ffffff;
        -webkit-box-shadow : 0px 1px 1px rgb(75, 107, 148);
        -moz-box-shadow : 0px 1px 1px rgb(75, 107, 148);
        box-shadow : 0px 1px 1px rgb(75, 107, 148);
        color : #ffffff;
        margin-top:10% !important;
        background-color : #4b9dff;
        font-size:small !important;
        opacity: 0.8;
    }

    .domotics-login-btn:hover {
        background-color : #001ec8;
        border-radius: 5px;

    }

    .domotics-body-login {
        padding-top: 0px !important;
    }
    /*************************/


    /**** DEVICE CONFIG *****/

    /**** LEFT SIDE ****/
    .domotics-device-config-top-container {
        min-width:80%;
        background-color: white;
    }

    .domotics-device-config-general-settings {
        position:relative;
        float:left;
        // width:50%;
        padding-left:5rem;
        text-align: center;
    }

    .domotics-device-config-general-settings > div:first-child {
        // margin-top:8rem;
        max-width:50%;
    }

    .domotics-device-config-general-settings > div:first-child > span {
        font-size: large !important;
        display:block;
        word-wrap: break-word;
    }

    .domotics-device-config-general-settings-span {
        margin-top:1rem;
        font-size: large;
    }

    .domotics-device-config-icon {
        width:15rem;
        height:15rem;
        border-style: dashed;
        margin-top:2rem;
        cursor: pointer;
    }

    .domotics-device-config-icon:before {
        font-size:12rem;
        position:relative;
        line-height: 14rem;
    }

    .domotics-device-config-general-settings > div:nth-child(2) {
        padding-right: 10rem;
        padding-top: 10rem;
        font-size:larger;
        display:inline-block;
        text-align: left;
    }

    .domotics-device-config-general-settings > div:nth-child(2) > input, .domotics-device-config-general-settings > div:nth-child(2) > select {
        display:block;
        width:20rem;
        padding:0.2rem;
        border: solid 2px #dcdcdc;
        transition: box-shadow 0.3s, border 0.3s;
    }

    .domotics-device-config-general-settings > div:nth-child(2) > input:focus, .domotics-device-config-general-settings > div:nth-child(2) > input.focus {
        border: solid 1px #707070;
        box-shadow: 0 0 5px 1px #969696;
    }

    .domotics-device-config-general-settings > div:nth-child(2) > span {
        margin-top:2rem;
        display: block;
    }

    .domotics-device-config-button-container {
        margin-top:2rem;
    }

    .domotics-device-config-button-container > input {
        width:12rem;
    }

    .domotics-device-config-button-container > input:nth-child(4) {
        margin-right:1rem;
    }

    /**** RIGHT SIDE ****/
    .domotics-device-config-usage-container {
        width:50%;
        position:relative;
        float:right;
        margin-top:8rem !important;
        margin-right:5%;
    }

    .domotics-device-config-usage-container > div:first-child > span {
        font-size:large;
        padding-left:1rem;
    }

    .domotics-device-config-usage-container > div:first-child > i {
        /*padding-right:30%;*/
    }

    .domotics-device-config-usage-graph-container {
        width:100%;
        height:80%;
        padding-left:1rem;
        margin-top:1rem;
    }

    .config-usage-graph {
        width:100%;
        height:30rem;
        overflow:hidden;
        border:1px solid black
    }

    .config-usage-graph-metering-info {
        padding-left:2rem;
        text-align: center;
    }

    .graph-info-title {
        color:#7cb5ec;
        font-size:x-large;
        display:block;
    }

    .domotics-device-config-turnoff-device {
        width:100%;
        padding-left:1rem;
    }

    .domotics-device-config-turnoff-device > i {
        -moz-transition: none;
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }

    .domotics-device-config-turnoff-device > input[type="checkbox"] {
        vertical-align: text-bottom;
    }

    .domotics-device-config-turnoff-device > input[type="number"] {
        width:7rem;
    }
    /************************/
    /**** NOTIFICATIONS ***/
    div[id="toast-container"] {
        margin-top:11%;
    }

    /***************CONFIGURATION WIZARD**************/

    /******STEP1********/

    .conf-wizard-step1 > h1 {
        padding-top:40rem;
    }

    .conf-wizard-step1 > span {
        display:block;
        font-size: x-large;
        color:white
    }

    .conf-wizard-step1 > input {
        color:black;
        background-color: white;
        font-size: large
    }


    /*****************************/


    /*************** STEP 2 *********/

    .domotics-device-config-wizard-langselect {
        width:50%;
        background-color: white;
        margin-left: auto;
        margin-right: auto;
        padding-top:5rem;
        margin-top:5rem;
        border:solid 2px black;
        font-size:3rem;
    }

    .domotics-device-config-wizard-management,
    .domotics-device-config-wizard-network,
    .wizard-config-button-container{
        width: 50% !important;
    }

    .domotics-device-config-wizard-button-container {
        margin-top:2rem;
    }

    .domotics-lang-element {
        width:90%;
        height:8rem;
        margin-bottom:2rem;
        margin-left:auto;
        margin-right:auto;
        line-height:8rem;
        cursor:default
    }

    .domotics-lang-not-selected-element {
        background-color: lightgray;
    }

    .domotics-selected-lang-element {
        background-color: #002f6d;
        color:white;
    }

    /********************************/

    /******** STEP3 *********/
    .domotics-config-wizard-create-user {
        width:50%;
        min-height:50rem;
        background-color: white;
        margin-left: auto;
        margin-right: auto;
        padding-top:5rem;
        margin-top:5rem;
        border:solid 2px black;
        font-size:3rem;
    }

    /***********************/

    /******** STEP4 *********/
    .stage-wrapper{
        margin-top: 5%;
    }
    /***********************/


    .device-progressbar-current {
        width:90%;
        position: absolute;
        height:1.4rem;
        bottom:0;
        margin-bottom:0.1rem;
        right:0;
        left:0;
        margin-left:auto;
        margin-right:auto;
        border-radius:0px;
    }

    .device-progressbar-current > .progress-bar {
        background-image: none;
        background-color: #AEAEAE;
        border-radius:0px;
    }

    .device-progressbar-requested {
        width:90%;
        position: absolute;
        height:1.2rem;
        top:0;
        margin-top:3%;
        right:0;
        left:0;
        margin-left:auto;
        margin-right:auto;
        border-radius:0px;
        background-color: transparent;
        background-image: none;
        border: 1px solid white;
    }

    .device-control-bar-requested-container {
        position: absolute;
        width: 90%;
        height: 1rem;
        margin-left: auto;
        margin-right: auto;
        top: 0;
        left:0;
        right:0;
    }

    .device-control-bar-request {
        width: 0.6rem;
        height: 2.5rem;
        position: relative;
        background: white;
        top: 0;
        margin-top: 0.5%;
        border: 1px solid #777;
        z-index: 2;
    }

    .device-progressbar-requested > .progress-bar {
        background-image: none;
        border-radius:0px;
        box-shadow: none;
    }

    .card-back-usage-icon {
        cursor:pointer;
        margin-right: 14px;
        margin-top: 14px;
        cursor: pointer;
        font-size: 16px;
        color: gray;
    }

    .card-back-usage-info {
        font-size:3.5rem;
    }

    /*** DEVICES HEADER **/

    .devices-header-content-container {
        text-align: center;
        padding-top:1rem;
    }

    .devices-header-content-title {
        font-size:xx-large;
        color:white;
    }
    .devices-header-content-title-device {
        font-size:xx-large;
        color:white;
    }

    .devices-header-content-container{
        padding-top: 14px;
        padding-bottom: 21px;
        text-align: left;
        padding-left: 21px;
    }

    .devices-header-content-container > .breadcrumb-device-navigation > i{
        font-size: 14px;
        line-height: 21px;
    }

    .devices-header-content-container > .breadcrumb-device-navigation > span,
    .devices-header-content-container > .breadcrumb-device-navigation > a > span{
        font-size: 14px;
        line-height: 21px;
        font-family: 'KelsonSans';
    }

    .devices-header-content-filtering-container {
        position:relative;
        bottom:0;
    }

    .devices-header-content-filter{
        left:0;
        right:0;
        margin-left:auto;
        margin-right:auto;
        position:relative;
        bottom:-15px;
        width:45%;
        display:table;
    }

    .devices-header-content-filter > div {
        display: inline-block;
        background-color:transparent;
        color:lightgray;
        font-size:larger;
        margin-right:7px;
        margin-left:7px;
    }
    /***********************/

    /****** STEP 5 CONFIG ******/
    .wizard-step5-btn-container {
        position: relative;
        width:35rem;
        margin-left:75%;
    }

    .wizard-step5-editarea-title {
        display:block;
        margin-top:1rem;
        font-size:large;
        font-weight: bold;
    }

    .wizard-step5-editarea-title-input {
        margin-top:1rem;
        border: solid 1px #707070;
        box-shadow: 0 0 5px 1px #969696;
        font-size:1.5rem;
        padding: 5px 0px 0px;
    }

    .wizard-step5-divisions-title {
        display:block;
        margin-top:3rem;
        font-size:large;
        font-weight: bold;
    }

    .wizard-step5-modal-input{
        margin-top:1rem;
        border: solid 1px #707070;
        box-shadow: 0 0 5px 1px #969696;
        font-size:1.5rem;
    }
    /***************************/

    /**** USER HEADER DETAILS ***/

    .user-details-header {
        position: absolute;
        right:0;
        top:0;
        margin-top: 1.5rem;
        width:20rem;
    }

    .user-header {
        width:70%;
        margin-right:-3rem;
        position:relative;
        color:white;
    }

    .user-header-sidebar {
        display:none;
    }

    .user-header,.user-header-sidebar * {
        cursor:pointer;
    }

    .user-header-name-sidebar {
        display:inline-block;
        position:relative;
        padding-right:0.5rem;
        margin-left: 2px;
    }

    .user-header-caret-sidebar {
        display:inline-block;
        position:relative;
        top:-1rem;
        padding-left:0.5rem;
    }

    .user-header-name {
        display:inline-block;
        position:relative;
        top:-1rem;
        padding-right:0.5rem;
    }

    .user-header-caret {
        display:inline-block;
        position:relative;
        top:-1rem;
        padding-left:0.5rem;
    }

    .notifications-header {
        width:30%;
        position:relative;
        color:white;
    }

    .notifications-header * {
        cursor:pointer;
    }

    .header-notifications-val {
        position:absolute;
        top:-0.2rem;
        right:2.5rem;
        padding:1px 4px;
        background: red; color: white;
        border-radius:3px;
        font-size:1.1rem;
    }

    .dom-not-ic {
        font-size:2.5rem;
        margin-left:0.5rem;
    }

    .circletag {
        width: 30px;
        height: 30px;
        background: white;
        background-color: white;
        background-repeat:no-repeat;
        color:#3CA2E0;
        font-size:2.5rem;
        margin-bottom:1rem;
    }

    .circletag > i {
        position:absolute;
        top:0;
        margin-left:0.5rem;
        margin-top:0.2rem;
    }

    /****************************/

    /*** graph arrows ***/

    .domotics-timetravel-forward {
        font-size: 4rem;
        position: relative;
        margin-top: 20rem;
        cursor:pointer;
    }

    .domotics-timetravel-back {
        font-size: 4rem;
        position: relative;
        margin-top: 20rem;
        cursor:pointer;
        padding: 0 !important;
        padding-left: 2.5rem !important;
    }

    /********************/

    .dash-header-today-spacing {
        margin-left:12.5% !important;
        margin-right:12.5% !important;
    }
    .visible-devices {
        display: none;
    }
}

.spec-mob {
    /*margin-top:0.5rem;*/
}

.mob-graphSlice {
    display:none;
}

.domotics-timetravel-back:hover , .domotics-timetravel-forward:hover {
    color:#6FB0FF;
}

.domotics-block-timetravel {
    pointer-events: none !important;
    color: lightgray !important;
    opacity: 0.3 !important;
}

.animate-show {
    -webkit-transition:all linear 0.5s !important;
    transition:all linear 0.5s !important;
}

.animate-show.ng-hide {
    opacity: 0 !important;
    transition: none !important;
}

.animate-show.ng-show {
    opacity: 1 !important;
    transition: none !important;
}

.animate-show.ng-hide {
    opacity: 0 ;
}

.animate-if.ng-enter, .animate-if.ng-leave {
    -webkit-transition:all linear 0.5s !important;
    transition:all linear 0.5s !important;
}

.animate-if.ng-enter,
.animate-if.ng-leave.ng-leave-active {
    opacity:0;
}

.animate-if.ng-leave,
.animate-if.ng-enter.ng-enter-active {
    opacity:1;
    transition: none !important;
}

.animate-show2 {
    -webkit-transition:all linear 2s !important;
    transition:all linear 2s !important;
}

.animate-show2.ng-hide {
    opacity: 0 !important;
    transition: none !important;
}

.animate-show2.ng-show {
    opacity: 1 !important;
}

.animate-show2.ng-hide {
    opacity: 0 ;
}


.animate-show-quick {
    -webkit-transition:all linear 0s !important;
    transition:all linear 0s !important;
}

.animate-show-quick.ng-hide {
    opacity: 0 !important;
    transition: 0s !important;
}

.animate-show-quick.ng-show {
    opacity: 1 !important;
}

.animate-show-quick.ng-hide {
    opacity: 0 ;
}

.animate-show-hyper {
    -webkit-transition:all linear 0s !important;
    transition:all linear 0s !important;
}

.animate-show-hyper.ng-hide {
    opacity: 0 !important;
    transition: 0s !important;
}

.animate-show-hyper.ng-show {
    opacity: 1 !important;
}

.animate-show-hyper.ng-hide {
    display: none;
}

.domotics-login-form{
    max-width: 450px;
    max-height 300px;
}

/*.device-down-simple > span {
    padding-left: 0px !important;
}
*/
.card-wrapper span{
    font-family: 'KelsonSans';
}

/***************************************** Login ***************************************/

@media all and (max-width: 767px){
    .domotics-login{
        background-image: url('../../../images/src/images/loginBackgrounds/img_sign_in_background_768.jpg') !important;
    }
}
@media all and (min-width: 768px) and (max-width: 992px){
    .domotics-login{
        background-image: url('../../../images/src/images/loginBackgrounds/img_sign_in_background_992.jpg') !important;
    }
}

@media all and (min-width : 993px) and (max-width: 1200px){
    .domotics-login{
        background-image: url('../../../images/src/images/loginBackgrounds/img_sign_in_background_1200.jpg') !important;
    }
}
@media all and (min-width : 1201px) and (max-width: 1600px){
    .domotics-login{
        background-image: url('../../../images/src/images/loginBackgrounds/img_sign_in_background_1600.jpg') !important;
    }
}

@media all and (min-width: 1601px){
    .domotics-login{
        background-image: url('../../../images/src/images/loginBackgrounds/img_sign_in_background_1920.jpg') !important;
    }
}

.login-outter{
    display: table;
    position: absolute;
    height: 100%;
    width: 100%;
}

.login-middle{
    display: table-cell;
    vertical-align: middle;
}

.login-container {
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.21),0px 4px 14px 0px rgba(0,0,0,0.07);
    width: 340px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 14px; 
}

.login-container .company-name{
    margin-bottom: 28px;
}
.login-container .welcome-msg{
    margin-bottom: 14px;
}


@media (min-width: 1200px){
    .login-container{
        margin-left: 127px;
        margin-right: 0;
    }
}

.login-container .company-name{
    color: #001ec8;
    line-height: 17px;
    font-size: 25px;
    font-style: italic;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    font-family: 'PT Sans', 'Source Sans Pro', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;

}

.login-container .welcome-msg{
    color: #15569B;
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'KelsonSansLight';
}

.login-container .login-msg{
    font-family: 'KelsonSans';
    height: 28px;
    width: 258px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 3px;
    padding: 4px 14px 4px 14px;
     box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-style: solid;
    border-width: 1px;
    text-align: left;
}

.login-container .login-msg.error{
    background-color: #FCF1F0;
    color: #D9534F;
    border-color: #D9534F;
}

.login-container .login-msg.warning{
    background-color: #F0AD4E;
    font-color: #F0AD4E;
}

.login-container .login-msg.sucess{
    background-color: #5CB85C;
    font-color: #F0AD4E;
}

form{
    margin-top: 14px;
}
.login-container input[type="password"],
.login-container input[type="text"]{
    font-family: 'KelsonSans';
    border-style: ridge;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 14px;
    height: 44px;
    width: 258px;
    color: #000A15;
    padding: 14px;
    border-radius: 3px;
    display: block;
}

.login-container input[type="password"]:focus,
.login-container input[type="text"]:focus{
    font-family: 'KelsonSansBold';
    border: 1px solid #000000;
}

.login-container input::-moz-placeholder,
.login-container input:-moz-placeholder,
.login-container input:-ms-input-placeholder,
.login-container input:placeholder-shown,
.login-container input::-webkit-input-placeholder {
    color:    #8A8E94;
}
.login-container input::-moz-placeholder,
.login-container input:-moz-placeholder {
    opacity:  1;
}

.login-container input[type="submit"]{
    border-radius: 3px;
    height: 44px;
    width: 258px;
    background-color:#3498db;
    margin-bottom: 28px;
    stroke:#0E874A;
    stroke-width:1px;
    stroke-opacity:0.87;
}

.login-container input[type="submit"]:active{
    background-color:#2f87c2;
}

.login-container input[type="submit"]:hover{
    background-color:#3baaf5;
}

/***************************************************************************************/

/*************************************** Device List ***********************************/

    .device-display-box.cards-container.device-list{
        width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        /*text-align: left;*/
    }

    .device-display-box.cards-container.device-list .domotics-device,
    .device-display-box.cards-container.device-list device-button, device-button-scenario{
        /*max-width: 240px !important;
        max-height: 222px !important;
        min-width: 13.46rem !important;
        min-height: 10.9rem !important;*/
        width: 100% !important;
        height: 100% !important;
        box-sizing: border-box;
        background-color: white;
    }
    .device-display-box.cards-container .card-wrapper{
        margin-right: 1rem;
    }

    @media (max-width: 480px){
        .device-display-box.cards-container.device-list.numItem1{
            text-align: center !important;
        }
        .device-display-box.cards-container.device-list{
            /* background-color: orange;
            width: 25rem;*/
        }
        .device-display-box.cards-container.device-list .card-wrapper{
            width:  148px !important;
            height: 148px !important;
        }

        .device-display-box.cards-container.device-list.configuration-item-wrapper {
            width: 120%!important;
            margin-left: -30px!important;
        }

        .multiple-houses {
            width: auto!important;
        }


        .device-display-box.cards-container.device-list.multiple-houses{
            width: 100% !important;
            margin: auto;
        }

        .domotics-homeserver-list-container {
            width:90vh!important;
        }

        .domotics-device-config-wizard-management.domotics-homeserver-list-container {
            max-width:1024px!important;
            height:70vh;
            display: grid;
        }
    }

    @media (min-width: 481px) and (max-width: 647px){
        .device-display-box.cards-container.device-list.numItem1,
        .device-display-box.cards-container.device-list.numItem2{
            text-align: center !important;
        }

        .device-display-box.cards-container.device-list{
            // background-color: purple;
            width: 47rem !important;
        }
        .device-display-box.cards-container.device-list.configuration-item-wrapper {
            width: 120%!important;
            margin-left: -30px!important;
        }
        .device-display-box.cards-container.device-list .card-wrapper{
            width:  148px !important;
            height: 148px !important;
        }

        .multiple-houses {
            width: auto!important;
        }

        .device-display-box.cards-container.device-list.multiple-houses{
            width: 100% !important;
            margin: auto;
        }

        .domotics-homeserver-list-container {
            width:90vh!important;
        }

        .domotics-device-config-wizard-management.domotics-homeserver-list-container {
            max-width:1024px!important;
            height:70vh;
            margin-top:65px;
        }
    }


    @media (min-width: 648px) and (max-width: 767px){
        .device-display-box.cards-container.device-list.numItem1,
        .device-display-box.cards-container.device-list.numItem2,
        .device-display-box.cards-container.device-list.numItem3{
            text-align: center !important;
        }
        .device-display-box.cards-container.device-list{
            // background-color: cyan;
            width: 60rem;
        }
        .device-display-box.cards-container.device-list .card-wrapper{
            width:  148px !important;
            height: 148px !important;
        }

        .multiple-houses {
            width: auto!important;
        }

        .device-display-box.cards-container.device-list.multiple-houses{
            width: 100% !important;
            margin: auto;
        }

        .domotics-homeserver-list-container {
            width:90vh!important;
        }

        .domotics-device-config-wizard-management.domotics-homeserver-list-container {
            max-width:1024px!important;
            height:70vh;
            margin-top:65px;
        }
    }

    @media (min-width: 768px) and (max-width: 991px){
        .device-display-box.cards-container.device-list.numItem1,
        .device-display-box.cards-container.device-list.numItem2,
        .device-display-box.cards-container.device-list.numItem3{
            text-align: center !important;
        }
        .device-display-box.cards-container.device-list{
            width: 73rem;
        }
        .device-display-box.cards-container.device-list .card-wrapper{
            width:  178px !important;
            height: 171px !important;
        }

        .multiple-houses {
            width: auto!important;
        }

        .device-display-box.cards-container.device-list.multiple-houses{
            width: 100% !important;
            margin: auto;
        }

        .domotics-homeserver-list-container {
            width:90%;
        }

        .domotics-device-config-wizard-management.domotics-homeserver-list-container {
            max-width:1024px!important;
            height:70vh;
            margin-top:65px;
        }
    }

    @media (min-width: 992px) and (max-width: 1199px){
        .device-display-box.cards-container.device-list.numItem1,
        .device-display-box.cards-container.device-list.numItem2{
            text-align: center !important;
        }
        .device-display-box.cards-container.device-list{
            // background-color: yellow;
            width: 73rem;
        }
        .device-display-box.cards-container.device-list .card-wrapper{
            width:  174px !important;
            height: 181px !important;
        }

        .multiple-houses {
            width: auto!important;
        }

        .device-display-box.cards-container.device-list.multiple-houses{
            width: 100% !important;
            margin: auto;
        }

        .domotics-homeserver-list-container {
            width:500px!important;
        }

        .domotics-device-config-wizard-management.domotics-homeserver-list-container {
            max-width:1024px!important;
            height:70vh;
            display: grid;
        }

    }

    @media (min-width: 1200px) and (max-width: 1439px){
        .device-display-box.cards-container.device-list.numItem1,
        .device-display-box.cards-container.device-list.numItem2,
        .device-display-box.cards-container.device-list.numItem3{
            text-align: center !important;
        }
        .device-display-box.cards-container.device-list{
            // background-color: aqua;
            width: 92rem;
        }
        .device-display-box.cards-container.device-list .card-wrapper{
            width:  222px !important;
            height: 182px !important;
        }

        .multiple-houses {
            width: auto!important;
        }

        .device-display-box.cards-container.device-list.multiple-houses{
            width: 100% !important;
            margin: auto;
        }

        .domotics-homeserver-list-container {
            width:600px!important;
        }

        .domotics-device-config-wizard-management.domotics-homeserver-list-container {
            max-width:1024px!important;
            height:70vh;
            display: grid;
        }
    }

    @media (min-width: 1440px) and (max-width: 1551px){
        .device-display-box.cards-container.device-list.numItem1,
        .device-display-box.cards-container.device-list.numItem2,
        .device-display-box.cards-container.device-list.numItem3{
            text-align: center !important;
        }
        .device-display-box.cards-container.device-list{
            // background-color: green;
            width:103rem;
        }

        .device-display-box.cards-container.device-list .card-wrapper{
            width:  240px !important;
            height: 182px !important;
        }

        .multiple-houses {
            width: auto!important;
        }

        .device-display-box.cards-container.device-list.multiple-houses{
            width: 100% !important;
            margin: auto;
        }

        .domotics-homeserver-list-container {
            width:700px!important;
        }

        .domotics-device-config-wizard-management.domotics-homeserver-list-container {
            max-width:1024px!important;
            height:70vh;
            display: grid;
        }

    }

    @media (min-width: 1552px) and (max-width: 1806px){
        .device-display-box.cards-container.device-list.numItem1,
        .device-display-box.cards-container.device-list.numItem2,
        .device-display-box.cards-container.device-list.numItem3,
        .device-display-box.cards-container.device-list.numItem4{
            text-align: center !important;
        }

        .device-display-box.cards-container.device-list{
            // background-color: purple;
            width: 110rem;
        }

        .device-display-box.cards-container.device-list .card-wrapper{
            width:  240px !important;
            height: 182px !important;
        }

        .multiple-houses {
            width: auto!important;
        }

        .device-display-box.cards-container.device-list.multiple-houses{
            width: 100% !important;
            margin: auto;
        }

        .domotics-homeserver-list-container {
            width:1024px!important;
        }

        .domotics-device-config-wizard-management.domotics-homeserver-list-container {
            max-width:1024px!important;
            height:70vh;
            display: grid;
        }
    }

    @media (min-width: 1807px) and (max-width: 1919px){

        .multiple-houses {
            width: auto!important;
        }

        .device-display-box.cards-container.device-list.numItem1,
        .device-display-box.cards-container.device-list.numItem2,
        .device-display-box.cards-container.device-list.numItem3,
        .device-display-box.cards-container.device-list.numItem4,
        .device-display-box.cards-container.device-list.numItem5{
            text-align: center !important;
        }

        .device-display-box.cards-container.device-list.multiple-houses{
            width: auto!important;
        }

        .device-display-box.cards-container.device-list{
            width: 149rem !important;
            // background-color: aqua;
        }
        .device-display-box.cards-container.device-list .card-wrapper{
            width:  240px !important;
            height: 182px !important;
        }


        .device-display-box.cards-container.device-list.multiple-houses{
            width: 100% !important;
            margin: auto;
        }

        .domotics-homeserver-list-container {
            width:1200px!important;
        }

        .domotics-device-config-wizard-management.domotics-homeserver-list-container {
            max-width:1200px!important;
            height:70vh;
            display: grid;
        }

    }

    @media (min-width: 1920px){
        .device-display-box.cards-container.device-list.multiple-houses{
            width: 100% !important;
            margin: auto;
        }

        .domotics-homeserver-list-container {
            width:1524px!important;
        }

        .domotics-device-config-wizard-management.domotics-homeserver-list-container {
            max-width:1524px!important;
            height:70vh;
            display: grid;
        }

    }

    @media (min-width: 1920px){

        .multiple-houses {
            width: auto!important;
        }

        .device-display-box.cards-container.device-list.numItem1,
        .device-display-box.cards-container.device-list.numItem2,
        .device-display-box.cards-container.device-list.numItem3,
        .device-display-box.cards-container.device-list.numItem4,
        .device-display-box.cards-container.device-list.numItem5{
            text-align: center !important;
        }

        .device-display-box.cards-container.device-list{
            width: 155rem !important;
            // background-color: aqua;
        }

        .device-display-box.cards-container.device-list .card-wrapper{
            width:  240px !important;
            height: 182px !important;
        }

        .device-display-box.cards-container.device-list.multiple-houses{
            width: 100% !important;
            margin: auto;
        }

        .domotics-homeserver-list-container {
            width:1024px!important;
        }

        .domotics-device-config-wizard-management.domotics-homeserver-list-container {
            max-width:1024px!important;
            height:70vh;
            display: grid;
        }

    }
/***************************************************************************************/

/************************************** Device Details *********************************/
    /**** NEW ***/
    .device-details-area{
        display: flex;
    }
    @media (min-width: 993px){
        .device-details-pane-wrapper{
            width: calc(50% - 2rem) !important;
            display: inline-block;
        }
    }
    @media (max-width: 992px){
        .device-details-area{
            display: block;
        }
        .device-details-pane-wrapper{
            width: 100% !important;
            display: block;
            margin-bottom: 25px;
        }
    }
    .device-details-pane-wrapper{
        // width: calc(50% - 2rem);
        display: inline-block;
        height: auto;
    }
    .device-details-pane-wrapper.general-settings{
        margin-right: 2em;
    }

    .device-details-pane-wrapper .device-details-pane {
        background-color: #FFFFFF;
        width: 100%;
        border-radius: 0 0 3px 3px;
        padding: 2rem;
        min-height: 475px;
    }
    .device-details-pane-wrapper .pane-title-container{
        border-bottom: solid 1px #F1F1F1;
        background-color: #FFFFFF;
        min-height: 42px;
        width: 100%;
        border-radius: 3px 3px 0 0 ;
        padding-left: 2rem;
        padding-top: 1rem
    }

    .device-details-pane-wrapper > .pane-title-container > span > span{
        font-family: "KelsonSansBold";
        font-size: 20px;
        font-weight: bold;
    }

    .device-details-pane.general-settings{
        margin-right: 3rem;
    }

    @media (max-width: 1400px){
        .device-details-pane-wrapper.key-bindings .device-details-pane{
            display: block;
        }
        .device-details-pane-wrapper.key-bindings .device-details-pane .half-section{
            width: 100% !important;
            display: block;
        }
        .device-details-pane-wrapper.key-bindings .device-details-pane .half-section .help-container{
            margin-bottom: 2rem
        }
        .device-details-pane-wrapper.key-bindings .device-details-pane .half-section .quad-wrapper{
            margin-bottom: 2rem
        }
    }

    .device-details-pane .half-section{
        display: inline-block;
        width: 50%;
    }

    .device-details-pane .half-section > .domotics-device-config-quad-container{
        padding-top: 0px;
    }

    .device-details-pane .device-scheduling .info > input[type=button]{
        font-family: "KelsonSans" !important;
        width: 124px !important;
        height: 34px !important;
        margin-top: 2px;
    }
    .device-details-pane .device-scheduling .info > input[type=button]:active,
    .device-details-pane .device-scheduling .info > input[type=button]:hover{
        font-family: "KelsonSansBold" !important;
    }

    .device-details-pane .icon-subpane{
        margin-top: 2rem;
        display: inline-block;
    }

    .device-details-pane .icon-title span,
    .device-details-pane .icon-title{
        display: block;
        font-family: "KelsonSansBold";
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .device-details-pane-wrapper.general-settings .domotics-device-config-icon:before{
        font-size: 34px !important;
        line-height: 34px !important;
        margin-left: 14px;
    }

    .device-details-pane-wrapper.general-settings .domotics-device-config-icon{
        width: 64px !important;
        height: 64px !important;
        padding-top: 14px;
        margin-bottom: 2rem;
        margin-top: 0px;
        margin-right: 2rem;
        display: inline-block;
        border: solid 1px #F1F1F1;
    }
    .device-details-pane-wrapper.general-settings .protected-switch{
        display: inline-block;
        width: calc(100% - 90px);
    }
    .device-details-pane-wrapper.general-settings .protected-switch span{
        font-family: "KelsonSans"
    }
    .device-details-pane-wrapper.general-settings .protected-switch .help-container{
        color: #95999E;
    }
    .device-details-pane-wrapper.key-bindings .help-container{
        color: #95999E;
        margin-bottom: 11rem;
        margin-left: auto;
        margin-right: auto;
        width: 19rem;
    }

    .device-details-pane-wrapper.key-bindings .help-container span{
        font-family: "KelsonSans"
    }

    @media (max-width: 400px){
        .device-details-pane-wrapper.general-settings .input-container > .input-wrapper{
            width: 100% !important;
            display: block !important;
        }
    }

    .device-details-pane-wrapper.general-settings .input-container > .input-wrapper{
        display: inline-block;
        width: calc(50% - 1rem);
        vertical-align: middle;
    }
    .device-details-pane-wrapper.general-settings .input-container > .input-wrapper:nth-child(odd){
        margin-right: 2rem;
    }
    .device-details-pane-wrapper.general-settings .input-container > .input-wrapper > input,
    .device-details-pane-wrapper.general-settings .input-container > .input-wrapper > select,
    .device-details-pane-wrapper.general-settings .input-container > .input-wrapper > span{
        display: block;
        width: 100%;
    }
    .device-details-pane-wrapper.general-settings .input-container > .input-wrapper > input,
    .device-details-pane-wrapper.general-settings .input-container > .input-wrapper > select{
        margin-bottom: 20px;
        height: 35px;
        font-family: "KelsonSansBold";
        font-weight: bold;
        padding: 5px;
    }
    /*.device-details-pane-wrapper.general-settings .input-container > .input-wrapper > input{
        -webkit-appearance: searchfield;
    }*/
    .device-details-pane-wrapper.general-settings .input-container > .input-wrapper span{
        font-family: "KelsonSansBold";
        font-weight: bold;
        margin-bottom: 2px;
    }

    .device-details-pane-wrapper.general-settings .device-scheduling span{
        font-family: "KelsonSansBold";
        font-weight: bold;
    }


    /**** \NEW ***/


    .domotics-device-config-usage-container{
        margin-top: 1.5em;
    }
    .domotics-device-config-general-settings .device-name {
        text-align: left;
    }

    .domotics-device-config-general-settings input,
    .domotics-device-config-general-settings select:not(button){
        /*width: 85px !important;*/
        /*max-width: 85%*/
    }

    .domotics-device-config-general-settings > div{
        padding-right: 0 !important;
    }

    .domotics-device-config-general-settings .protected-switch{
        width: 15rem;
        max-width: 90%;
        padding: 0;
        text-align: left;
    }

    .domotics-device-config-quad-container{
        padding-top: 10em;
        height: 100%;
        text-align: center;
    }

    .domotics-device-config-quad-container .key-conf-title{
        margin-bottom: 2em;
        margin-top: -4em;
    }

    .domotics-device-config-quad-container > .info{
        margin-top: 20px;
        height: 5em;
        // width: 23em;
        margin-left: auto;
        margin-right: auto;
    }

    .domotics-device-config-quad-container > h3 {
        margin: 0 0 35px 0;
    }

    .domotics-device-config-quad-container > .info > .icon-left{
        position: relative;

    }

    .domotics-device-config-quad-container > .info > .icon-left > i{
        font-size: 2em;
    }

    .domotics-device-config-quad-container > .info > .description{
        text-align: -webkit-left;
        text-align: left;
        // margin-left: -15px;
    }

    .quad-wrapper{
        width: auto;
        /*height: 100%;*/
        position: relative;
        height: 14em;
        width: 14em;
        margin-left: auto;
        margin-right: auto;
    }

    .quad-wrapper i{
        font-size:  14em;
    }

    .quad-wrapper .quad-button{
        z-index: 100;
        opacity: 0.5;
        cursor: pointer;
        position: absolute;
        width: 75px;
        height: 74px;
        text-align: center;
    }

    .quad-wrapper .quad-button:hover{
        background-color: #6FB0FF;
    }

    .quad-wrapper .quad-button:active{
        background-color: #002F6D;
    }

    .quad-wrapper .quad-button:nth-of-type(1){
        top: 32px;
        left: 32px;
        width: 64px;
        height: 41px !important;
    }
    .quad-wrapper .quad-button:nth-of-type(2){
        top: 32px;
        right: 32px;
        width: 64px;
        height: 41px !important;
    }
    .quad-wrapper .quad-button:nth-of-type(3){
        bottom: 33px;
        left: 32px;
        width: 64px;
        height: 41px !important;
    }
    .quad-wrapper .quad-button:nth-of-type(4){
        bottom: 33px;
        right: 32px;
        width: 64px;
        height: 41px !important;
    }
/***************************************************************************************/

/****************************************** REMOTE *************************************/
    .ir-remote{
        width: 196px;
        margin-left: calc(50% - 98px);
        margin-right: calc(50% - 98px);
        background-image: url(../../../images/src/images/remote/comando_total.svg);
        background-repeat: no-repeat;
    }

    .ir-remote div{
        background-repeat: no-repeat;
    }

    /*.ir-remote > div{
        width: 14em;
    }*/

    .ir-remote > .top-section{
        height: 74px;
        background-image: url(../../../images/src/images/remote/comando_01.svg);
    }
    .ir-remote > .top-button-section{
        height: 8px;
    }
    .ir-remote > .mid-section{
        background-image: url(../../../images/src/images/remote/comando_03.svg);
        height: 48px;
    }
    .ir-remote > .bottom-button-section{
        height: 7px;
    }
    .ir-remote > .bottom-section{
        background-image: url(../../../images/src/images/remote/comando_05.svg);
        height: 64px;
    }


    .ir-remote > .top-first-button-row > div:nth-child(1){
        background-image: url(../../../images/src/images/remote/comando_bt_up_01.svg);
    }
    .ir-remote > .top-first-button-row > div:nth-child(1):hover{
        background-image: url(../../../images/src/images/remote/comando_bt_up_01_highlighted.svg);
    }
    .ir-remote > .top-first-button-row > div:nth-child(1):active{
        background-image: url(../../../images/src/images/remote/comando_bt_up_01_pressed.svg);
    }
    .ir-remote > .top-first-button-row > div:nth-child(2){
        background-image: url(../../../images/src/images/remote/comando_bt_up_02.svg);
    }
    .ir-remote > .top-first-button-row > div:nth-child(2):hover{
        background-image: url(../../../images/src/images/remote/comando_bt_up_02_highlighted.svg);
    }
    .ir-remote > .top-first-button-row > div:nth-child(2):active{
        background-image: url(../../../images/src/images/remote/comando_bt_up_02_pressed.svg);
    }
    .ir-remote > .top-first-button-row > div:nth-child(3){
        background-image: url(../../../images/src/images/remote/comando_bt_up_03.svg);
    }
    .ir-remote > .top-first-button-row > div:nth-child(3):hover{
        background-image: url(../../../images/src/images/remote/comando_bt_up_03_highlighted.svg);
    }
    .ir-remote > .top-first-button-row > div:nth-child(3):active{
        background-image: url(../../../images/src/images/remote/comando_bt_up_03_pressed.svg);
    }
    .ir-remote > .top-first-button-row > div:nth-child(4){
        background-image: url(../../../images/src/images/remote/comando_bt_up_04.svg);
    }
    .ir-remote > .top-first-button-row > div:nth-child(4):hover{
        background-image: url(../../../images/src/images/remote/comando_bt_up_04_highlighted.svg);
    }
    .ir-remote > .top-first-button-row > div:nth-child(4):active{
        background-image: url(../../../images/src/images/remote/comando_bt_up_04_pressed.svg);
    }
    .ir-remote > .top-last-button-row > div:nth-child(1){
        background-image: url(../../../images/src/images/remote/comando_bt_down_01.svg);
    }
    .ir-remote > .top-last-button-row > div:nth-child(1):hover{
        background-image: url(../../../images/src/images/remote/comando_bt_down_01_highlighted.svg);
    }
    .ir-remote > .top-last-button-row > div:nth-child(1):active{
        background-image: url(../../../images/src/images/remote/comando_bt_down_01_pressed.svg);
    }
    .ir-remote > .top-last-button-row > div:nth-child(2){
        background-image: url(../../../images/src/images/remote/comando_bt_down_02.svg);
    }
    .ir-remote > .top-last-button-row > div:nth-child(2):hover{
        background-image: url(../../../images/src/images/remote/comando_bt_down_02_highlighted.svg);
    }
    .ir-remote > .top-last-button-row > div:nth-child(2):active{
        background-image: url(../../../images/src/images/remote/comando_bt_down_02_pressed.svg);
    }
    .ir-remote > .top-last-button-row > div:nth-child(3){
        background-image: url(../../../images/src/images/remote/comando_bt_down_03.svg);
    }
    .ir-remote > .top-last-button-row > div:nth-child(3):hover{
        background-image: url(../../../images/src/images/remote/comando_bt_down_03_highlighted.svg);
    }
    .ir-remote > .top-last-button-row > div:nth-child(3):active{
        background-image: url(../../../images/src/images/remote/comando_bt_down_03_pressed.svg);
    }
    .ir-remote > .top-last-button-row > div:nth-child(4){
        background-image: url(../../../images/src/images/remote/comando_bt_down_04.svg);
    }
    .ir-remote > .top-last-button-row > div:nth-child(4):hover{
        background-image: url(../../../images/src/images/remote/comando_bt_down_04_highlighted.svg);
    }
    .ir-remote > .top-last-button-row > div:nth-child(4):active{
        background-image: url(../../../images/src/images/remote/comando_bt_down_04_pressed.svg);
    }
    .ir-remote > .bottom-first-button-row > div:nth-child(1){
        background-image: url(../../../images/src/images/remote/comando_bt_A.svg);
    }
    .ir-remote > .bottom-first-button-row > div:nth-child(1):hover{
        background-image: url(../../../images/src/images/remote/comando_bt_A_highlighted.svg);
    }
    .ir-remote > .bottom-first-button-row > div:nth-child(1):active{
        background-image: url(../../../images/src/images/remote/comando_bt_A_pressed.svg);
    }
    .ir-remote > .bottom-first-button-row > div:nth-child(2){
        background-image: url(../../../images/src/images/remote/comando_bt_B.svg);
    }
    .ir-remote > .bottom-first-button-row > div:nth-child(2):hover{
        background-image: url(../../../images/src/images/remote/comando_bt_B_highlighted.svg);
    }
    .ir-remote > .bottom-first-button-row > div:nth-child(2):active{
        background-image: url(../../../images/src/images/remote/comando_bt_B_pressed.svg);
    }
    .ir-remote > .bottom-first-button-row > div:nth-child(3){
        background-image: url(../../../images/src/images/remote/comando_bt_C.svg);
    }
    .ir-remote > .bottom-first-button-row > div:nth-child(3):hover{
        background-image: url(../../../images/src/images/remote/comando_bt_C_highlighted.svg);
    }
    .ir-remote > .bottom-first-button-row > div:nth-child(3):active{
        background-image: url(../../../images/src/images/remote/comando_bt_C_pressed.svg);
    }
    .ir-remote > .bottom-first-button-row > div:nth-child(4){
        background-image: url(../../../images/src/images/remote/comando_bt_D.svg);
    }
    .ir-remote > .bottom-first-button-row > div:nth-child(4):hover{
        background-image: url(../../../images/src/images/remote/comando_bt_D_highlighted.svg);
    }
    .ir-remote > .bottom-first-button-row > div:nth-child(4):active{
        background-image: url(../../../images/src/images/remote/comando_bt_D_pressed.svg);
    }
    .ir-remote > .bottom-last-button-row > div:nth-child(1){
        background-image: url(../../../images/src/images/remote/comando_bt_E.svg);
    }
    .ir-remote > .bottom-last-button-row > div:nth-child(1):hover{
        background-image: url(../../../images/src/images/remote/comando_bt_E_highlighted.svg);
    }
    .ir-remote > .bottom-last-button-row > div:nth-child(1):active{
        background-image: url(../../../images/src/images/remote/comando_bt_E_pressed.svg);
    }
    .ir-remote > .bottom-last-button-row > div:nth-child(2){
        background-image: url(../../../images/src/images/remote/comando_bt_F.svg);
    }
    .ir-remote > .bottom-last-button-row > div:nth-child(2):hover{
        background-image: url(../../../images/src/images/remote/comando_bt_F_highlighted.svg);
    }
    .ir-remote > .bottom-last-button-row > div:nth-child(2):active{
        background-image: url(../../../images/src/images/remote/comando_bt_F_pressed.svg);
    }
    .ir-remote > .bottom-last-button-row > div:nth-child(3){
        background-image: url(../../../images/src/images/remote/comando_bt_G.svg);
    }
    .ir-remote > .bottom-last-button-row > div:nth-child(3):hover{
        background-image: url(../../../images/src/images/remote/comando_bt_G_highlighted.svg);
    }
    .ir-remote > .bottom-last-button-row > div:nth-child(3):active{
        background-image: url(../../../images/src/images/remote/comando_bt_G_pressed.svg);
    }
    .ir-remote > .bottom-last-button-row > div:nth-child(4){
        background-image: url(../../../images/src/images/remote/comando_bt_H.svg);
    }
    .ir-remote > .bottom-last-button-row > div:nth-child(4):hover{
        background-image: url(../../../images/src/images/remote/comando_bt_H_highlighted.svg);
    }
    .ir-remote > .bottom-last-button-row > div:nth-child(4):active{
        background-image: url(../../../images/src/images/remote/comando_bt_H_pressed.svg);
    }

    .ir-remote > .top-first-button-row > div,
    .ir-remote > .top-last-button-row > div,
    .ir-remote > .bottom-first-button-row > div,
    .ir-remote > .bottom-last-button-row > div{
        width: 50px;
        height: inherit;
        display: table-cell;
    }

    .ir-remote > .top-first-button-row,
    .ir-remote > .top-last-button-row,
    .ir-remote > .bottom-first-button-row,
    .ir-remote > .bottom-last-button-row{
        height: 37px;
    }
/***************************************************************************************/

/**************************************** Scenarios ************************************/
    .section > .scenario-name{
        margin-bottom: 12px;
    }

    .section > .scenario-name > span:first-child{
        font-size: 24px;
        font-family: "KelsonSansBold";
        font-weight: bold;
        color: black;
    }
    .section > .scenario-name > span{
        font-size: 24px;
        font-family: "KelsonSansBold";
        font-weight: bold;
        color: #657281;
    }

    .scenario-details-area{  
        // display: flex;
    }

    .scenario-details-area > .scenario-details-pane-wrapper.associated-devices > .scenario-details-pane > .device-display-box.cards-container{
        width: 100% !important;
    }
    .scenario-details-pane-wrapper.associated-devices{
        width: 100% !important;
    }
    .scenario-details-pane-wrapper.general-settings{
        margin-bottom: 5rem;
    }
    .scenario-details-pane-wrapper .help-container{
        margin-bottom: 1rem;
        margin-left: auto;
        margin-right: auto;
        max-width: none !important;
    }

     @media(max-width: 410px){
        .scenario-details-pane-wrapper .scenario-details-pane{
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }
    }

    @media(max-width: 380px){
        .add-new-device-small{
            display: none;
        }
        .section.container-fluid.scenario-super-container{
            padding-left: 1px !important;
            padding-right: 1px !important;
        }
        .scenario-details-pane-wrapper.associated-devices .scenario-details-pane{
            padding-left: 1px !important;
            padding-right: 1px !important;
        }
        .section.container-fluid.scenario-super-container input {
            width: 95%;
            margin-left: 2.5% !important;
            margin-right: 2.5% !important;
            margin-bottom: 1rem;
        }
    }

    @media(min-width: 381px){
        .add-new-device-big{
            display: none;
            width: 100%;
        }

    }

    @media (min-width: 992px){
        .scenario-details-pane-wrapper.general-settings{
            width: calc(62% - 2rem) !important;
            max-width: 500px;
        }
    }
    @media (max-width: 993px){
        .scenario-details-area{
            display: block;
        }
        .scenario-details-pane-wrapper.associated-devices{
            width: 100% !important;
            display: block;
            margin-bottom: 25px;
        }
        .scenario-details-pane-wrapper.general-settings{
            width: 100% !important;
            display: block;
            margin-bottom: 25px;
        }
    }
    .scenario-details-pane-wrapper{
        // width: calc(50% - 2rem);
        display: inline-block;
        height: auto;
    }
    .scenario-details-pane-wrapper.general-settings{
        margin-right: 2em;
    }

    .scenario-details-pane-wrapper .scenario-details-pane {
        background-color: #FFFFFF;
        width: 100%;
        border-radius: 0 0 3px 3px;
        padding: 2rem;
        /*min-height: 262px;*/
    }
    .scenario-details-pane-wrapper .pane-title-container{
        border-bottom: solid 1px #F1F1F1;
        background-color: #FFFFFF;
        min-height: 42px;
        width: 100%;
        border-radius: 3px 3px 0 0 ;
        padding-left: 2rem;
        padding-top: 1rem
    }

    .scenario-details-pane-wrapper > .pane-title-container > span > span{
        font-family: "KelsonSansBold";
        font-size: 20px;
        font-weight: bold;
    }
    .scenario-details-pane-wrapper > .pane-title-container > input{
        height: 34px;
        float: right;
        margin-top: -7px;
        margin-right: 1rem;
    }

    .scenario-details-pane-wrapper.general-settings .domotics-scenario-config-icon:before{
        font-size: 34px !important;
        line-height: 34px !important;
        margin-left: 14px;
    }

    .scenario-details-pane-wrapper.general-settings .domotics-scenario-config-icon{
        width: 64px !important;
        height: 64px !important;
        padding-top: 14px;
        margin-bottom: 2rem;
        margin-top: 0px;
        margin-right: 2rem;
        display: inline-block;
        border: solid 1px #DADFE5;
        border-radius: 3px;
    }

    @media (max-width: 400px){
        .scenario-details-pane-wrapper.general-settings .input-container > .input-wrapper{
            width: 100% !important;
            display: block !important;
        }
    }

    .scenario-details-pane-wrapper.general-settings .input-container > .input-wrapper{
        display: inline-block;
        width: calc(50% - 1rem);
    }
    .scenario-details-pane-wrapper.general-settings .input-container > .input-wrapper:nth-child(odd){
        margin-right: 2rem;
        width: 100%;
    }
    .scenario-details-pane-wrapper.general-settings .input-container > .input-wrapper > input,
    .scenario-details-pane-wrapper.general-settings .input-container > .input-wrapper > select,
    .scenario-details-pane-wrapper.general-settings .input-container > .input-wrapper > span{
        display: block;
        width: 100%;
    }
    .scenario-details-pane-wrapper.general-settings .input-container > .input-wrapper > input,
    .scenario-details-pane-wrapper.general-settings .input-container > .input-wrapper > select{
        margin-bottom: 20px;
        height: 35px;
        font-family: "KelsonSansBold";
        font-weight: bold;
        padding: 5px;
    }
    /*.scenario-details-pane-wrapper.general-settings .input-container > .input-wrapper > input{
        -webkit-appearance: searchfield;
    }*/
    .scenario-details-pane-wrapper.general-settings .input-container > .input-wrapper span{
        font-family: "KelsonSansBold";
        font-weight: bold;
        margin-bottom: 2px;
    }

    .scenario-details-pane .scenario-line{
        display: -webkit-inline-box;
    }
    .scenario-details-pane .scenario-line .input-container{
        width: calc(50% - 1rem);
    }

    .scenario-details-pane .scenario-line .input-container input{
        width: 100%
    }

    .scenario-details-pane .icon-subpane{
        display: inline-block;
        margin-right: 2rem;
        margin-top: 2px;
    }

    .scenario-details-pane .name-subpane{
        display: inline-block;
        width: calc(100% - 150px)
    }

    .scenario-details-pane .name-subpane .input-wrapper{
        height: 100%;
        width: 100%;
    }

    .scenario-details-pane .icon-title span,
    .scenario-details-pane .icon-title{
        display: block;
        font-family: "KelsonSansBold";
        font-weight: bold;
        margin-bottom: 6px;
    }


    .scenario-details-pane.general-settings{
        margin-right: 3rem;
    }

    .card-container.scenario .domotics-scenario-list-icon{
        text-align: center;
    }

    .card-container.scenario i.avatar{
        font-size: 4.3em;
        color:#aeaeae;
        position: relative;
        top: 37%;
    }

    .card-container .device-down-simple.scenario {
        padding: 10px;
    }

    .card-container:hover .device-down-simple.scenario .device-card-name{
        color:#002F6D !important;
    }

    .card-container:active .device-down-simple.scenario .device-card-name{
        color:#FFFFFF !important;
    }

    .card-container:active .device-down-simple.scenario{
        background-color:#002F6D !important;
    }
/***************************************************************************************/

/******************************************* Wizard *************************************/
    .wizard-config-button-container,
    .domotics-device-config-wizard-langselect{
        max-width: 774px;
    }

    .wizard-config-button-container{
        height: 100px !important;
        margin-left: auto;
        margin-right: auto;
        margin-top: 4rem;
    }
    .wizard-config-button-container > div{
    }
/****************************************************************************************/

/*********************************** Wizard Network Step 3 ******************************/
    .domotics-config-wizard-create-user{
        padding-left: 10px;
        padding-right: 10px;
    }

    .domotics-config-wizard-create-user .item-wrapper{
        width: 97% !important;
    }

    .domotics-config-wizard-create-user > .create-user > .form-group > .form-item > label{
        float: left;
        font-size: 2rem
    }

    .domotics-config-wizard-create-user > .create-user > .form-group > .form-item > input{
        color: black;
        padding-left: 10px;
        border-radius: 5px;
        display: inline-block;
        height: 36px;
        font-size: 17px;
        width: 100%;
    }

    .domotics-config-wizard-create-user .aux-text i {
            font-size: 3rem;
        }

    .domotics-config-wizard-create-user .aux-text {
        padding: 0 0em 0 1em;
        font-size: large;
        float: left;
        text-align: left;
        margin-bottom: 10px;
    }
/****************************************************************************************/

/*********************************** Wizard Network Step 4 ******************************/
    .stage-wrapper{
        width: 75%;
        margin-left: auto !important;
        margin-right: auto !important;
        max-width: 1400px;
        margin-top: 100px;
    }

    .stage-wrapper.mini{
        width: 30% !important;
        margin-top: 1% !important;
    }

    .stage {
        display: inline-block;
        margin-left: 1%;
        margin-right: 1%;
        vertical-align: middle;
    }

    .circle{
        border-radius: 50%;
        width: 29%;
        height: auto;
        padding-bottom: 29%
    }

    .circle-active {
        background-color: #65B2E9;
    }

    .circle-inactive {
        background-color: #BBBBBB;
    }

    .mini > .stage{
        margin-left: 2%;
        margin-right: 2%;
    }


    .icon{
        /*background-size: cover;*/
        background-repeat: no-repeat;
        background-size: 70% 70%;
        background-position: center
    }

    .ethernetIcon{
        background-image: url(../../../images/src/images/icons/ethernet.svg);
    }

    .wifiIcon{
        background-image: url(../../../images/src/images/icons/wifi.svg);
    }

    .powerplugIcon{
        background-image: url(../../../images/src/images/icons/powerplug.svg);
    }

    .nohomeserverIcon{
        background-image: url(../../../images/src/images/icons/nohomeserver.svg);
    }

    .loginicon {
        width: 24vh;
        height: 24vh;
        background-size: cover;
    }

    .clearer {
        clear: both;
    }

    .info-wrapper{
        width: 80%;
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 20px;
        font-size: 2rem;
    }

    .domotics-device-config-wizard-network form.ng-invalid {
        border: 0px !important;
    }

    .domotics-config-wizard-create-user .item-wrapper,
    .domotics-device-config-wizard-management .item-wrapper,
    .domotics-device-config-wizard-langselect .item-wrapper{
        padding: 10px 18px 10px 25px;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: left;
        vertical-align: middle;
        background-color: #002F6D;
        color: white;
        height: 50px;
        border-radius: 5px;
        font-size: 3rem;
    }

    .domotics-config-wizard-create-user .item-wrapper.inactive,
    .domotics-device-config-wizard-management .item-wrapper.inactive,
    .domotics-device-config-wizard-langselect .item-wrapper.inactive{
        background-color: #BBBBBB;
    }

    .config-panel{
        clear: both;
        display: block;
        background-color: #BBBBBB;
        margin: -3px auto 30px auto;
        padding: 10px 18px 15px 25px;
        border-radius: 0 0 5px 5px;
        /*width: 95%;*/
        height: auto;
        text-align: left;
        vertical-align: middle;
        color: white;
        font-size: 1em;
    }

    .config-panel > form > .form-element {
        margin-top: 15px;
    }

    .config-panel .form-group {
        padding: 5px 0;
    }

    .config-panel .form-group > .form-item{
        max-width: 45%;
        display: inline-block;
        margin-right: 2%;
    }

    .config-panel .form-group > .form-item > input,
    .config-panel .form-group > .form-item > select,
    .config-panel  form  .form-element > input{
        color: black;
        padding-left: 10px;
        border-radius: 5px;
        display: inline-block;
        height: 36px;
        font-size: 17px;
        width: 100%;
    }

    .config-panel .form-group > .form-item > input:disabled,
    .config-panel .form-group > .form-item > select:disabled,
    .config-panel  form  .form-element > input:disabled{
        opacity: 0.5;
    }


    .config-panel > form > .form-element > label{
        width: 20%;
    }

    .config-panel .connectionMode {
        font-size: 2rem;
    }


    .toggle-text{
        width: calc(100% - 54px);
        font-size: 1.6rem;
        margin-top: 0.45rem;
    }


    .domotics-device-config-wizard-network{
        max-width:774px;
        min-height:50rem;
        background-color: white;
        margin-left: auto;
        margin-right: auto;
        padding-top:5rem;
        margin-top:5rem;
        border:solid 2px black;
        /*font-size:1.3rem;*/
    }

    .domotics-device-config-wizard-network > div{
        margin-left: 3rem;
        margin-right: 3rem;
    }

    .domotics-device-config-wizard-network.wider{
        width: 90%;
    }


    .domotics-device-config-wizard-network .item-wrapper{
        padding: 10px 18px 10px 25px;
        /*width: 95%;*/
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        text-align: left;
        vertical-align: middle;
        background-color: #002F6D;
        color: white;
        /*height: 50px;*/
        border-radius: 5px;
        font-size: large;
    }
    .domotics-device-config-wizard-network .user-list{
        text-align: left;
        padding: 0 2em 0 2em;
    }

    .domotics-device-config-wizard-network .item-wrapper.inactive{
        background-color: #BBBBBB;
    }

    .domotics-device-config-wizard-network .create-new-admin{
        margin-top: 4em;
        margin-bottom: 4em;
    }

    .create-new-admin .no-users-text{
        text-align: center;
        font-size: 2rem;
        padding: 0 5rem 0 5rem;
    }

    .domotics-device-config-wizard-network .aux-text i {
        font-size: 3rem;
    }

    .domotics-device-config-wizard-network .aux-text {
        padding: 0 1em 0 1em;
        font-size: large;
        float: left;
        text-align: left;
    }

    .domotics-device-config-wizard-network .create-user .form-group{
        padding: 0 1em 0 1em;
    }
    .domotics-device-config-wizard-network .create-user .form-group .form-item label{
        font-size: large;
        float: left;
        text-align: left;
        margin-top: 1em;
        margin-bottom: 0;
        display: block;
    }

    .domotics-device-config-wizard-network .create-user .form-group .form-item input{
        width: 100%;
        font-size: large;
        padding: 0 0.5em 0 0.5em;
        color: black;
        border-radius: 5px;
        height: 36px;
        display: block;
    }

    .domotics-device-config-wizard-network .device-down.scenario{
        font-size: 2rem !important;
    }

    .domotics-device-config-wizard-network.wider .device-down i,
    .domotics-device-config-wizard-network.wider .device-down span.device-card-name{
        font-size: 2rem !important;
    }

    .domotics-device-config-wizard-network .device-down{
        line-height: 2.3rem;
    }
    .create-user .item-wrapper .toggle-text{
        margin-top: 0.5rem;
        font-size: large;
    }
/****************************************************************************************/

/*********************************** Wizard MGMT Step 7 *********************************/

    @media (max-width: 767px){
        .domotics-device-config-wizard-management{
            width: 90% !important;
        }
    }

    @media (min-width: 768px) and (max-width: 1440px){
        .domotics-device-config-wizard-management{
            width: 75%;
        }
    }

    @media (max-width: 440px){
        .domotics-device-config-wizard-management .mini-all{
            padding-left: 14px !important;
        }
    }
    @media (min-width: 441px){
        .domotics-device-config-wizard-management .mini-all{
            padding-left: 5rem !important;
            padding-right: 2rem !important;
        }
    }

    .domotics-device-config-wizard-management{
        max-width:774px;
        min-height:50rem;
        background-color: white;
        margin-left: auto;
        margin-right: auto;
        padding-top:5rem;
        margin-top:5rem;
        border:solid 2px black;
        /*font-size:1.3rem;*/
    }
    .domotics-device-config-wizard-management .picker-wrapper{
        margin-left: 0;
        margin-right: 0;
        float: none;
    }

    .domotics-device-config-wizard-management > div{
        margin-bottom: 2rem;
    }

    .domotics-device-config-wizard-management .description-bigger{
        margin-left: 4rem;
    }

    .domotics-device-config-wizard-management .description{
        text-align: left;
    }

    .domotics-device-config-wizard-management .title{
        text-align: left;
        font-size: 23px;
        margin-left: 3rem;
        white-space: normal;
        margin-right: 3rem;
    }

    .domotics-device-config-wizard-management .description i{
        float: left;
        margin-right: 10px;
        margin-left: 10px;
        clear:both;
    }
    .domotics-device-config-wizard-management .description span{
        margin-left: 0px;
        overflow:auto;
        display:block;
    }
/****************************************************************************************/

/*********************************** Wizard MGMT Step 7 *********************************/
/****************************************************************************************/

/***DELETE LATER ***/
.icon-ic_level_empty {
    color:gray;
}
/****************/

.domotics-loading, .no-trans .domotics-loading {
    /*background: url('../../../images/src/images/hourglass.svg') no-repeat center center;*/
    margin: 100px auto;
    width: 80px;
    height: 80px;
    position: relative;
    text-align: center;
    /*margin-top: calc(30% - 40px) !important;*/
    top:calc(50% - 100px);
    -webkit-animation: sk-rotate 2.0s infinite linear !important;
    animation: sk-rotate 2.0s infinite linear !important;
}

.dot1, .dot2, .no-trans .dot1, .no-trans .dot2{
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #7686DD;
    border-radius: 100%;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out !important;
    animation: sk-bounce 2.0s infinite ease-in-out !important;
}

.dot2, .no-trans .dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1.0s !important;
    animation-delay: -1.0s !important;
}

@-webkit-keyframes sk-rotate { 100% { -webkit-transform: rotate(360deg) }}
@keyframes sk-rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}

@-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 50% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
}

/****************************/

.domotics-disable {
    position: absolute;
    top:0;
    left:0;
    background-color: white;
    z-index:1000;
    opacity:0.5;
    filter: alpha(opacity = 50);
}

.domotics-disable2 {
    position:fixed !important;
    width:100%;
    height:100%;
    z-index:1000;
    opacity:0.8;
    filter: alpha(opacity = 80);
    background-color: white;
    top:0;
    left:0;
}


.domotics-simple-disable {
    pointer-events: none;
    opacity: 0.5;
    cursor: default;
}

/*DOMOTICS BUTTON */

.domotics-btn {
    width:10rem;
    margin-left:1rem;
}

.domotics-btn-big {
    width:15rem;
    margin-left:1rem;
}

.domotics-notification-btn {

    background-color: white !important;
    height: 3rem;
    padding: 0;
    margin: 0;
    line-height: 0 !important;
    font-size: small;
    width: 8rem;
}

.btn-primary.domotics-notification-btn:hover {
    background-color : #001ec8 !important;
    color:white !important;
    -webkit-box-shadow : 0px 3px 6px rgb(0, 47, 109);
    -moz-box-shadow : 0px 3px 6px rgb(0, 47, 109);
    box-shadow : 0px 3px 6px rgb(0, 47, 109);
}

.btn-primary.domotics-notification-btn {
    color: #0A7EFE;
}



.btn-primary.domotics-btn:hover {
    background-color : #001ec8 !important;
    -webkit-box-shadow : 0px 3px 6px rgb(0, 47, 109);
    -moz-box-shadow : 0px 3px 6px rgb(0, 47, 109);
    box-shadow : 0px 3px 6px rgb(0, 47, 109);
}

.btn-default.domotics-btn:hover {
    background-color : rgb(232, 232, 232);
    -webkit-box-shadow : 0px 3px 6px rgb(217, 217, 217);
    -moz-box-shadow : 0px 3px 6px rgb(217, 217, 217);
    box-shadow : 0px 3px 6px rgb(217, 217, 217);
}

/******/
/** GRAPH BUTTONS***/

.domotics-graph-btn {
    background-color: white;
    border: 0px;
    text-align: center;
    color: #AEAEAE;
    font-weight: bold;
    cursor:pointer;
    padding: 1rem;
    padding-bottom:0.1rem;
    font-size: smaller;
}

.domotics-graph-btn.active {
    color:black;
    border-bottom: 1px solid #3FA4E2;
    margin-top: -0.1rem;
}

/******/

/**HEADER**/
.devices-header-content-filter div.active{
    border-bottom: solid #3FA4E2;
}

.devices-header-content-filter  div.active span{
    font-family: 'KelsonSansBold';
    color: #FFFFFF;
}

.devices-header-content-filter div{
    border-bottom: solid #50749E;
    height: 40px;
}

.devices-header-content-filter > div > span{
    font-family: 'KelsonSans';
    line-height: 21px;
    // color: #8A8E94index;
    cursor: pointer;
    font-weight: bold;
    font-size:13px;
}

/*global properies*/7872

.togglebtn-active, .togglebtn-inactive {font-size:2.3rem;cursor:pointer;}
.togglebtn-active, .togglebtn-inactive {font-size:2.3rem;cursor:pointer;}
i.togglebtn-active { color: #5cb85c}
i.togglebtn-inactive {color: #d9534f}

.selected-icon {
    color: white !important;
    background-color: #65b2e9;
    /*padding-top: calc(50% - 11rem);*/
    top: 2px;
}


.domotics-fullscreen {
    height:100%;
    width:100%;
    text-align: center;
    /*overflow-y:hidden;*/
}

/*VIEW TRANS ANIMATION*/

.view-container {
    position: relative;
}

.view-frame.ng-enter, .view-frame.ng-leave {
    background: #ECEFF1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.view-frame.ng-enter {
    -webkit-animation: 0.5s fade-in;
    -moz-animation: 0.5s fade-in;
    -o-animation: 0.5s fade-in;
    animation: 0.5s fade-in;
    z-index: 100;
}

.view-frame.ng-leave {
    -webkit-animation: 0.5s fade-out;
    -moz-animation: 0.5s fade-out;
    -o-animation: 0.5s fade-out;
    animation: 0.5s fade-out;
    z-index:99;
}

@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}
@-moz-keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}
@-webkit-keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fade-out {
    from { opacity: 1; }
    to { opacity: 0; }
}
@-moz-keyframes fade-out {
    from { opacity: 1; }
    to { opacity: 0; }
}
@-webkit-keyframes fade-out {
    from { opacity: 1; }
    to { opacity: 0; }
}
/*VIEW TRANS ANIMATION*/

.navbar{
    -webkit-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    transition: all 0.8s ease;
}

/*.app-body {
    -webkit-transition: padding-top 0.3s ease;
    -moz-transition: padding-top 0.3s ease;
    -o-transition: padding-top 0.3s ease;
    transition: padding-top 0.3s ease;
}*/

.app {
    background-color: #ECEFF1;
}
.app-content {
    background-color:#ECEFF1;
}

.domotics-side-bar-title-cloud {
    background-image: url('../../../images/src/images/logos/Logo_Domus40-cloud@3x.png')!important;
    background-size: 160px!important;
}

.domotics-side-bar-title {
    background-image: url('../../../images/src/images/logos/Logo_Domus40@3x.png');
    background-size: 116px;
    background-position: 20px;
    background-repeat: no-repeat;
    border-bottom: solid #2975A3 1px;
    background-color: #3CA2E0;
    height: 56px;
    color:#FFFFFF;
    font-family: "KelsonSans";
    font-weight: bold;
}

.domotics-side-bar-title span{
    margin-bottom: 10px;
}

.domotics-sidebar span{
    font-family: "KelsonSans";
}

.domotics-homeserver-list {
    border: 0px;
}

.nohomeserver-login-wrapper {
    margin-top:10rem;
}

@media (min-width: 1281px) {
    .domotics-homeserver-list {
        width: 90rem!important;
    }
}

@media (max-width: 1281px) and (min-width: 1124px) {
    .domotics-homeserver-list {
        width: 30rem!important;
    }
}

@media (max-width: 1281px) and (min-width: 1124px) {
    .domotics-homeserver-list {
        width: 50rem!important;
    }
}

@media (max-width: 852px) and (min-width: 770px) {
    .domotics-homeserver-list {
        width: 30rem!important;
    }
}

.domotics-sidebar {
    background-color:white;
}

.domotics-sidebar a:hover {
    color: #65b2e9;
}

.list-group-item, a.list-group-item {
    border-bottom: none;
    height: 49px;
    padding-top: 14px;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    color: #015C99;
    background-color: #F3F9FC;
    background-image: none;
    border-bottom: none;
    border-right: 4px solid #015C99;
    text-shadow: 0 0 0;
    font-weight: 500;
    font-family: "KelsonSansBold";
}

.domotics-sidebar-top-span {
    margin-left: 1rem;
    font-size: xx-small;
    font-weight: bold;
    color: gray;
}

.navbar {
    border-bottom: solid #2975A3 1px;
    background-color: #3CA2E0;
    min-height: 56px !important;
   /* max-height: 56px !important;*/
    border-radius: 0px;
}

.navbar div.btn {
    color:white !important;
}

/* shadow effect */
.effect1{
    box-shadow:0px 0.3571rem 0.3571rem #777;
}

/*DEVICE action animations */
@-webkit-keyframes pulsePers {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    to {
        -webkit-transform: scale3d(1.02, 1.02, 1.02);
        transform: scale3d(1.05, 1.05, 1.05);
    }

}

@keyframes pulsePers {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    to {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }
}


/*.pulsePers {
    -webkit-animation-name: pulsePers;
    animation-name: pulsePers;
}*/

.pulsePers {
    filter: none;
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -ms-filter: blur(0px);
    filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
    -webkit-transform: scale3d(1.02, 1.02, 1.02);
    transform: scale3d(1.02, 1.02, 1.02);
}


/*LEAVE*/
@-webkit-keyframes pulsePersLeave {
    from {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes pulsePersLeave {
    from {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.pulsePersLeave {
    filter: none;
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -ms-filter: blur(0px);
    filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
    -webkit-animation-name: pulsePersLeave;
    animation-name: pulsePersLeave;
}


@-webkit-keyframes devicePress {
    from {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(0.90, 0.90, 0.90);
        transform: scale3d(0.90, 0.90, 0.90);
    }
}

@keyframes devicePress {
    from {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(0.90, 0.90, 0.90);
        transform: scale3d(0.90, 0.90, 0.90);
    }
}

.domotics-device-protect {
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    margin-top:0.5rem;
}
/*.devicePress {
    -webkit-animation-name: devicePress;
    animation-name: devicePress;
}*/

.devicePress {
    filter: none;
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -ms-filter: blur(0px);
    filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95);
}


.devicePressPressivePositive {
    /*-webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin:bottom;
    -webkit-transform: rotateY(15deg);
    -moz-transform: rotateY(15deg);
    -o-transform:rotateY(15deg);
    transform: rotateY(15deg);
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    z-index: 900;*/
    /*box-shadow: 0 22rem 6rem -6rem #777, 0 -6rem 6rem -6rem #777;*/
}


.devicePressPressiveNegative {
    /*-webkit-transform-origin: top;
    -moz-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: rotateY(-15deg);
    -moz-transform: rotateY(-15deg);
    -o-transform:rotateY(-15deg);
    transform: rotateY(-15deg);
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    z-index: 900;*/
}


.progressive-parent-persp {
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 73.571rem;
    -moz-perspective: 73.571rem;
    -o-perspective: 73.571rem;
    perspective: 73.571rem;
}

/***flip**/

.card {

}

.card__front,
.card__back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.card__front,
.card__back {
    -webkit-backface-visibility: hidden !important;
    backface-visibility: hidden !important;
    -webkit-transition: -webkit-transform 0.5s !important;
    transition: transform 0.5s !important;
}

.card__front {
}

.card__back {
    -webkit-transition:transform 0.5s !important;
    transition:transform 0.5s !important;
    -webkit-transform: rotateY(-180deg) !important;
    transform: rotateY(-180deg) !important;
}


.card.effect__click.flipped .card__front {
    -webkit-transition:transform 0.5s !important;
    transition:transform 0.5s !important;
    -webkit-transform: rotateY(-180deg) !important;
    transform: rotateY(-180deg) !important;
}

.card.effect__click.flipped .card__back {
    -webkit-transition:transform 0.5s !important;
    transition:transform 0.5s !important;
    -webkit-transform: rotateY(0) !important;
    transform: rotateY(0) !important;
}

/**********/

.domotics-trash-icon {
    font-family: 'FontAwesome';
}

.domotics-trash-icon:before {
    content: "\f1f8";
}


/************************** WIZARD STEPS CSS **********************/

.flexer,  .progress-indicator  {
    display:  -webkit-box;
    display:  -moz-box;
    display:  -ms-flexbox;
    display:  -webkit-flex;
    display:  flex;
}
.no-flexer,  .progress-indicator.stacked  {
    display:  block;
}
.no-flexer-element  {
    -ms-flex:  0;
    -webkit-flex:  0;
    -moz-flex:  0;
    flex:  0;
}
.flexer-element,  .progress-indicator > li  {
    -ms-flex:  1;
    -webkit-flex:  1;
    -moz-flex:  1;
    flex:  1;
}
.progress-indicator  {
    margin:  0;
    padding:  0;
    font-size:  100%;
    margin-bottom:  1em;
}
.progress-indicator > li  {
    list-style:  none;
    text-align:  center;
    width:  auto;
    padding:  0;
    margin:  0;
    position:  relative;
    text-overflow:  ellipsis;
    color:  #bbbbbb;
    display:  block;
}
.progress-indicator > li:hover  {
    color:  #6e6e6e;
}
.progress-indicator > li .bubble  {
    border-radius:  1000px;
    width:  20px;
    height:  20px;
    background-color:  #bbbbbb;
    display:  block;
    margin:  0 auto 0.5em auto;
    border-bottom:  1px solid #888888;
}
.progress-indicator > li .bubble:before,  .progress-indicator > li .bubble:after  {
    display:  block;
    position:  absolute;
    top:  9px;
    width:  100%;
    height:  3px;
    content:  '';
    background-color:  #bbbbbb;
}
.progress-indicator > li .bubble:before  {
    left:  0;
}
.progress-indicator > li .bubble:after  {
    right:  0;
}
.progress-indicator > li.completed  {
    color:  green;
}
.progress-indicator > li.completed .bubble  {
    background-color:  #65d074;
    color:  #65d074;
    border-color:  #247830;
}
.progress-indicator > li.completed .bubble:before,  .progress-indicator > li.completed .bubble:after  {
    background-color:  #65d074;
    border-color:  #247830;
}
.progress-indicator > li a:hover .bubble  {
    background-color:  #5671d0;
    color:  #5671d0;
    border-color:  #1f306e;
}
.progress-indicator > li a:hover .bubble:before,  .progress-indicator > li a:hover .bubble:after  {
    background-color:  #5671d0;
    border-color:  #1f306e;
}
.progress-indicator > li.danger .bubble  {
    background-color:  #d3140f;
    color:  #d3140f;
    border-color:  #440605;
}
.progress-indicator > li.danger .bubble:before,  .progress-indicator > li.danger .bubble:after  {
    background-color:  #d3140f;
    border-color:  #440605;
}
.progress-indicator > li.warning .bubble  {
    background-color:  #edb10a;
    color:  #edb10a;
    border-color:  #5a4304;
}
.progress-indicator > li.warning .bubble:before,  .progress-indicator > li.warning .bubble:after  {
    background-color:  #edb10a;
    border-color:  #5a4304;
}
.progress-indicator > li.info .bubble  {
    background-color:  #5b32d6;
    color:  #5b32d6;
    border-color:  #25135d;
}
.progress-indicator > li.info .bubble:before,  .progress-indicator > li.info .bubble:after  {
    background-color:  #5b32d6;
    border-color:  #25135d;
}
.progress-indicator.stacked > li  {
    text-indent:  -10px;
    text-align:  center;
    display:  block;
}
.progress-indicator.stacked > li .bubble:before,  .progress-indicator.stacked > li .bubble:after  {
    left:  50%;
    margin-left:  -1.5px;
    width:  3px;
    height:  100%;
}
.progress-indicator.stacked .stacked-text  {
    position:  relative;
    z-index:  10;
    top:  0;
    margin-left:  60% !important;
    width:  45% !important;
    display:  inline-block;
    text-align:  left;
    line-height:  1.2em;
}
.progress-indicator.stacked > li a  {
    border:  none;
}
@media handheld,  screen and (max-width: 400px)  {
    .progress-indicator  {
        font-size:  60%;
    }
}

@media handheld,  screen and (min-width: 400px) and (max-width: 420px)  {
    .progress-indicator  {
        font-size:  85%;
    }
}

/******************************************************************/



/***** NOTIFICATIONS DROPDOWN ******/
.notifications-container {
    display:inherit;
    margin-right:2rem;
    width:29.5rem;
}

.notification-messages-container-scrollable{
    max-height:40rem;
    overflow-y:auto;
    overflow-x:hidden;
}

.notification-messages-container-scrollable > li {
    overflow:hidden;
}

.notifications-container-title-container {
    cursor:default;
    margin-bottom:1rem;
    color:black;
    text-align: center;
    font-size: medium;
    font-weight: bold;
}

.notifications-container-title-container > span {
    cursor:default;
}

.notifications-container-title-container > i {
    cursor:pointer;
    margin-right:1rem;
}

.notification-content-container {
    margin:0;
    padding:0;
    border:none;
}

.notifications-container-end-el {
    margin:0.5rem;
}

.see-all-messages-container {
    text-align: center;
    font-weight: bold;
}

.notification-title {
    background-color: white;
    color:black;
    text-align: center;
    border:0;
}

.notification-body {
    background-color: lightblue;
}

.not-with-action {
    color:#FF9B00;
    font-size:medium;
}

.not-no-action {
    color:#65b2e9;
    font-size:medium;
}
/**********************************/


/***************************   Scenarios   ************************/
    .scenario-header-container{
        text-align: center;
        padding-top: 16px;
        font-size: 14px;
    }
    .scenario-header-container  span{
        font-size: 14px;
        font-family: "KelsonSansBold";
        padding-right: 26px;
    }
    .pseudo-header{
        width: 100%;
        display: block;
        height: 30px;
        margin-bottom: 20px;
    }
    .pseudo-header > div{
        display: inline-block;
    }
    .pseudo-header > .scenario-counter{
        float: left;
        overflow: hidden;
        white-space:nowrap;
        text-overflow:ellipsis;
        width: calc(100% - 18rem);
        padding-top: 4px;
    }
    .pseudo-header > .scenario-counter > span{
        font-size: 20px;
        font-weight: bold;
        color: black;
    }
    .pseudo-header > .scenario-counter span:nth-child(2){
        color: #2C97DE;
    }
    .pseudo-header > .add-new-scenario{
        float: right;
        margin-right: 9px;
    }
    @media (max-width: 991px){
        .pseudo-header{
            margin-bottom: 20px;
            height: 50px
        }
        .pseudo-header > .add-new-scenario > input{
            height: 44px;
        }
        .pseudo-header > .scenario-counter{
            padding-top: 8px;
        }
    }
    @media (max-width: 450px){
        .pseudo-header{
            height: 73px;
        }
        .pseudo-header > .scenario-counter{
            width: 100% !important;
            margin-bottom: 5px;
        }
        .pseudo-header > .add-new-scenario > input,
        .pseudo-header > .add-new-scenario{
            width: 100%;
        }
    }
    .content{
        display: inline-block;
        padding: 10px;
    }
    .content-card{
        width: 24.46rem !important;
        height: 18.86rem !important;
        position: relative;
        float:left;
        border-radius: 0.2143rem;
    }

    .card-container.scenario .selectedDevice{
        background-color: #7CBDEC;
        color: #FFFFFF;
    }

    .card-container.scenario .device-down-simple span{
        position: relative;
        top: 1rem;
    }

    .domotics-device-config-general-settings.schedules .scenario-config-schedules .schedule-line input{
        /*width: 85px !important;*/
        margin-right: -4px;
        display: inline-block;
    }

    @media (min-width: 320px) and (max-width: 1350px){
        .btn-group.day-picker{
            width: 26rem;
            margin-left: 2%;
        }
        .btn.btn-primary.toggle{
             width: 49% !important;
             border-radius: 0;
             margin-left: 0;
        }
        .btn.btn-primary.toggle:nth-child(1){
            border-radius: 10px 0 0 0;
        }
        .btn.btn-primary.toggle:nth-child(2){
            border-radius: 0 10px 0 0 !important;
        }
        .btn.btn-primary.toggle:nth-child(7){
            border-radius: 0 0 0 10px !important;
        }
        .btn.btn-primary.toggle:nth-child(8){
            border-radius: 0 0 10px 0;
        }
    }

    @media (min-width: 1350px) and (max-width: 1635px){
        .btn-group.day-picker{
            width: 56rem;
        }
        .btn.btn-primary.toggle{
             width: 14rem !important;
             margin-left: 0;
        }
        .btn.btn-primary.toggle:nth-child(1){
            border-radius: 10px 0 0 0;
        }
        .btn.btn-primary.toggle:nth-child(4){
            border-radius: 0 10px 0 0 !important;
        }
        .btn.btn-primary.toggle:nth-child(5){
            border-radius: 0 0 0 10px !important;
        }
        .btn.btn-primary.toggle:nth-child(8){
            border-radius: 0 0 10px 0;
        }
    }

    .btn.btn-primary.toggle{
        width: 11rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        /*border-radius: 0;*/
    }

    .btn.btn-primary.toggle.hasHover:hover{
        background-color: #001EC8 !important;
        color: white;
    }

    .btn.btn-primary.toggle.active{
        background-color: #002F6D;
    }

    .btn.btn-primary.toggle {
        background-color: gray;
    }

    .domotics-device-config-general-settings.schedules .scenario-config-schedules .schedule-line .input-group-addon{
        height: 35px;
        display: -webkit-inline-box;
    }

    .input-group.clockpicker .input-group-addon{
        display: -webkit-inline-box;
        width: 40px;
    }
    .input.clockpicker input,
    .input.clockpicker .input-group-addon{
        display: inline-block;
    }

    .input-group-addon{
        width: 40px;
        display: -webkit-inline-box;
    }


    .picker-wrapper{
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
        margin-right: 10px;
    }


    .animated.domotics-device.effect1.devicePress .card-container.scenario .device-down-simple.scenario i{
        color: white;
    }

    .card-container.scenario .device-down-simple.scenario i {
        float: right;
        padding-right: 10px;
    }
    .domotics-scenario-list-icon.device-upper.executing{
        background-color: #7575FF;
    }

    .domotics-scenario-list-icon.device-upper .scheduled-icon{
        position: absolute;
        left: 12px;
        top: 11px;
        font-size: x-large;
    }

    .device-down.scenario .icon-ic_settings{
        float: right;
        margin: 6px 15px;
    }

    .device-down.scenario{
        padding-top: calc(10% - 12px);
    }

    .animate-show.device-down  i.glyphicon.glyphicon-trash {
        float: right;
        padding: 10px;
    }

    @media screen and (min-width: 401px) and (max-width: 800px) {
        .config-scenario .scenario-info .avatar {
            float: none !important;
            padding-right:0 !important;
            margin-top: 2px !important;
            width:auto;
            display: block !important;
            width: 50% !important;
            text-align: left;
        }
        .config-scenario .scenario-info .name {
            width: 48% !important;
            padding-right: 2% !important;
        }
        .config-scenario .scenario-info .scheduling {
            width: 48% !important;
            padding-right: 2% !important;
        }

    }

    @media screen and (max-width: 400px) {
        .config-scenario .scenario-info .avatar,
        .config-scenario .scenario-info .name,
        .config-scenario .scenario-info .scheduling {
        float: none;
        margin-right:0;
        padding: 2px !important;
        width:auto;
        display: block !important;
        width: 100% !important;
      }
    }

    .config-scenario{
        overflow: auto;
    }

    .config-scenario .domotics-device-config-general-settings{
        height: initial !important;
        padding-bottom: 10px;
    }

    .config-scenario .scenario-config-header,
    .config-scenario .scenario-info,
    .config-scenario .scenario-actions{
            max-width: none !important;
            width: 100%;
    }

    .config-scenario .scenario-config-header{
        font-weight: bold;
        font-size: large;
        display: block;
        max-width: none !important;
        padding-top: 15px;
        text-align: left;
        margin-bottom: 4rem;
    }

    .config-scenario .scenario-config-header strong{
        font-weight: 800;
        font-size: larger;
    }

    .config-scenario .scenario-config-header span{
        font-size: x-large;
    }
    .config-scenario .scenario-config-header i{
        float: right;
        margin-right: 40px;
        font-size: x-large;
        cursor: pointer;
    }


    .config-scenario .scenario-info{
        max-width:100% !important;
        height:50%;
        padding-right: 5rem !important;
        padding-top: 0 !important;
    }

    .config-scenario .scenario-actions{
        max-width:100% !important;
        height:50%;
        width: 100%;
        padding-right: 5rem !important;
        padding-top: 5rem !important;
    }


    .config-scenario .scenario-info .avatar,
    .config-scenario .scenario-info .name,
    .config-scenario .scenario-info .scheduling{
        max-width: none;
        display: inline-block;
        vertical-align: top;
        padding-right: 25px;
    }

    .config-scenario .scenario-info .avatar{
        width: 20%;
        margin-right: 5%;
    }

    .config-scenario .scenario-info .avatar .icon-box{
        width: 15rem;
        cursor: pointer;
        height: 15rem;
        border-style: dashed;
        border-color: #414B4F;
        padding: 1.5em 2em 1.5em 2em;
        padding-bottom: 1.5em;
    }

    .config-scenario .scenario-info .avatar .icon-box i{
        font-size: 5em;
    }

    .config-scenario .scenario-info .name{
        width: 35%;
        padding-right: 65px;
    }

    .config-scenario .scenario-info .name input{
        width: 100%;
    }
    .config-scenario .scenario-info .name label{
        width: 100%;
    }

    .config-scenario .scenario-info .scheduling{
        width: 40%;
    }

    .config-scenario .scenario-info .scheduling .header{
        font-weight: bold;
        cursor: pointer;
    }

    .config-scenario .scenario-info .scheduling .actions{

    }

    .config-scenario .scenario-actions{
        max-width:100% !important;
        height:50%;
        padding-right: 5rem !important;
        padding-top: 5rem !important;
    }
    .config-scenario .scenario-actions .header,
    .config-scenario .scenario-actions .actions,
    .config-scenario .scenario-actions .info{
        max-width: none;
        display: block;
        vertical-align: top;
        padding-right: 25px;
        text-align: left;
    }

    .config-scenario .scenario-actions .info .description{
        font-size: larger;
    }
    .config-scenario .scenario-actions .info .header{
        font-size: large;
        font-weight: bold;
    }
    .config-scenario .scenario-actions .actions{
        display: inline-block;
        float: left;
        padding-top: 10px;
        width: 100%;
    }
    /* Schedule Editing */
    .domotics-device-config-general-settings.schedules .scenario-config-schedules{
        width: 100%;
        float: left;
        padding-top: 0px;
    }

    .domotics-device-config-general-settings.schedules .scenario-config-schedules .sub-header{
        padding-bottom: 10px;
        font-size: larger;
    }
    .domotics-device-config-general-settings.schedules .scenario-config-schedules .schedule-line{
        margin-bottom: 2rem;
    }


    .domotics-device-config-general-settings.schedules .scenario-config-schedules .schedule-line i:nth-of-type(2){
        padding-right: 24px;
        font-size: 29px;
        vertical-align: middle;
    }

    .domotics-device-config-general-settings.schedules .scenario-config-schedules .schedule-line i:nth-of-type(1){
        cursor: pointer;
    }


    .domotics-device-config-general-settings.schedules .scenario-config-schedules .schedule-line input{
        margin-bottom: 4px;
        width: 100px;
    }

    .domotics-device-config-general-settings.schedules .scenario-config-schedules .schedule-line .btn-group{
        margin-left: 2px;
    }

    .domotics-device-config-general-settings.icons .device-display-box.cards-container{
        max-width: none !important;
    }

    .currently-selected{
        box-shadow:0px 0px 41px #13549B
    }

/*****************************************************************************/


/*************************** Toggle Switch ************************/
    .onoffswitch {
        position: relative; width: 53px;
        -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
    }
    .onoffswitch-checkbox {
        display: none;
    }
    .onoffswitch-label {
        display: block; overflow: hidden; cursor: pointer;
        border: 2px solid #999999; border-radius: 10px;
    }
    .onoffswitch-inner {
        display: block; width: 200%; margin-left: -100%;
        transition: margin 0.3s ease-in 0s;
    }
    .onoffswitch-inner:before, .onoffswitch-inner:after {
        display: block; float: left; width: 50%; height: 22px; padding: 0; line-height: 22px;
        font-size: 10px; color: white; font-family: Trebuchet, Arial, sans-serif; font-weight: bold;
        box-sizing: border-box;
    }
    .onoffswitch-inner:before {
        content: "";
        padding-left: 5px;
        background-color: #6AA84F; color: #FFFFFF;
    }
    .onoffswitch-inner:after {
        content: "";
        padding-right: 5px;
        background-color: #BBBBBB; color: #999999;
        text-align: right;
    }
    .onoffswitch-switch {
        height: 26px;
        display: block; width: 23px; margin: -0.0px;
        background: #FFFFFF;
        position: absolute; top: 0; bottom: 0;
        right: 27px;
        border: 2px solid #999999; border-radius: 10px;
        transition: all 0.3s ease-in 0s;
    }
    .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
        margin-left: 0;
    }
    .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
        right: 0px;
    }
/******************************************************************/


.ng-invalid {
    border: 2px solid red !important;
}


.truncate-name-header {
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    max-width:50% !important;
    cursor:default !important;
}

.truncate {
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    max-width:80% !important;
}

.truncate2 {
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    max-width:100% !important;
}


.clear-transitions * {
    -webkit-transition:all linear 0s !important;
    transition:all linear 0s !important;
}

.domotics-header-login {
    min-height: 0 !important;
}

.siblings-break {
    border: 0;
    height: 2px;
    width:100%;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    position:relative;
    top:2rem;
}

.siblings-info {
    width:90%;
    text-align:center;
}

.siblings-info i {
    position:relative;
    top:0.5rem;
    color:#65b2e9;
    padding:0.7rem;
}

.siblings-info span {
    word-wrap:break-word;
    font-weight:bold;
    font-style:italic;
}


.device-scheduling {
    text-align: left;
    margin-top:3rem;
}

.device-scheduling div:first-child span {
    display: inline-block;
    text-align: left;
    font-weight: bold;
}

.device-scheduling div:first-child {
    font-size:larger;
    cursor:pointer;
}


.device-scheduling-on-off {
    display:inline-block;
    width: 127px;
}

.device-scheduling-on-off i {
    margin-left:0.5rem;
    margin-right:0.5rem;
}

.device-scheduling-on-off span {
    margin-right:0.5rem;
}

.device-scheduling-progressive {
    display:inline-block;
    margin-bottom:-2rem;
}

.device-scheduling-progressive > div:first-child {
    width:10rem;
    margin-left:1rem;
    margin-right:1rem;
    cursor:pointer;
}

.device-scheduling-progressive > div:first-child > div:first-child {
    width:10rem;
    margin-left:auto;
    margin-right:auto;
    position:absolute;
}

.click-sched-level {
    width:20%;
    height: 2rem;
    z-index: 2;
    position: relative;
}


.no-trans * {
    -webkit-animation: enter 0s !important;
    animation: enter 0s !important;
    -webkit-transition:all linear 0s !important;
    transition:all linear 0s !important;
}

.no-trans * {
    -webkit-animation: enter 0s ease-out reverse !important;
    animation: enter 0s ease-out reverse !important;
    -webkit-transition:all linear 0s !important;
    transition:all linear 0s !important;
}

.no-trans-single {
    -webkit-animation: enter 0s !important;
    animation: enter 0s !important;
    -webkit-transition:all linear 0s !important;
    transition:all linear 0s !important;
}

.no-trans-single {
    -webkit-animation: enter 0s ease-out reverse !important;
    animation: enter 0s ease-out reverse !important;
    -webkit-transition:all linear 0s !important;
    transition:all linear 0s !important;
}


.no-anim .ng-enter {
    -webkit-animation: enter 0s !important;
    animation: enter 0s !important;
}

.no-anim .ng-leave {
    -webkit-animation: enter 0s ease-out reverse !important;
    animation: enter 0s ease-out reverse !important;
}

.custom-dates-container span {
    padding:0.25rem;
}


.btn-primary:hover {
    color:white !important;
}

.fa-times-thin:before {
    content: '\00d7';
}

.display-usage-container {
    margin-right:4rem;
    cursor:pointer;
}

.domotics-usage-icon {
    position:absolute;
    top:1.2rem;
    color:white;
    font-size:2rem;
    right: 3.4rem;
}

.domotics-displaying-usage {
    position:absolute;
    top:0px;
    right:3.1rem;
    color:white;
    font-size:4rem;
    font-weight:900;
}


/************************* FILE PROCESSING BAR **************************/

.barlittle {
    background-color: #2187e7;
    background-image: -moz-linear-gradient(45deg, #2187e7 25%, #a0eaff);
    background-image: -webkit-linear-gradient(45deg, #2187e7 25%, #a0eaff);
    border-left: 1px solid #111;
    border-top: 1px solid #111;
    border-right: 1px solid #333;
    border-bottom: 1px solid #333;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-left: 5px;
    opacity: 0.1;
    -moz-transform: scale(0.7) !important;
    -webkit-transform: scale(0.7) !important;
    -moz-animation: move 1s infinite linear !important;
    -webkit-animation: move 1s infinite linear !important;
}

#block_1 {
    -moz-animation-delay: .4s !important;
    -webkit-animation-delay: .4s !important;
}

#block_2 {
    -moz-animation-delay: .3s !important;
    -webkit-animation-delay: .3s !important;
}

#block_3 {
    -moz-animation-delay: .2s !important;
    -webkit-animation-delay: .2s !important;
}

#block_4 {
    -moz-animation-delay: .3s !important;
    -webkit-animation-delay: .3s !important;
}

#block_5 {
    -moz-animation-delay: .4s !important;
    -webkit-animation-delay: .4s !important;
}

@-moz-keyframes move {
    0% {
        -moz-transform: scale(1.2) !important;
        opacity: 1 !important;
    }

    100% {
        -moz-transform: scale(0.7);
        opacity: 0.1 !important;
    }
}

@-webkit-keyframes move {
    0% {
        -webkit-transform: scale(1.2);
        opacity: 1 !important;
    }

    100% {
        -webkit-transform: scale(0.7);
        opacity: 0.1 !important;
    }
}


.domotics-file-processing-container {
    position: relative;
    top: 50%;
    width: 30rem;
    opacity: 1 !important;
    z-index: 10000000;
    height:10rem;
    margin: 0 auto;
}

.domotics-file-processing-container * {
    opacity: 1 !important;
}

.domotics-processing-file-info {
    position: relative;
    top: 48%;
    text-align: center;
}

.domotics-processing-file-info span {
    color:black;font-weight: bold;
}

.domotics-background
{
    height:100%;
    width:100%;
    text-align: center;
    background-image: url('../../../images/src/images/back.jpg') !important;
    background-size: 100% 100%;
    font-family: "PT Sans", "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}


.no-data-list {
    margin-top:5%;
    font-size:larger;
    text-align: center;
}

.no-internet-warning {
    font-weight: bold;
    color:red;
}

.eula-terms {
    color: #414b4f;
    text-decoration: underline;
}

.eula-checkbox input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 1;
    background: white;
    height: 33px;
    width: 33px;
    border: 1px solid white;
    margin-right: 10px;
    border-radius: 5px;
    display: inline-block;
}

.eula-checkbox input[type="checkbox"]:checked {
    background: white;
    border-radius: 5px;
    border-color: #414b4f;
}

.eula-checkbox input[type="checkbox"]:hover {
    border-radius: 5px;
}

.eula-checkbox input[type="checkbox"]:disabled {
    background: white;
    opacity: 0.6;
    pointer-events: none;
    border-radius: 5px;
}

.eula-checkbox input[type="checkbox"]:after {
    content: '';
    position: relative;
    border: solid rgb(28, 197, 13);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: none;
    border-radius: 5px;
}

.eula-checkbox input[type="checkbox"]:checked:after {
    width: 4mm;
    height: 7mm;
    left: 25%;
    top: 8%;
    border: solid #6AA84F;
    border-width: 0 2mm 2mm 0;
    -webkit-transform: rotate(40deg);
    -moz-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
    content: "";
    display: block;
}

.eula-checkbox input[type="checkbox"]:disabled:after {
    border-color: white;
    border-radius: 5px;
}

.eula-text {
    position: relative;
    top: -8px;
}

.administrator-toggle {
    margin-bottom: 0px!important;
    margin-top: 0px!important;
}

.associateusertoggle-toggle {
    margin-top: 0px!important;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes cowbell {
    0%    {transform: rotate(0deg);}
    25%   {transform: rotate(40deg);}
    50%   {transform: rotate(0deg);}
    75%   {transform: rotate(-40deg);}
    100%  {transform: rotate(0deg);}
}

/* Standard syntax */
@keyframes cowbell {
    0%    {transform: rotate(0deg);}
    25%   {transform: rotate(40deg);}
    50%   {transform: rotate(0deg);}
    75%   {transform: rotate(-40deg);}
    100%  {transform: rotate(0deg);}
}

.cowbell-animation{
    -webkit-animation-name: cowbell; /* Chrome, Safari, Opera */
    -webkit-animation-duration: 0.45s; /* Chrome, Safari, Opera */
    -webkit-animation-iteration-count: 10; /* Chrome, Safari, Opera */
    animation-name: cowbell;
    animation-duration: 0.45s;
    animation-iteration-count: 10;
}

.circletag > i{
    margin-top: 1rem;
}

/***********************************************************************/

.dismiss {
    opacity: 0.5 !important;
}

.dismissed {
    display:none !important;
}

.bottom-filtering {
    position:fixed!important;
    bottom:0;
    width:100% !important;
    text-align: center;
    left:0;
    padding-bottom:0 !important;
    margin-bottom:0 !important;
    padding-left: 20px;
    height:4rem;
    line-height:4rem;
    -moz-box-shadow:5px 5px 5px rgba(0,0,0,0.3);
    -webkit-box-shadow:5px 5px 5px rgba(0,0,0,0.3);
    box-shadow:5px 5px 5px rgba(0,0,0,0.3);
    background-color:#002f6d;
    opacity: 0.7;
    backdrop-filter: blur(3px);
    /*-webkit-transform: translate3d(0,0,0);*/
    z-index: 10;
}

/*
* iPad
*/
@media only screen
and (min-width: 768px)
and (max-width : 1024px)
and (-webkit-min-device-pixel-ratio: 1)  {

    .graphSliceButtons {
        display:none !important;
    }

    .mob-graphSlice {
        display:inherit !important;
    }

    .mob-graphSlice.mob-graphSlice-select {
        padding-right: 0;
        margin-right: 0.2rem;
        width: 50%;
    }


}

@media only screen
and (min-width : 1px)
and (max-width : 1024px)
and (-webkit-min-device-pixel-ratio: 1)  {

    .list-100 {
        height: auto;
        min-height: 100% !important;
    }

    .list-100-no-heigth {
        height: auto;
    }
}

.dash-header-content-container {
    margin:20px auto !important;
}

.dash-header-content-container > div{
    padding-top: 0;
    color: white;
    display: inline-block;
    padding-left: 0;
    padding-right: 0 !important;
    margin: 0 auto;
    padding-top:20px;
    padding-bottom:20px;
}

div .device-name,
div .device-details-area{
    text-align: left;
}

.section > .device-name{
    margin-bottom: 12px;
    /*padding-top: 30px;*/
}

.section > .device-name > span{
    font-size: 24px;
    font-family: "KelsonSansBold"
}

.custom-list-group-sidebar {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

i.active-icon{
    color: darkgray !important;
}
i.chart-icon{
    color: white !important;
}

.iconCategoryDivider {
    border: 0;
    height: 1px;
    background-image: -webkit-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
    background-image: -moz-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
    background-image: -ms-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
    background-image: -o-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
    margin-top:rem;
    margin-bottom:1rem;
}

@media (max-width: 767px){
    .chart-desktop-only{
        display: none !important;
    }
    .popover {
        top: 10% !important;
        left: 33% !important;
    }

}

@media (max-width:1000px) {
    .login-cloud-info-panel {
        display: none !important;
    }

    .login-sidebar-toggle {
        display: block !important;
    }
}

@media (min-width: 768px){
    .chart-mobile-only{
        display: none !important;
    }
    .popover {
        top: 10% !important;
        left: 33% !important;
    }

}

@media (max-width: 320px) {
    .reserved-efapel {
        display:  none!important;
    }
}


@media screen and (max-height: 360px) {
    .disable-logout-user-bottom {
        bottom: inherit !important;
        z-index: -1;
    }
    .popover {
        top: 10% !important;
        left: 33% !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation:portrait) {
  /* For portrait layouts only */
  .dash-header-today-spacing {
        margin-left:12.5% !important;
        margin-right:12.5% !important;
    }
    /*
    .col-sm-10 {
        width: 100% !important;
    }*/

    .popover {
        top: 20% !important;
        left: 33% !important;
    }
}

.efapel-credits {
    float: left!important;
}

@media (min-width: 1145px) {

    .footer-efapel-text {
        display:inline-block!important;
    }

    .login-footer-item {
        margin-right:10.1vw!important;
    }

}

@media (max-width: 1144px) {

    .footer-efapel-text {
        display:none!important;
    }

    .login-footer-item {
        text-align:right;
        position:absolute;
    }

    .support-footer-item {
        right:90px;
        bottom: 2px;
    }

    .eula-footer-item {
        right:60px;
        bottom: 2px;
    }

    .osi-footer-item {
        right:30px;
        bottom: 2px;
    }

}

.lang-footer-item {
    margin-right:10px!important;
}

/***************WIZARD RATES STEP **************/

.domotics-toggle-no-anim-all * {
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}

.domotics-toggle-no-anim {
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}

.domotics-wizard-step7-periods-container input {
    max-width:100% !important;
    font-family: "KelsonSansBold" !important;
    font-weight: bold !important;
    padding: 5px !important;
}

.domotics-wizard-step7-periods-container span {
    line-height: 30px;
    font-family: "KelsonSansBold";
    font-weight: bold;
}

.rate-config-container {
    max-width:90% !important;
    margin:0 auto !important;
    margin-bottom:1.5rem !important;
}

.rate-config-container .notification-title {
    background-color:#3168ff !important;
    color: white !important;
    cursor:pointer;
}

.rate-config-container .notification-body {
    background-color:white !important;
}

@media all and (min-width: 768px) {
    .ratingConfig-line * {
        display:inline-block !important;
    }
}

.ratingConfig-line span.info {
    line-height: 35px;
}

.rating-time-config-input {
    height: 32px;
    /*width:50%;*/
    font-family: "KelsonSansBold";
    font-weight: bold;
    padding: 5px;
}

.domotics-select {
    margin-bottom: 20px;
    height: 35px;
    font-family: "KelsonSansBold";
    font-weight: bold;
    padding: 5px;
    width:100%;
}
/**********************************************/

.loading * {
    visibility: hidden !important;
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
}

.loading {
    position: relative;
    background:url("../../../images/src/images/ring.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-color:white;
    margin-top:50px;
}

/* Transparent Overlay */
.loading:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.in-front {
    z-index:100 !important;
    position: relative;
}

.on-back{
     z-index:0 !important;
}

.scrollable-content {
    overflow: auto;
}
.scrollable-sidebar-device {
    overflow: hidden;
}

.btn {
    pointer-events: auto !important;
}

.scrollable-config-overlay {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.mac-address {
    width: calc(100% - 1rem) !important;
}

.no-click-div {
    pointer-events:none;
}

.d3-translate {
    -webkit-transform: translate3d(0,0,0);
}

.popover {
    top: 30% !important;
    left: 37% !important;
}

.highcharts-container {
    width:100% !important;
    height:400px !important;
}

.toast-top-center {
    -webkit-transform: translate3d(0,0,0);
}

input[type="time"]::-webkit-clear-button {
    display: none;
}
.title-overflow {
    display:inherit;
    width:100%;
    white-space: nowrap;
    overflow:hidden !important;
    text-overflow: ellipsis;
}
label > * {
  pointer-events:none;
}
.bottom-wrapper {
    position: fixed;
}
.bottom-filtering-ios {
  position: -webkit-sticky;
}
.scheduling-buttons {
    width: 97.5%; /*lel*/
    margin-top: 3rem;
}
.logout-text{
    margin-left: 5px;
}
.device-type-wizard-name {
    font-size: 1.2rem;
}
.device-name-wizard {
    padding-top: 0px !important;
}
.import-export-div {
    margin-top: 5px;
    margin-bottom: 10px;
}
.step-8-btn {
    padding-bottom: 5px
}
.import-export-button {
    width: 85%;
}

.step-4-form-group {
    margin-left: 26%;
}

.domotics-ap-login {
    margin-left: 1.8rem;
    margin-right: 1.8rem;
    font-weight: bold;
}

.message-config{
    font-family: 'KelsonSans';
    margin-left: auto;
    margin-right: auto;
    border-radius: 3px;
    padding: 4px 14px 4px 14px;
     box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-style: solid;
    border-width: 1px;
    text-align: left;
}

.message-config.error{
    background-color: #FCF1F0;
    color: #D9534F;
    border-color: #D9534F;
}

.login-sidebar-toggle {
    position: absolute;
    margin-top: 2.3rem;
    color: white;
    font-size:3rem;
    text-shadow: 2px 2px #414b4f;
    display:none;
}

.domotics-homeserver-list-container {
    background-color: rgba(255, 255, 255, 0.8);;
}

.cloud-sidebar {
    position:fixed;
    width:250px;
    background-color: white;
    z-index:1000;
    transition: 3s;
}

.cloud-sidebar-item {
    width:220px!important;
}

.sidebar-backdrop {
    position:fixed;
    display:block;
    width: 100%;
    height: 100%;
    background-color:rgba(0, 0, 0, 0.75);
    margin-top: 24px;
    z-index: 999;
}

.login-footer {
    border-bottom:none!important;
}

.eula-editable {
    overflow-y: auto;
    outline: auto;
    height:40vh;
    text-align:left;
    padding: 7px;
}

.eula-editable-container {
    height: 40vh;
}

.eula-editable-container h1,
.eula-editable-container h2,
.eula-editable-container h3,
.eula-editable-container h4,
.eula-editable-container h5 {
    color:#15569B!important;
}

.eula-modal-header {
    background-color: rgba(0, 47, 109,0.8);
    color: white;
}

.efapel-lang-menu-item {
    background-color: rgba(0, 47, 109,0.8);
    color: white;
}

.efapel-lang-menu-item-text {
    color: lightgray;
}

.efapel-lang-menu {
    position:fixed;
    bottom:40px;
    right:0px;
}

.efapel-badge {
    background-color: white!important;
    color: #337ab7!important;
    margin-right:3px;
}

.remember-me {
    transform: scale(1.4);
    margin-left: 10px!important;
}
