@font-face {
    font-family: 'KelsonSans';
    src:url('../fonts/KelsonSans/Regular/Kelson_Sans_Regular.eot');
    src:url('../fonts/KelsonSans/Regular/Kelson_Sans_Regular.eot') format('embedded-opentype'),
    url('../fonts/KelsonSans/Regular/Kelson_Sans_Regular.ttf') format('truetype'),
    url('../fonts/KelsonSans/Regular/Kelson_Sans_Regular.woff') format('woff'),
    url('../fonts/KelsonSans/Regular/Kelson_Sans_Regular.svg') format('svg');
}

@font-face {
    font-family: 'KelsonSansLight';
    src:url('../fonts/KelsonSans/Light/Kelson_Sans_Light.eot');
    src:url('../fonts/KelsonSans/Light/Kelson_Sans_Light.eot') format('embedded-opentype'),
    url('../fonts/KelsonSans/Light/Kelson_Sans_Light.ttf') format('truetype'),
    url('../fonts/KelsonSans/Light/Kelson_Sans_Light.woff') format('woff'),
    url('../fonts/KelsonSans/Light/Kelson_Sans_Light.svg') format('svg');
}

@font-face {
    font-family: 'KelsonSansBold';
    src:url('../fonts/KelsonSans/Bold/Kelson_Sans_Bold.eot');
    src:url('../fonts/KelsonSans/Bold/Kelson_Sans_Bold.eot') format('embedded-opentype'),
    url('../fonts/KelsonSans/Bold/Kelson_Sans_Bold.ttf') format('truetype'),
    url('../fonts/KelsonSans/Bold/Kelson_Sans_Bold.woff') format('woff'),
    url('../fonts/KelsonSans/Bold/Kelson_Sans_Bold.svg') format('svg');
}


@font-face {
    font-family: 'Domus40';
    src:    url('../fonts/Domus40/Domus40.eot?3aybjv');
    src:    url('../fonts/Domus40/Domus40.eot?3aybjv#iefix') format('embedded-opentype'),
    url('../fonts/Domus40/Domus40.ttf?3aybjv') format('truetype'),
    url('../fonts/Domus40/Domus40.woff?3aybjv') format('woff'),
    url('../fonts/Domus40/Domus40.svg?3aybjv#Domus40') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Domus40' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-icones_ic_categoria_botoes_quadruplo_04:before {
  content: "\e924";
}
.icon-icones_ic_categoria_botoes_quadruplo_01:before {
  content: "\e925";
}
.icon-icones_ic_categoria_botoes_quadruplo_02:before {
  content: "\e969";
}
.icon-icones_ic_categoria_botoes_quadruplo_03:before {
  content: "\e96a";
}
.icon-icones_ic_dvd:before {
    content: "\e968";
}
.icon-icon_icones-02:before {
    content: "\e97c";
}
.icon-icon_icones-04:before {
    content: "\e97d";
}
.icon-icon_icones-05:before {
    content: "\e97e";
}
.icon-icon_icones-06:before {
    content: "\e97f";
}
.icon-icon_icones-07:before {
    content: "\e980";
}
.icon-icon_icones-08:before {
    content: "\e981";
}
.icon-icon_icones-09:before {
    content: "\e982";
}
.icon-icon_icones-10:before {
    content: "\e983";
}
.icon-icon_icones-11:before {
    content: "\e984";
}
.icon-icon_icones-12:before {
    content: "\e985";
}
.icon-icon_icones-13:before {
    content: "\e986";
}
.icon-icon_icones-14:before {
    content: "\e987";
}
.icon-icon_icones-16:before {
    content: "\e988";
}
.icon-icon_icones-17:before {
    content: "\e989";
}
.icon-icon_icones-19:before {
    content: "\e98a";
}
.icon-icon_icones-20:before {
    content: "\e98b";
}
.icon-icon_icones-22:before {
    content: "\e98c";
}
.icon-icon_icones-01:before {
    content: "\e98d";
}
.icon-icon_icones-23:before {
    content: "\e98e";
}
.icon-icon_icones-30:before {
    content: "\e98f";
}
.icon-icon_icones-32:before {
    content: "\e990";
}
.icon-icon_icones-33:before {
    content: "\e991";
}
.icon-icon_icones-34:before {
    content: "\e992";
}
.icon-icon_icones-35:before {
    content: "\e993";
}
.icon-icon_icones-36:before {
    content: "\e994";
}
.icon-icon_icones-37:before {
    content: "\e995";
}
.icon-icon_icones-38:before {
    content: "\e996";
}
.icon-icon_icones-39:before {
    content: "\e997";
}
.icon-icon_icones-40:before {
    content: "\e998";
}
.icon-icon_icones-41:before {
    content: "\e999";
}
.icon-icon_icones-42:before {
    content: "\e99a";
}
.icon-icon_icones-31:before {
    content: "\e99b";
}
.icon-icon_icones-03:before {
    content: "\e99c";
}
.icon-icon_icones-15:before {
    content: "\e99d";
}
.icon-icon_icones-18:before {
    content: "\e99e";
}
.icon-icon_icones-21:before {
    content: "\e99f";
}
.icon-icon_icones-25:before {
    content: "\e9a0";
}
.icon-icon_icones-26:before {
    content: "\e9a1";
}
.icon-icon_icones-27:before {
    content: "\e9a2";
}
.icon-icon_icones-28:before {
    content: "\e9a3";
}
.icon-icon_icones-24:before {
    content: "\e9a4";
}
.icon-icon_icones-29:before {
    content: "\e9a5";
}
.icon-icones_ic_area:before {
    content: "\e97b";
}
.icon-fa-angle-right:before {
    content: "\e900";
}
.icon-fa-bar-chart:before {
    content: "\e901";
}
.icon-fa-bars:before {
    content: "\e902";
}
.icon-fa-bell:before {
    content: "\e903";
}
.icon-fa-checkbox-check:before {
    content: "\e904";
}
.icon-fa-checkbox:before {
    content: "\e905";
}
.icon-fa-chevron-left:before {
    content: "\e906";
}
.icon-fa-chevron-right:before {
    content: "\e907";
}
.icon-fa-clock-o:before {
    content: "\e908";
}
.icon-fa-close:before {
    content: "\e909";
}
.icon-fa-exchange:before {
    content: "\e90a";
}
.icon-fa-info-circle:before {
    content: "\e90b";
}
.icon-fa-plug:before {
    content: "\e90c";
}
.icon-fa-sitemap:before {
    content: "\e90d";
}
.icon-fa-sort:before {
    content: "\e90e";
}
.icon-fa-toggle-off:before {
    content: "\e90f";
}
.icon-fa-toggle-on:before {
    content: "\e910";
}
.icon-fa-trash:before {
    content: "\e911";
}
.icon-fa-user-circle:before {
    content: "\e912";
}
.icon-ic_calendar_day:before {
    content: "\e913";
}
.icon-ic_calendar_month:before {
    content: "\e914";
}
.icon-ic_clear_notifications:before {
    content: "\e915";
}
.icon-ic_home:before {
    content: "\e916";
}
.icon-ic_level_empty:before {
    content: "\e917";
}
.icon-ic_level_full:before {
    content: "\e918";
}
.icon-ic_minus:before {
    content: "\e919";
}
.icon-ic_notifications:before {
    content: "\e91a";
}
.icon-ic_plus:before {
    content: "\e91b";
}
.icon-ic_power:before {
    content: "\e91c";
}
.icon-ic_search:before {
    content: "\e91d";
}
.icon-ic_settings:before {
    content: "\e91e";
}

.icon-icones_ic_administrador:before {
    content: "\e91f";
}
.icon-icones_ic_cabo:before {
    content: "\e920";
}
.icon-icones_ic_categoria_bateria:before {
    content: "\e921";
}
.icon-icones_ic_categoria_botoes_quadruplo_sem_fio:before {
    content: "\e922";
}
.icon-icones_ic_categoria_botoes_quadruplo:before {
    content: "\e923";
}
.icon-icones_ic_categoria_contador:before {
    content: "\e926";
}
.icon-icones_ic_categoria_detetor_movimento:before {
    content: "\e927";
}
.icon-icones_ic_categoria_interruptores:before {
    content: "\e928";
}
.icon-icones_ic_categoria_persianas:before {
    content: "\e929";
}
.icon-icones_ic_categoria_quadro_eletrico:before {
    content: "\e92a";
}
.icon-icones_ic_categoria_reguladores_luz:before {
    content: "\e92b";
}
.icon-icones_ic_categoria_tomadas_embeber:before {
    content: "\e92c";
}
.icon-icones_ic_categoria_tomadas_salientes:before {
    content: "\e92d";
}
.icon-icones_ic_cenario_abrir_persianas:before {
    content: "\e92e";
}
.icon-icones_ic_cenario_acordar:before {
    content: "\e92f";
}
.icon-icones_ic_cenario_apresentacao:before {
    content: "\e930";
}
.icon-icones_ic_cenario_cinema:before {
    content: "\e931";
}
.icon-icones_ic_cenario_criancas:before {
    content: "\e932";
}
.icon-icones_ic_cenario_dentro_casa:before {
    content: "\e933";
}
.icon-icones_ic_cenario_dia:before {
    content: "\e934";
}
.icon-icones_ic_cenario_dormir:before {
    content: "\e935";
}
.icon-icones_ic_cenario_economico:before {
    content: "\e936";
}
.icon-icones_ic_cenario_fechar_persianas:before {
    content: "\e937";
}
.icon-icones_ic_cenario_ferias:before {
    content: "\e938";
}
.icon-icones_ic_cenario_festa:before {
    content: "\e939";
}
.icon-icones_ic_cenario_fitness:before {
    content: "\e93a";
}
.icon-icones_ic_cenario_fora_casa:before {
    content: "\e93b";
}
.icon-icones_ic_cenario_musica:before {
    content: "\e93c";
}
.icon-icones_ic_cenario_noite:before {
    content: "\e93d";
}
.icon-icones_ic_cenario_refeicao:before {
    content: "\e93e";
}
.icon-icones_ic_cenario_romantico:before {
    content: "\e93f";
}
.icon-icones_ic_cenario_tempestade:before {
    content: "\e940";
}
.icon-icones_ic_cenario_visitas:before {
    content: "\e941";
}
.icon-icones_ic_conforto_aquecedor:before {
    content: "\e942";
}
.icon-icones_ic_conforto_ar_condicionado:before {
    content: "\e943";
}
.icon-icones_ic_conforto_bomba_calor:before {
    content: "\e944";
}
.icon-icones_ic_conforto_cilindro:before {
    content: "\e945";
}
.icon-icones_ic_conforto_esquentador:before {
    content: "\e946";
}
.icon-icones_ic_conforto_recuperador_calor:before {
    content: "\e947";
}
.icon-icones_ic_conforto_ventoinha:before {
    content: "\e948";
}
.icon-icones_ic_dispositivos:before {
    content: "\e949";
}
.icon-icones_ic_eletro_arca:before {
    content: "\e94a";
}
.icon-icones_ic_eletro_cafeteira:before {
    content: "\e94b";
}
.icon-icones_ic_eletro_forno:before {
    content: "\e94c";
}
.icon-icones_ic_eletro_frigorifico:before {
    content: "\e94d";
}
.icon-icones_ic_eletro_maquina_cafe:before {
    content: "\e94e";
}
.icon-icones_ic_eletro_maquina_louca:before {
    content: "\e94f";
}
.icon-icones_ic_eletro_maquina_roupa:before {
    content: "\e950";
}
.icon-icones_ic_eletro_maquina_secar:before {
    content: "\e951";
}
.icon-icones_ic_eletro_microondas:before {
    content: "\e952";
}
.icon-icones_ic_eletro_placa_fogao:before {
    content: "\e953";
}
.icon-icones_ic_eletro_robot_cozinha:before {
    content: "\e954";
}
.icon-icones_ic_eletro_torradeira:before {
    content: "\e955";
}
.icon-icones_ic_exterior_portao_eletrico:before {
    content: "\e956";
}
.icon-icones_ic_exterior_portao_garagem:before {
    content: "\e957";
}
.icon-icones_ic_exterior_rega:before {
    content: "\e958";
}
.icon-icones_ic_exterior_toldo:before {
    content: "\e959";
}
.icon-icones_ic_ilum_bebe:before {
    content: "\e95a";
}
.icon-icones_ic_ilum_candeeiro_cabeceira:before {
    content: "\e95b";
}
.icon-icones_ic_ilum_candeeiro_escritorio:before {
    content: "\e95c";
}
.icon-icones_ic_ilum_candeeiro_parede:before {
    content: "\e95d";
}
.icon-icones_ic_ilum_candeeiro_pe:before {
    content: "\e95e";
}
.icon-icones_ic_ilum_candeeiro_rua:before {
    content: "\e95f";
}
.icon-icones_ic_ilum_candeeiro_teto:before {
    content: "\e960";
}
.icon-icones_ic_ilum_escada:before {
    content: "\e961";
}
.icon-icones_ic_ilum_exterior:before {
    content: "\e962";
}
.icon-icones_ic_ilum_lampada1:before {
    content: "\e963";
}
.icon-icones_ic_ilum_lampada2:before {
    content: "\e964";
}
.icon-icones_ic_ilum_piscina:before {
    content: "\e965";
}
.icon-icones_ic_multimedia_box:before {
    content: "\e966";
}
.icon-icones_ic_multimedia_consola_jogos:before {
    content: "\e967";
}
.icon-icones_ic_multimedia_hifi:before {
    content: "\e96e";
}
.icon-icones_ic_multimedia_impressora:before {
    content: "\e96f";
}
.icon-icones_ic_multimedia_notebook:before {
    content: "\e970";
}
.icon-icones_ic_multimedia_pc:before {
    content: "\e971";
}
.icon-icones_ic_multimedia_router:before {
    content: "\e972";
}
.icon-icones_ic_multimedia_tv:before {
    content: "\e973";
}
.icon-icones_ic_persiana_0:before {
    content: "\e974";
}
.icon-icones_ic_persiana_25:before {
    content: "\e975";
}
.icon-icones_ic_persiana_50:before {
    content: "\e976";
}
.icon-icones_ic_persiana_75:before {
    content: "\e977";
}
.icon-icones_ic_persiana_100:before {
    content: "\e978";
}
.icon-icones_ic_utilizador:before {
    content: "\e979";
}
.icon-icones_ic_wifi:before {
    content: "\e97a";
}
.icon-icones-espelhos-multiplos-19:before {
  content: "\e9a7";
}
.icon-icones-espelhos-multiplos-18:before {
  content: "\e9a8";
}
.icon-icones-espelhos-multiplos-17:before {
  content: "\e9a9";
}
.icon-icones-espelhos-multiplos-16:before {
  content: "\e9aa";
}
.icon-icones-espelhos-multiplos-15:before {
  content: "\e9ab";
}
.icon-icones-espelhos-multiplos-14:before {
  content: "\e9ac";
}
.icon-icones-espelhos-multiplos-13:before {
  content: "\e9ad";
}
.icon-icones-espelhos-multiplos-12:before {
  content: "\e9ae";
}
.icon-icones-espelhos-multiplos-11:before {
  content: "\e9af";
}
.icon-icones-espelhos-multiplos-10:before {
  content: "\e9b0";
}
.icon-icones-espelhos-multiplos-09:before {
  content: "\e9b1";
}
.icon-icones-espelhos-multiplos-08:before {
  content: "\e9b2";
}
.icon-icones-espelhos-multiplos-07:before {
  content: "\e9b3";
}
.icon-icones-espelhos-multiplos-06:before {
  content: "\e9b4";
}
.icon-icones-espelhos-multiplos-05:before {
  content: "\e9b5";
}
.icon-icones-espelhos-multiplos-04:before {
  content: "\e9b6";
}
.icon-icones-espelhos-multiplos-03:before {
  content: "\e9b7";
}
.icon-icones-espelhos-multiplos-02:before {
  content: "\e9b8";
}
.icon-icones-espelhos-multiplos-01:before {
  content: "\e9b9";
}