a:hover {
  outline: 0;
}

a:hover {
  color: #0055b3;
  text-decoration: underline;
}

a.text-primary:hover {
  color: #0062cc;
}

a.text-success:hover {
  color: #2b542c;
}

a.text-info:hover {
  color: #245269;
}

a.text-warning:hover {
  color: #66512c;
}

a.text-danger:hover {
  color: #843534;
}

a.bg-primary:hover {
  background-color: #0062cc;
}

a.bg-success:hover {
  background-color: #c1e2b3;
}

a.bg-info:hover {
  background-color: #afd9ee;
}

a.bg-warning:hover {
  background-color: #f7ecb5;
}

a.bg-danger:hover {
  background-color: #e4b9b9;
}

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}

@media screen and (max-width: 767px) {

}

.btn:hover {
  color: #333333;
  text-decoration: none;
}

.btn-default:hover {
  color: #333333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover {
  color: #333333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover {
  background-color: #ffffff;
  border-color: #cccccc;
}

.btn-primary:hover {
  color: #ffffff;
  background-color: #0062cc;
  border-color: #0051a8;
}

.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover {
  color: #ffffff;
  background-color: #0051a8;
  border-color: #003166;
}

.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover {
  background-color: #007aff;
  border-color: #006ee6;
}

.btn-success:hover {
  color: #ffffff;
  background-color: #2ac845;
  border-color: #24aa3b;
}

.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover {
  color: #ffffff;
  background-color: #24aa3b;
  border-color: #197328;
}

.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover {
  background-color: #4cd964;
  border-color: #37d552;
}

.btn-info:hover {
  color: #ffffff;
  background-color: #218ebd;
  border-color: #1b779e;
}

.btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover {
  color: #ffffff;
  background-color: #1b779e;
  border-color: #114c66;
}

.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover {
  background-color: #34aadc;
  border-color: #249ed2;
}

.btn-warning:hover {
  color: #ffffff;
  background-color: #cca300;
  border-color: #a88700;
}

.btn-warning:active:hover,
.btn-warning.active:hover,
.open > .dropdown-toggle.btn-warning:hover {
  color: #ffffff;
  background-color: #a88700;
  border-color: #665200;
}

.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover {
  background-color: #ffcc00;
  border-color: #e6b800;
}

.btn-danger:hover {
  color: #ffffff;
  background-color: #fc0d00;
  border-color: #d80b00;
}

.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover {
  color: #ffffff;
  background-color: #d80b00;
  border-color: #960800;
}

.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover {
  background-color: #ff3b30;
  border-color: #ff2317;
}

.btn-link:hover {
  border-color: transparent;
}

.btn-link:hover {
  color: #0055b3;
  text-decoration: underline;
  background-color: transparent;
}

.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover {
  color: #777777;
  text-decoration: none;
}

.dropdown-menu > li > a:hover {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}

.dropdown-menu > .active > a:hover {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  background-color: #007aff;
}

.dropdown-menu > .disabled > a:hover {
  color: #777777;
}

.dropdown-menu > .disabled > a:hover {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: not-allowed;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 2;
}

.input-group-btn > .btn:hover {
  z-index: 2;
}

.nav > li > a:hover {
  text-decoration: none;
  background-color: #eeeeee;
}

.nav > li.disabled > a:hover {
  color: #777777;
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed;
}

.nav .open > a:hover {
  background-color: #eeeeee;
  border-color: #007aff;
}

.nav-tabs > li > a:hover {
  border-color: #eeeeee #eeeeee #dddddd;
}

.nav-tabs > li.active > a:hover {
  color: #555555;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-bottom-color: transparent;
  cursor: default;
}

.nav-tabs.nav-justified > .active > a:hover {
  border: 1px solid #dddddd;
}

.nav-pills > li.active > a:hover {
  color: #ffffff;
  background-color: #007aff;
}

.nav-tabs-justified > .active > a:hover {
  border: 1px solid #dddddd;
}

.navbar-brand:hover {
  text-decoration: none;
}

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu > li > a:hover {
    background-image: none;
  }
}

@media (max-width: 767px) {

}

.navbar-default .navbar-brand:hover {
  color: #0062cc;
  background-color: transparent;
}

.navbar-default .navbar-nav > li > a:hover {
  color: #006ee6;
  background-color: transparent;
}

.navbar-default .navbar-nav > .active > a:hover {
  color: #1a87ff;
  background-color: #e6e6e6;
}

.navbar-default .navbar-nav > .disabled > a:hover {
  color: #66afff;
  background-color: transparent;
}

.navbar-default .navbar-toggle:hover {
  background-color: #dddddd;
}

.navbar-default .navbar-nav > .open > a:hover {
  background-color: #e6e6e6;
  color: #1a87ff;
}

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #006ee6;
    background-color: transparent;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover {
    color: #1a87ff;
    background-color: #e6e6e6;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover {
    color: #66afff;
    background-color: transparent;
  }
}

.navbar-default .navbar-link:hover {
  color: #006ee6;
}

.navbar-default .btn-link:hover {
  color: #006ee6;
}

.navbar-default .btn-link[disabled]:hover,
fieldset[disabled] .navbar-default .btn-link:hover {
  color: #66afff;
}

.navbar-inverse .navbar-brand:hover {
  color: #ffffff;
  background-color: transparent;
}

.navbar-inverse .navbar-nav > li > a:hover {
  color: #ffffff;
  background-color: transparent;
}

.navbar-inverse .navbar-nav > .active > a:hover {
  color: #ffffff;
  background-color: #080808;
}

.navbar-inverse .navbar-nav > .disabled > a:hover {
  color: #444444;
  background-color: transparent;
}

.navbar-inverse .navbar-toggle:hover {
  background-color: #333333;
}

.navbar-inverse .navbar-nav > .open > a:hover {
  background-color: #080808;
  color: #ffffff;
}

@media (max-width: 767px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #ffffff;
    background-color: transparent;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover {
    color: #ffffff;
    background-color: #080808;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover {
    color: #444444;
    background-color: transparent;
  }
}

.navbar-inverse .navbar-link:hover {
  color: #ffffff;
}

.navbar-inverse .btn-link:hover {
  color: #ffffff;
}

.navbar-inverse .btn-link[disabled]:hover,
fieldset[disabled] .navbar-inverse .btn-link:hover {
  color: #444444;
}

.pager li > a:hover {
  text-decoration: none;
  background-color: #eeeeee;
}

.pager .disabled > a:hover {
  color: #777777;
  background-color: #ffffff;
  cursor: not-allowed;
}

a.label:hover {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

.label-default[href]:hover {
  background-color: #5e5e5e;
}

.label-primary[href]:hover {
  background-color: #0062cc;
}

.label-success[href]:hover {
  background-color: #2ac845;
}

.label-info[href]:hover {
  background-color: #218ebd;
}

.label-warning[href]:hover {
  background-color: #cca300;
}

.label-danger[href]:hover {
  background-color: #fc0d00;
}

a.badge:hover {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

a.thumbnail:hover {
  border-color: #007aff;
}

a.list-group-item:hover,
button.list-group-item:hover {
  text-decoration: none;
  color: #555555;
  background-color: #f5f5f5;
}

.list-group-item.disabled:hover {
  background-color: #eeeeee;
  color: #777777;
  cursor: not-allowed;
}

.list-group-item.disabled:hover .list-group-item-heading {
  color: inherit;
}

.list-group-item.disabled:hover .list-group-item-text {
  color: #777777;
}

.list-group-item.active:hover {
  z-index: 2;
  color: #ffffff;
  background-color: #007aff;
  border-color: #007aff;
}

.list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading > small,
.list-group-item.active:hover .list-group-item-heading > .small {
  color: inherit;
}

.list-group-item.active:hover .list-group-item-text {
  color: #cce4ff;
}

a.list-group-item-success:hover,
button.list-group-item-success:hover {
  color: #3c763d;
  background-color: #d0e9c6;
}

a.list-group-item-success.active:hover,
button.list-group-item-success.active:hover {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d;
}

a.list-group-item-info:hover,
button.list-group-item-info:hover {
  color: #31708f;
  background-color: #c4e3f3;
}

a.list-group-item-info.active:hover,
button.list-group-item-info.active:hover {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}

a.list-group-item-warning:hover,
button.list-group-item-warning:hover {
  color: #8a6d3b;
  background-color: #faf2cc;
}

a.list-group-item-warning.active:hover,
button.list-group-item-warning.active:hover {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b;
}

a.list-group-item-danger:hover,
button.list-group-item-danger:hover {
  color: #a94442;
  background-color: #ebcccc;
}

a.list-group-item-danger.active:hover,
button.list-group-item-danger.active:hover {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442;
}

.close:hover {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.carousel-control:hover {
  outline: 0;
  color: #ffffff;
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

@media (max-width: 767px) {

}

@media (max-width: 767px) {

}

@media (max-width: 767px) {

}

@media (max-width: 767px) {

}

@media (max-width: 767px) {

}

.btn.active:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-group > .btn-default.active:hover {
  color: #ffffff;
  background-color: #007aff;
  border-color: #006ee6;
}

.navbar-app .btn-navbar:hover,
.navbar-app .btn:hover {
  color: #007aff;
}