[overlay],
[data-overlay] {
  display: none;
}
.overlay {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.overlay .overlay-inner {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.overlay .overlay-inner > .overlay-background {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: -5px -5px -5px -5px;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1049;
}
.overlay .overlay-inner > .overlay-dismiss {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1051;
  font-size: 24px;
}
.overlay .overlay-inner > .overlay-content {
  width: 100%;
  box-sizing: border-box;
  display: table;
  background: transparent;
  text-align: center;
  color: #000000;
  height: 100%;
  z-index: 1050;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 20px;
}
.overlay .overlay-inner > .overlay-content > .overlay-body {
  display: table-cell;
  vertical-align: middle;
}
.overlay-show {
  display: block;
}
