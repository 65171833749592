@charset "UTF-8";
/**
   * INDEX OF WIT Animation Kit
   * A set of CSS3 Animations using Keyframes.
   * Use on Web Projects
   *
   * HOW TO START:
   *  Import this file into your SCSS project.
   *  Put Comments the type of animations that you DO NOT want to include on your CSS.
   *
   *  Or @include specific mixins in a class of yours:
   *    (e.g. @include "bounces/bounceIn" in a class of yours);
   *
   *    .button{
   *      @include fadeInUp;
   *    }
   *
   *     section {
   *       @include fadeInDown($duration: 180ms);
   *     }
   *
   * @requires: Sass 3.4 or greater
   *
   * @version:  1.0 (March 22nd, 2015)
   *
   * @author:   João Dias
   * @email:    joao.dias@wit-software.com
   *
   */
/**
 * Specifies how many seconds or milliseconds an animation takes to complete one cycle.
 * @type integer
 * @group Animations
 */
/**
 * Specifies when the animation will start.
 * @type integer
 * @group Animations
 */
/**
 * Describes how the animation will progress over one cycle of its duration.
 * @type integer
 * @group Animations
 */
/**
 * Specifies if the effects of an animation are before the animation starts and after it ends.
 * @type integer
 * @group Animations
 */
/**
 * Determines whether or not a transformed element is visible when it is not facing the screen.
 * @type integer
 * @group Animations
 */
/**
 * Determines whether or not a transformed element is visible when it is not facing the screen.
 * @type integer
 * @group Animations
 */
/**
 * Defaults the transition of all elements to 200ms duration
 * with an ease-in time function.
**/
* {
  -webkit-transition: all 120ms ease-in-out;
  -moz-transition: all 120ms ease-in-out;
  transition: all 120ms ease-in-out;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
  }
}

@-ms-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -ms-transform: translateY(0);
  }
  40% {
    -ms-transform: translateY(-30px);
  }
  60% {
    -ms-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -o-transform: translateY(0);
  }
  40% {
    -o-transform: translateY(-30px);
  }
  60% {
    -o-transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

/**
 * An element bounces on it's position
 *  @type mixin
 * @param $duration - the duration of the animation
 * @param $delay - the delay of the animation
 * @param $function - the timing function of the animation
 * @param $fill-mode - the element fill mode during the animation
 * @param $visibility - Backface visibility
 * @group animations
 * @author  João Dias
 * company:  WIT Software
 * date:  August 31st, 2014
 */
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
  }
  70% {
    -webkit-transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -moz-transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -moz-transform: scale(1.05);
  }
  70% {
    -moz-transform: scale(0.9);
  }
  100% {
    -moz-transform: scale(1);
  }
}

@-ms-keyframes bounceIn {
  0% {
    opacity: 0;
    -ms-transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -ms-transform: scale(1.05);
  }
  70% {
    -ms-transform: scale(0.9);
  }
  100% {
    -ms-transform: scale(1);
  }
}

@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -o-transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -o-transform: scale(1.05);
  }
  70% {
    -o-transform: scale(0.9);
  }
  100% {
    -o-transform: scale(1);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

/**
 * An element fades and bounces in, from zero opacity to the final opacity state
 *  @type mixin
 * @param $duration - the duration of the animation
 * @param $delay - the delay of the animation
 * @param $function - the timing function of the animation
 * @param $fill-mode - the element fill mode during the animation
 * @param $visibility - Backface visibility
 * @group animations
 * @author  João Dias
 * company:  WIT Software
 * date:  August 31st, 2014
 */
@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-200px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(20px);
  }
  80% {
    -webkit-transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-200px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateY(20px);
  }
  80% {
    -moz-transform: translateY(-10px);
  }
  100% {
    -moz-transform: translateY(0);
  }
}

@-ms-keyframes bounceInDown {
  0% {
    opacity: 0;
    -ms-transform: translateY(-200px);
  }
  60% {
    opacity: 1;
    -ms-transform: translateY(20px);
  }
  80% {
    -ms-transform: translateY(-10px);
  }
  100% {
    -ms-transform: translateY(0);
  }
}

@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-200px);
  }
  60% {
    opacity: 1;
    -o-transform: translateY(20px);
  }
  80% {
    -o-transform: translateY(-10px);
  }
  100% {
    -o-transform: translateY(0);
  }
}

@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  60% {
    opacity: 1;
    transform: translateY(20px);
  }
  80% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

/**
 * An element fades and bounces in from the top of the page, and from zero opacity to the final opacity state
 *  @type mixin
 * @param $duration - the duration of the animation
 * @param $delay - the delay of the animation
 * @param $function - the timing function of the animation
 * @param $fill-mode - the element fill mode during the animation
 * @param $visibility - Backface visibility
 * @group animations
 * @author  João Dias
 * company:  WIT Software
 * date:  August 31st, 2014
 */
@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes bounceInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateY(-30px);
  }
  80% {
    -moz-transform: translateY(10px);
  }
  100% {
    -moz-transform: translateY(0);
  }
}

@-ms-keyframes bounceInUp {
  0% {
    opacity: 0;
    -ms-transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -ms-transform: translateY(-30px);
  }
  80% {
    -ms-transform: translateY(10px);
  }
  100% {
    -ms-transform: translateY(0);
  }
}

@-o-keyframes bounceInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateY(-30px);
  }
  80% {
    -o-transform: translateY(10px);
  }
  100% {
    -o-transform: translateY(0);
  }
}

@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    transform: translateY(-30px);
  }
  80% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

/**
 * An element fades and bounces in from the bottom of the page, and from zero opacity to the final opacity state
 *  @type mixin
 * @param $duration - the duration of the animation
 * @param $delay - the delay of the animation
 * @param $function - the timing function of the animation
 * @param $fill-mode - the element fill mode during the animation
 * @param $visibility - Backface visibility
 * @group animations
 * @author  João Dias
 * company:  WIT Software
 * date:  August 31st, 2014
 */
@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
  }
  25% {
    -webkit-transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
  }
}

@-moz-keyframes bounceOut {
  0% {
    -moz-transform: scale(1);
  }
  25% {
    -moz-transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -moz-transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -moz-transform: scale(0.3);
  }
}

@-ms-keyframes bounceOut {
  0% {
    -ms-transform: scale(1);
  }
  25% {
    -ms-transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -ms-transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -ms-transform: scale(0.3);
  }
}

@-o-keyframes bounceOut {
  0% {
    -o-transform: scale(1);
  }
  25% {
    -o-transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -o-transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -o-transform: scale(0.3);
  }
}

@keyframes bounceOut {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    transform: scale(0.3);
  }
}

/**
 * An element fades and bounces out, from initial opacity to the final opacity state
 *  @type mixin
 * @param $duration - the duration of the animation
 * @param $delay - the delay of the animation
 * @param $function - the timing function of the animation
 * @param $fill-mode - the element fill mode during the animation
 * @param $visibility - Backface visibility
 * @group animations
 * @author  João Dias
 * company:  WIT Software
 * date:  August 31st, 2014
 */
@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-150px);
  }
}

@-moz-keyframes bounceOutUp {
  0% {
    -moz-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -moz-transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(-150px);
  }
}

@-ms-keyframes bounceOutUp {
  0% {
    -ms-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -ms-transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -ms-transform: translateY(-150px);
  }
}

@-o-keyframes bounceOutUp {
  0% {
    -o-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -o-transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(-150px);
  }
}

@keyframes bounceOutUp {
  0% {
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    transform: translateY(-150px);
  }
}

/**
 * An element fades and bounces out to the top of the page, from initial opacity to the final opacity state
 *  @type mixin
 * @param $duration - the duration of the animation
 * @param $delay - the delay of the animation
 * @param $function - the timing function of the animation
 * @param $fill-mode - the element fill mode during the animation
 * @param $visibility - Backface visibility
 * @group animations
 * @author  João Dias
 * company:  WIT Software
 * date:  August 31st, 2014
 */
@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
}

@-moz-keyframes bounceOutDown {
  0% {
    -moz-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -moz-transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
}

@-ms-keyframes bounceOutDown {
  0% {
    -ms-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -ms-transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -ms-transform: translateY(2000px);
  }
}

@-o-keyframes bounceOutDown {
  0% {
    -o-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -o-transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
}

@keyframes bounceOutDown {
  0% {
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

/**
 * An element fades and bounces out to the bottom of the page, from initial opacity to the final opacity state
 *  @type mixin
 * @param $duration - the duration of the animation
 * @param $delay - the delay of the animation
 * @param $function - the timing function of the animation
 * @param $fill-mode - the element fill mode during the animation
 * @param $visibility - Backface visibility
 * @group animations
 * @author  João Dias
 * company:  WIT Software
 * date:  August 31st, 2014
 */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/**
 * An element fades in from zero opacity to the final opacity state
 *  @type mixin
 * @param $duration - the duration of the animation
 * @param $delay - the delay of the animation
 * @param $function - the timing function of the animation
 * @param $fill-mode - the element fill mode during the animation
 * @param $visibility - Backface visibility
 * @group animations
 * @author  João Dias
 * company:  WIT Software
 * date:  August 31st, 2014
 */
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-ms-keyframes fadeInDown {
  0% {
    opacity: 0;
    -ms-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateY(0);
  }
}

@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -ms-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/**
 * An element fades in from the top of the page, and from zero opacity to the final opacity state
 *  @type mixin
 * @param $duration - the duration of the animation
 * @param $delay - the delay of the animation
 * @param $function - the timing function of the animation
 * @param $fill-mode - the element fill mode during the animation
 * @param $visibility - Backface visibility
 * @group animations
 * @author  João Dias
 * company:  WIT Software
 * date:  August 31st, 2014
 */
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-ms-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -ms-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateX(0);
  }
}

@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/**
 * An element fades in from the left of the page, and from zero opacity to the final opacity state
 *  @type mixin
 * @param $duration - the duration of the animation
 * @param $delay - the delay of the animation
 * @param $function - the timing function of the animation
 * @param $fill-mode - the element fill mode during the animation
 * @param $visibility - Backface visibility
 * @group animations
 * @author  João Dias
 * company:  WIT Software
 * date:  August 31st, 2014
 */
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-ms-keyframes fadeInRight {
  0% {
    opacity: 0;
    -ms-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateX(0);
  }
}

@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/**
 * An element fades in from the right of the page, and from zero opacity to the final opacity state
 *  @type mixin
 * @param $duration - the duration of the animation
 * @param $delay - the delay of the animation
 * @param $function - the timing function of the animation
 * @param $fill-mode - the element fill mode during the animation
 * @param $visibility - Backface visibility
 * @group animations
 * @author  João Dias
 * company:  WIT Software
 * date:  August 31st, 2014
 */
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-ms-keyframes fadeInUp {
  0% {
    opacity: 0;
    -ms-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateY(0);
  }
}

@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/**
 * An element fades in from the bottom of the page, and from zero opacity to the final opacity state
 *  @type mixin
 * @param $duration - the duration of the animation
 * @param $delay - the delay of the animation
 * @param $function - the timing function of the animation
 * @param $fill-mode - the element fill mode during the animation
 * @param $visibility - Backface visibility
 * @group animations
 * @author  João Dias
 * company:  WIT Software
 * date:  August 31st, 2014
 */
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/**
 * An element fades out from the initial position to a zero opacity state
 *  @type mixin
 * @param $duration - the duration of the animation
 * @param $delay - the delay of the animation
 * @param $function - the timing function of the animation
 * @param $fill-mode - the element fill mode during the animation
 * @param $visibility - Backface visibility
 * @group animations
 * @author  João Dias
 * company:  WIT Software
 * date:  August 31st, 2014
 */
@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
}

@-moz-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
}

@-ms-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -ms-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -ms-transform: translateY(20px);
  }
}

@-o-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

/**
 * An element fades out from the initial position to bottom, with a zero opacity state
 *  @type mixin
 * @param $duration - the duration of the animation
 * @param $delay - the delay of the animation
 * @param $function - the timing function of the animation
 * @param $fill-mode - the element fill mode during the animation
 * @param $visibility - Backface visibility
 * @group animations
 * @author  João Dias
 * company:  WIT Software
 * date:  August 31st, 2014
 */
@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
}

@-moz-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }
}

@-ms-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -ms-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -ms-transform: translateX(-20px);
  }
}

@-o-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-20px);
  }
}

/**
 * An element fades out from the initial position to left, with a zero opacity state
 *  @type mixin
 * @param $duration - the duration of the animation
 * @param $delay - the delay of the animation
 * @param $function - the timing function of the animation
 * @param $fill-mode - the element fill mode during the animation
 * @param $visibility - Backface visibility
 * @group animations
 * @author  João Dias
 * company:  WIT Software
 * date:  August 31st, 2014
 */
@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
}

@-moz-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
}

@-ms-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -ms-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -ms-transform: translateX(20px);
  }
}

@-o-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(20px);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(20px);
  }
}

/**
 * An element fades out from the initial position to right, with a zero opacity state
 *  @type mixin
 * @param $duration - the duration of the animation
 * @param $delay - the delay of the animation
 * @param $function - the timing function of the animation
 * @param $fill-mode - the element fill mode during the animation
 * @param $visibility - Backface visibility
 * @group animations
 * @author  João Dias
 * company:  WIT Software
 * date:  August 31st, 2014
 */
@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
}

@-moz-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
}

@-ms-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -ms-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -ms-transform: translateY(-20px);
  }
}

@-o-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

/**
 * An element fades out from the initial position to top, with a zero opacity state
 *  @type mixin
 * @param $duration - the duration of the animation
 * @param $delay - the delay of the animation
 * @param $function - the timing function of the animation
 * @param $fill-mode - the element fill mode during the animation
 * @param $visibility - Backface visibility
 * @group animations
 * @author  João Dias
 * company:  WIT Software
 * date:  August 31st, 2014
 */
@-webkit-keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  100% {
    transform: translateY(0);
  }
}

@-moz-keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  100% {
    transform: translateY(0);
  }
}

@-ms-keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  100% {
    transform: translateY(0);
  }
}

@-o-keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  100% {
    transform: translateY(0);
  }
}

/**
 * An element slides from top to final position
 *  @type mixin
 * @param $duration - the duration of the animation
 * @param $delay - the delay of the animation
 * @param $function - the timing function of the animation
 * @param $fill-mode - the element fill mode during the animation
 * @param $visibility - Backface visibility
 * @group animations
 * @author  João Dias
 * company:  WIT Software
 * date:  August 31st, 2014
 */
@-webkit-keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }
  100% {
    transform: translateY(0);
  }
}

@-moz-keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }
  100% {
    transform: translateY(0);
  }
}

@-ms-keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }
  100% {
    transform: translateY(0);
  }
}

@-o-keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }
  100% {
    transform: translateY(0);
  }
}

/**
 * An element slides from the bottom of the page to final position
 *  @type mixin
 * @param $duration - the duration of the animation
 * @param $delay - the delay of the animation
 * @param $function - the timing function of the animation
 * @param $fill-mode - the element fill mode during the animation
 * @param $visibility - Backface visibility
 * @group animations
 * @author  João Dias
 * company:  WIT Software
 * date:  August 31st, 2014
 */
/**
   * This is a set of classes to use directly on the HTML
   */
.animated {
  -webkit-animation-duration: 120ms;
  -moz-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
}

.bounce {
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-duration: 120ms;
  -moz-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.bounceIn {
  -webkit-animation-name: bounceIn;
  -moz-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-animation-duration: 120ms;
  -moz-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.bounceOut {
  -webkit-animation-name: bounceOut;
  -moz-animation-name: bounceOut;
  animation-name: bounceOut;
  -webkit-animation-duration: 120ms;
  -moz-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 120ms;
  -moz-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 120ms;
  -moz-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-duration: 120ms;
  -moz-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-duration: 120ms;
  -moz-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 120ms;
  -moz-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  -moz-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 120ms;
  -moz-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  -moz-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  -webkit-animation-duration: 120ms;
  -moz-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  -moz-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
  -webkit-animation-duration: 120ms;
  -moz-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  -moz-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
  -webkit-animation-duration: 120ms;
  -moz-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  -moz-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  -webkit-animation-duration: 120ms;
  -moz-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/*# sourceMappingURL=wit-animation-kit.css.map */